import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

export interface CurrencyPipeOptions {
  /**
   * The ISO 4217 currency code, such as USD for the US dollar and EUR for the euro. 
   */
  currencyCode: string,
  
  /**
   * The format for the currency indicator. One of the following:
   * code: Show the code (such as USD).
   * symbol(default): Show the symbol (such as $).
   * symbol-narrow: Use the narrow symbol for locales that have two symbols for their currency. For example, the Canadian dollar CAD has the symbol CA$ and the symbol-narrow $. If the locale has no narrow symbol, uses the standard symbol for the locale.
   * String: Use the given string value instead of a code or a symbol. For example, an empty string will suppress the currency & symbol.
   * Boolean (marked deprecated in v5): true for symbol and false for code.
   * Optional. Default is 'symbol'.
   */
  display?: string | boolean,
  
  /**
   * Decimal representation options, specified by a string in the following format:
   * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
   * 
   * minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
   * minFractionDigits: The minimum number of digits after the decimal point. Default is 2.
   * maxFractionDigits: The maximum number of digits after the decimal point. Default is 2. If not provided, the number will be formatted with the proper amount of digits, depending on what the ISO 4217 specifies. For example, the Canadian dollar has 2 digits, whereas the Chilean peso has none.
   * Optional. Default is undefined.
   */
  digitsInfo?: string,

  /**
   * A locale code for the locale format rules to use. When not supplied, uses the value of LOCALE_ID, which is en-US by default. See Setting your app locale.
   * 
   * Optional. Default is undefined.
   */
  locale?: string,

}

@Pipe({
	name: "price",
})
export class PricePipe implements PipeTransform {

	constructor(private currencyPipe: CurrencyPipe) {}

	transform(
		price: number,
		currencyPipeOptions: CurrencyPipeOptions = {
			currencyCode: "EUR",
			digitsInfo: "1.2-2",
			display: "symbol-narrow",
			locale: "de",
		},
		displaySign = false,
	): string {
		if (displaySign) {
			const unsignedPrice = Math.abs(price);
			const sign = price >= 0 ? "+" : "-";

			return `${sign} ${this.currencyTransform(unsignedPrice, currencyPipeOptions)}`;
		}
		return this.currencyTransform(price, currencyPipeOptions);
	}

	private currencyTransform(price: number, currencyPipeOptions: CurrencyPipeOptions) {
		return this.currencyPipe.transform(
			price,
			currencyPipeOptions.currencyCode,
			currencyPipeOptions.display,
			currencyPipeOptions.digitsInfo,
			currencyPipeOptions.locale,
		);
	}

}

import { GastroService } from "./../services/gastro.service";
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrderingAvailableGuard implements CanActivate {
	constructor(
    public gastroService: GastroService,
    public router: Router,
	) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const menuOptions = this.gastroService.$gastro.menuOptions;
		if (menuOptions && menuOptions.orderMenuActivated == false) {
			this.router.navigate(["menu"]);
			return false;
		} else {
			return true;
		}
	}
  
}

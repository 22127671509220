import {
	Component,
	OnInit,
	ViewChild,
	ViewChildren,
} from "@angular/core";
import { CartService } from "../services/cart.service";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";

import { Storage } from "@ionic/storage";
import { IonContent, ToastController } from "@ionic/angular";
import { NavController, LoadingController } from "@ionic/angular";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { UserService } from "../services/user.service";
import { AlertController } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { DishDetailPage } from "../dish-detail/dish-detail.page";
import { VytalModalPage } from "../vytal-modal/vytal-modal.page";
import { OverlayEventDetail } from "@ionic/core";
import { Subscription } from "rxjs";
import { DeviceService } from "../services/device.service";
import { LocationsService } from "../services/locations.service";
import { CheckoutSystemApiService } from "../services/api/checkout-system-api.service";
import { SessionDataService } from "../services/session-data.service";
import { ProfileEntryModalPage } from "../profile-entry-modal/profile-entry-modal.page";
import { CoronaFormPage } from "../corona-form/corona-form.page";
import { GlobalService } from "../services/global.service";
import { GastroService } from "../services/gastro.service";
import { MenuService } from "../services/menu.service";
import { OrderService } from "../services/order.service";
import { GastroResettable, ResetService } from "../services/reset.service";
import { TemplateStyleService } from "../services/template-style.service";
import { SentryService } from "../services/sentry.service";
import { LocalizationService } from "../services/localization.service";
import { getLabelOfLanguageCode } from "src/shared/split-submodules/functions/functions";
import { Category, Product } from "src/shared/split-submodules/types/types";
import { ReportService } from "../services/report.service";
import { GastroInfoModalPage } from "./gastro-info-modal/gastro-info-modal.page";
import { AgeVerificationPage } from "../age-verification/age-verification.page";
import { SimpleImgModalComponent } from "../components/simple-img-modal/simple-img-modal.component";
import { CurrencyPipeOptions } from "../pipes/price.pipe";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { StorageService } from "../services/storage.service";
import { OrderbirdService } from "../services/api/orderbird/orderbird.service";
import { TableContentService } from "../services/table-content.service";
export interface Dish {
	id?: string;
	category: number;
	name: string;
	price: string;
	description: any;
	kind: number;
	description2: any;
	count: number;
	dayID: Array<number>;
	taste: string;
	dishes: Array<Dish>;
	inhouse: boolean;
	outerhouse: boolean;
	unclickable: boolean;
}
export class Gastro {
	id?: string;
	name: string;
	bar: boolean;
	creditcard: boolean;
	paypal: boolean;
	self: boolean;
}
@Component({
	selector: "app-restaurant-page",
	templateUrl: "./restaurant-page.page.html",
	styleUrls: ["./restaurant-page.page.scss"],
})
export class RestaurantPagePage implements OnInit, GastroResettable {
	@ViewChildren("cat") components;
	@ViewChild("content") content: IonContent;

	cart = [];
	offerItems;
	catItem;
	displayedCategory = "";
	random;
	offers;
	// gastroID;
	leftPage = false;
	img;
	search;
	// gastro;
	alertOpen = false;
	interval;
	showBackdrop = false;
	coronaModal;
	isMenuOpen = false;
	catItems: Dish[];
	alreadyScrolledBy = new Set<string>();
	//splash = true;
	deliveryInactive = false;
	name = "";
	phone = "";
	adress = "";
	saveCoronaInfosOnDevice = false;
	saveCoronaInfosOnDeviceChecked = false;
	private isCoronaFormOpen = false;
	public additionalPersons: any = [];

	public listener;
	public selectedCategory;
	private gastroSubscription: Subscription;

	public get categories() {
		return this.menuService.categories;
	}

	public get dishes() {
		return this.menuService.products;
	}

	public get topDishes() {
		return this.menuService.topProducts;
	}

	// eslint-disable-next-line no-magic-numbers
	public foodDrinkFilter: 0 | 1 = 0; //0 => Foodfilter | 1 => Drinkfilters 

	constructor(
		public userService: UserService,
		public alertController: AlertController,
		public modalController: ModalController,
		public router: Router,
		public cartService: CartService,
		private checkoutSystemApiService: CheckoutSystemApiService,
		private storage: Storage,
		private navCtrl: NavController,
		private db: AngularFirestore,
		private route: ActivatedRoute,
		public loadingController: LoadingController,
		public deviceService: DeviceService,
		public locationService: LocationsService,
		public sessionDataService: SessionDataService,
		private toastController: ToastController,
		public globalService: GlobalService,
		public gastroService: GastroService,
		public reportService: ReportService,
		public menuService: MenuService,
		public orderService: OrderService,
		private resetService: ResetService,
		public templateStyleService: TemplateStyleService,
		private sentryService: SentryService,
		public localizationService: LocalizationService,
		private analytics: AngularFireAnalytics,
		private storageService: StorageService,
		public orderbirdService: OrderbirdService,
		public tableContentService: TableContentService,
	) {
		this.registerGastroReset();
		// this.gastro = new Observable<Gastro>();
	}

	onGastroLogout(): void {
		this.cart = [];
		this.displayedCategory = "";
		this.name = "";
		this.phone = "";
		this.adress = "";
		this.saveCoronaInfosOnDevice = false;
		this.saveCoronaInfosOnDeviceChecked = false;
		if (this.gastroSubscription !== undefined) {
			this.gastroSubscription.unsubscribe();
			this.gastroSubscription = undefined;
		}
		this.additionalPersons = [];
	}

	registerGastroReset(): void {
		this.resetService.registerGastroReset(this);
	}

	async openProfileSelect(eve: any) {
		const modal = await this.modalController.create({
			component: ProfileEntryModalPage,
			cssClass: "auto-height",
		});
		modal.present();
		modal.onDidDismiss().then(data => {

		});
	}

	enteredGastro() {
		this.storage.get("gastroId").then(val => {

			// if (!val) {
			//     val = this.gastroService.$gastroId;
			// }
			// this.gastroID = val;

			if (!this.gastroService.gastroLoaded) {
				this.fetchGastro();
			} else if (!this.menuService.menuLoaded) {
				this.menuService.fetchMenu();
				this.menuService.checkAndShowGroupOrderingPopup();
			}
		});


	}

	fetchGastro() {
		if (this.gastroSubscription !== undefined) {
			this.gastroSubscription.unsubscribe();
		}
		if (this.gastroService.$gastroId === undefined) {
			return;
		}
		this.gastroSubscription = this.db
			.collection("gastro")
			.doc(this.gastroService.$gastroId)
			.snapshotChanges()
			.subscribe(async a => {

				const gastro: any = a.payload.data();
				// this.gastroService.$gastro = this.gastro;
				this.gastroService.gastroLoaded = true;
				const themeWrapper = document.querySelector("body");
				themeWrapper.style.setProperty(
					"--ion-color-gastro",
					`#${gastro.color}`,
				);
				/**if (!this.deviceService.$showedSplash) {
					this.showSplash();
					this.deviceService.$showedSplash = true;
				} else {
					this.splash = false;
				}**/
				if (gastro.menuOptions && gastro.menuOptions.orderMenuActivated == false) {
					if (gastro.menuOptions.menuOnlyActivated == true) {
						this.router.navigate(["menu"]);
						return;
					} else {
						this.router.navigate(["qrcode-scanner"]);
						return;
					}
				}
				this.menuService.fetchMenu();
			});

	}

	downloadPDF() {
		if (this.gastroService.$gastro.hasOnlyPDF === true) {
			const a = document.createElement("a");
			const filePath = this.gastroService.$gastro.pdfURL;
			a.href = filePath;
			a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	/**showSplash() {
		document.getElementById('tabstabs').style.display = 'none';
		setTimeout(() => {
			this.splash = false;
			document.getElementById('tabstabs').style.display = 'flex';
		}, 0);
	}**/
	/**
	 * 
	 * @param category switches the restaurant page view to the selected category
	 */
	goToCategory(category) {
		this.selectedCategory = category;
		const modalState = { modal: true };
		history.pushState(modalState, null);
	}

	goBackFromSelectedCategory() {
		this.selectedCategory = undefined;
	}

	async openInfoPopOver() {

		const infoPopOver = await this.modalController.create({
			animated: true,
			backdropDismiss: true,
			component: GastroInfoModalPage,
			componentProps: {
				gastro: this.gastroService.$gastro,
			},
			showBackdrop: true,
		});
		return await infoPopOver.present();
	}

	async ngOnInit() {
		await this.templateStyleService.loadTemplateConfig();
		this.analytics.logEvent("session_start", { gastro: this.gastroService.$gastroId });
		TemplateStyleService.InjectFonts(this.templateStyleService.$templateConfig.ownFonts);
		if ((this.sessionDataService.$inhouseLink && this.gastroService.$gastro.popDishesInhouseShown)
			|| (!this.sessionDataService.$inhouseLink && this.gastroService.$gastro.popDishesOuterhouseShown)) {
			this.displayedCategory = "popDishes";
		}
	}


	alergicOpen(link) {
		window.open(link, "_blank");
	}

	length() {
		let count = 0;
		this.cartService.$cart.forEach(element => {
			count += element.count;
		});
		return count;
	}

	ionViewWillLeave() {
		this.listener.unsubscribe();
		this.leftPage = true;
	}

	/**
	 * returns the user to the home screen only if the user came from the marketplace(platform)
	 */
	public backToSite() {

		if (this.sessionDataService.$comesFromPlatform == true) {
			this.router.navigate(["home"]);
		}
	}

	/**
	 * navigates the user to the Table-Content, only if the user is inhouse.
	 * There is no other case since the button is only shown if the gastro has self checkout
	 */
	public goToSelfCheckoutTableContent() {

		if (this.gastroService.$gastro.hasPayAtTable == true && this.sessionDataService.$inhouseLink) {
			this.router.navigate(["table-content"]);
		}
	}

	ionViewDidEnter() {

		if (this.catItem != undefined && this.catItem.parentNode != undefined) {
			this.catItem.selected = true;
			this.catItem.parentNode.selected = true;
		}
		this.resizeIosWebView();
		this.subscribeBackButton();
	}

	public async subscribeBackButton() {
		const isModalOpened = await this.modalController.getTop();
		window.addEventListener("popstate", (event) => {
			if (this.coronaModal !== undefined && isModalOpened) {
				this.coronaModal.dismiss();
			} else if (this.selectedCategory !== undefined) {
				this.selectedCategory = undefined;
				event.stopPropagation();
			}
		});
	}


	resizeIosWebView() {
		const standalone = (<any>window.navigator).standalone,
			userAgent = window.navigator.userAgent.toLowerCase(),
			safari = /safari/.test(userAgent),
			ios = /iphone|ipod|ipad/.test(userAgent);
		if (window.innerHeight === window.screen.height && navigator.userAgent.match(/instagram/i)) {
			window.dispatchEvent(new Event("resize"));
		}
		if (ios) {
			window.dispatchEvent(new Event("resize"));
			if (!standalone && safari) {
				return;
			} else if (standalone && !safari) {
				return;
			} else if (!standalone && !safari) {
				window.dispatchEvent(new Event("resize"));
			}
		} else {
			return;
		}
	}

	hideArrow() {
		document.getElementById("usage-arrow").className += " hidden";
		document.getElementById("searchbar").classList.remove("margin-swipe");
	}

	ionViewWillEnter() {
		this.tableContentService.fetchOBTableData();

		this.listener = this.router.events
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === "popstate") {
					if (!this.sessionDataService.$inhouseLink && this.sessionDataService.$comesFromPlatform == true) {
						if (this.locationService.previousCity == "Gesamt") {
							this.router.navigate(["home"]);
						} else {
							this.router.navigate([`home/${this.locationService.previousCity}`]);
						}

					}
				}
			});
		this.leftPage = false;

		this.selectedCategory = undefined;

		this.components.forEach(element => {
			element.selected = false;
		});
		this.enteredGastro();

		if (this.sessionDataService.$vytalQR !== undefined
			&& this.sessionDataService.inhouseLink === false
			&& this.sessionDataService.$vytalQR !== ""
			&& this.sessionDataService.$vytalQR !== null
			&& this.gastroService.$gastro.hasVytal === true
			&& (this.sessionDataService.$hasSeenVytal === false
				|| this.sessionDataService.$hasSeenVytal === undefined
				|| this.sessionDataService.$hasSeenVytal === null)) {
			this.openVytal();
		}

		this.orderService.updateCartTotal();
		this.cartService.updateCountOfProducts();
	}

	async addToCart(dish, index) {
		this.analytics.logEvent("dish-clicked", { dish: dish.name, gastro: this.gastroService.$gastroId });
		if (dish.hasAlc === 16 && this.sessionDataService.$overSixteen !== true) {
			this.askOverSixteen(dish, index);
			return;
		}

		if (dish.hasAlc === 18 && this.sessionDataService.$overEighteen !== true) {
			this.askOverEighteen(dish, index);
			return;
		}

		if (document.getElementById(`pm${index}`) != undefined) {
			document.getElementById(`pm${index}`).classList.add("animation");
			setTimeout(() => {
				document.getElementById(`pm${index}`).classList.remove("animation");
			}, 400);
		}
		if (document.getElementById("badgeCart") != undefined) {
			document.getElementById("badgeCart").classList.add("animationCart");
			setTimeout(() => {
				document.getElementById("badgeCart").classList.remove("animationCart");
			}, 1000);
		}
		event.stopPropagation();
		dish.price = this.sessionDataService.$inhouseLink ? dish.inhousePrice : dish.outerhousePrice;
		//No extras for this dish
		if (dish.opt == false) {
			if (this.menuService.isUnclickable()) {
				return;
			}
			this.cartService.addProduct(dish);
			this.toast("Zum Warenkorb hinzugefügt", "orderbird");
		}
		//extras
		else {
			//No extras defined -> add baseProduct to cart
			if (dish.extraId == "" || dish.extraId == undefined) {
				this.cartService.addProduct(dish);
			}
			//Loading screen until extra is found
			this.showBackdrop = true;
			const loading = await this.loadingController.create({
				spinner: "dots",
				translucent: true,
				cssClass: "my-loading-class",
			});
			loading.present();

			// this.checkoutSystemApiService.getActiveApi().then(
			//     async (api: CheckoutSystem) => {
			//         if (api !== undefined) {
			//             const extra = api.getExtra(dish.extraId);
			//             const modal = await this.modalController.create({
			//                 component: DishDetailPage,
			//                 showBackdrop: true,
			//                 componentProps: {
			//                     dish: dish,
			//                     extra: extra,
			//                 },
			//                 animated: true,
			//                 backdropDismiss: true,
			//                 mode: 'ios'
			//             })
			//             modal.onDidDismiss().then((modalRetVal: OverlayEventDetail) => {
			//                 if (modalRetVal !== null && modalRetVal.data !== null && modalRetVal.data !== undefined) {
			//                     this.addToCartModal(modalRetVal.data.dish, modalRetVal.data.extra, modalRetVal.data.total);
			//                 }
			//             });
			//             loading.dismiss();
			//             modal.present();
			//             this.showBackdrop = false;
			//         }
			//         else {
			this.db
				.collection("gastro")
				.doc(this.gastroService.$gastroId)
				.collection("dishExtras")
				.doc(dish.extraId)
				.get()
				.toPromise()
				.then(async(extraDoc) => {
					if (extraDoc.exists) {
						const modal = await this.modalController.create({
							animated: true,
							backdropDismiss: true,
							component: DishDetailPage,
							componentProps: {
								dish: dish,
								extra: extraDoc.data(),
							},
							mode: "ios",
							showBackdrop: true,
						});
						modal.onDidDismiss().then((modalRetVal: OverlayEventDetail) => {
							if (modalRetVal !== null && modalRetVal.data !== null && modalRetVal.data !== undefined) {
								this.addToCartModal(
									modalRetVal.data.dish,
									modalRetVal.data.extra,
									modalRetVal.data.total,
								);
							}
						});
						loading.dismiss();
						modal.present();
						this.showBackdrop = false;
					} else {
						loading.dismiss();
						this.showBackdrop = false;
					}
				});
		}
		this.gastroService.calculateIsTogoOptionAvailable(
			this.cartService.$cart,
			this.cartService.getAmountOfFoodInCart(),
		);
		this.orderService.updateCartTotal();
		this.cartService.updateCountOfProducts();
		//         }
		//     )

		// }
	}

	async toast(message, status) {
		const toast = await this.toastController.create({
			color: status,
			duration: 2000,
			message: message,
			position: "top",
		});
		toast.present();
	}

	emptyStringToZero(val) {
		let ret = val;
		if (val == "") {
			ret = 0;
		}

		return ret;
	}

	addToCartModal(dish, extra, total) {
		dish.price = total;
		const id = 0;
		//Remap the new extras to the old extras so that there are no errors in Waiter-app and reports :/
		extra.extraCategories = extra.extraCategories.map(cat => {
			const ret: any = {};
			ret.kind = cat.kind;
			ret.name = cat.name;
			ret.description = cat.description;
			if (cat.free != undefined) {
				ret.free = cat.free;
			}
			if (cat.maxCount != undefined) {
				ret.maxCount = cat.maxCount;
			}
			ret.extra = cat.extraItems;
			if (cat.kind == 0) {
				cat.extraItems.forEach(item => {
					if (item.selected == true) {
						ret.select = item.name;
						if (this.gastroService.$gastro.hasArticleNr && item.articleNr) {
							dish.articleNr = item.articleNr;
						}
					}
				});
			} else if (cat.kind === 1) {
				for (let i = 0; i < cat.extraItems.length; i++) {
					ret.extra[i].isChecked = cat.extraItems[i].selected ? 1 : 0;
					ret.extra[i].selected = cat.extraItems[i].selected;
				}
			}

			cat = ret;
			return ret;
		});

		const extras = {
			checkboxen: [],
			dropdown: [],
			extras: extra.extraCategories,
			name: dish.name,
			note: extra.note,
			sorted: true,
		};

		this.cartService.addProduct(dish, extras);
		this.gastroService.calculateIsTogoOptionAvailable(
			this.cartService.$cart,
			this.cartService.getAmountOfFoodInCart(),
		);
		this.orderService.updateCartTotal();
		this.cartService.updateCountOfProducts();
	}

	deleteFromCart(product) {
		event.stopPropagation();
		this.cartService.deleteProduct(product);
	}

	openCart() {
		this.router.navigate(["cart"]);
	}

	countproduct(arr, product) {
		const counts = {};

		for (let i = 0; i < arr.length; i++) {
			const num = arr[i];
			counts[num] = counts[num] ? counts[num] + 1 : 1;
		}

		return counts[product];
	}

	isObject(cat) {
		return typeof (cat) === "object";
	}

	toggleAccordion(product) {
		product.isMenuOpen = product.isMenuOpen ? false : true;
	}

	dateToTimeString(date: Date): string {
		let hours = date.getHours().toString();
		let minutes = date.getMinutes().toString();
		if (hours.length == 1) {
			hours = `0${hours}`;
		}
		if (minutes.length == 1) {
			minutes = `0${minutes}`;
		}
		return `${hours}:${minutes}`;
	}

	offerAvailable(dish) {
		if (dish.offer == false) {
			return true;
		} else {
			const now = new Date();
			if (dish.offerData.dates.includes(now.getDay())) {
				if (
					dish.offerData.from <= this.dateToTimeString(now)
					&& this.dateToTimeString(now) <= dish.offerData.to
				) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
	}

	scrollTo(categoryId: string|number) {
		const elem = document.getElementById(`cat${categoryId}`);
		if (elem == null) {
			return;
		}
		const category = this.categories.filter((cat)=>{
			return cat.id == categoryId;
		});
		this.analytics.logEvent("category-clicked", { cat: category[0].name, gastro: this.gastroService.$gastroId });
		const y = elem.getBoundingClientRect().top;
		const offset = document.getElementById("horizontalCategorySelector").getBoundingClientRect().height + 10;
		this.content.scrollByPoint(0, y - offset, 500);
	}

	scrolling($event) {
		const offset = document.getElementById("horizontalCategorySelector").getBoundingClientRect().height + 10;
		const allCats: any[] = this.categories
			.filter(elem => !this.menuService.isCategoryEmpty(elem.id)
				&& (
					this.sessionDataService.$inhouseLink
					&& elem.inhouseVisible
					|| !this.sessionDataService.$inhouseLink
					&& elem.outerhouseVisible
				));
		allCats.push({ id: "hinweise" });
		if ((this.sessionDataService.$inhouseLink && this.gastroService.$gastro.popDishesInhouseShown)
			|| (!this.sessionDataService.$inhouseLink && this.gastroService.$gastro.popDishesOuterhouseShown)) {
			allCats.splice(0, 0, { id: "popDishes" });
		}
		let foundIndex = -1;
		for (let i = 0; i < allCats.length; i++) {
			const cat = document.getElementById(`cat${allCats[i].id}`);
			if (cat == null) {
				continue;
			}
			if (cat.getBoundingClientRect().top - offset > 0) {

				break;
			}
			foundIndex = i;

		}
		if (foundIndex != -1) {
			this.displayedCategory = allCats[foundIndex].id;
			if (!this.alreadyScrolledBy.has(this.displayedCategory)) {
				this.alreadyScrolledBy.add(this.displayedCategory);
				this.analytics.logEvent("scrolled-to", { gastro: this.gastroService.$gastroId, category: this.displayedCategory });
			}
			this.scrollTabBar(allCats[foundIndex]);
		}
	}

	scrollTabBar(cat) {
		const tbBase = document.getElementById("tbBaseComponent");
		const doc = document.getElementById(`tb${cat.id}`);
		const x = doc.getBoundingClientRect().x;
		tbBase.scrollBy({
			left: x / 2 - 5,
			top: 0,
		});
	}

	async presentAlertRadio(product) {
		const alert = await this.alertController.create({
			header: "Radio",
			inputs: [
				{
					checked: true,
					label: product.options[1].n,
					name: product.options[1].n,
					type: "radio",
					value: 1,
				},
				{
					checked: false,
					label: product.options[2].n,
					name: product.options[2].n,
					type: "radio",
					value: 2,
				},
			],
			buttons: [
				{
					cssClass: "secondary",
					handler: () => {
						console.log("Confirm Cancel");
					},
					role: "cancel",
					text: "Abbruch",
				},
				{
					handler: val => {
						this.addToCart({
							img: "",
							name: `${product.name} ${product.options[val].n}`,
							parent: product,
							price: product.options[val].p,
						}, 0);
						if (product.count == null || product.count == "") {
							product.count = 1;
						} else {
							product.count += 1;
						}
					},
					text: "Hinzufügen",
				},
			],
		});
		await alert.present();
	}

	async showCoronaFormModal() {

		this.coronaModal = await this.modalController.create({
			component: CoronaFormPage,
			backdropDismiss: false,
		});
		this.coronaModal.onDidDismiss().then(async(data) => {
			const toast = await this.toastController.create({
				color: "success",
				duration: 3000,
				message: "Du wurdest erfolgreich registriert!",
				position: "top",
			});
			if (data?.data?.success == true) {
				toast.present();
				this.menuService.fetchMenu();
				this.downloadPDF();
			}
		});
		return await this.coronaModal.present();
	}

	splitDescritption(description) {
		return description.split("****");
	}

	countDish(dish) {
		let ret = 0;
		this.cartService.$cart.forEach((e) => {
			if (e.name == dish.name) {
				ret += e.count;
			}
		});
		return ret;
	}

	async openZoomedImage(imgUrl: string) {

		const modal = await this.modalController.create({
			component: SimpleImgModalComponent,
			componentProps: { imgUrl: imgUrl },
			cssClass: "auto-height",
			presentingElement: await this.modalController.getTop(),
		});
		await modal.present();
		await modal.onWillDismiss().then((result) => {
			console.log("result :>> ", result);

		}).catch((err) => {
			console.log("err :>> ", err);
		});
	}

	public async changeLanguageButtonCallback() {
		const inputs = this.localizationService.availableLanguageCodes.map(code => {
			return {
				checked: this.localizationService.currentLanguageCode === code,
				label: getLabelOfLanguageCode(code),
				type: "radio",
				value: code,
			};
		});

		const defaultLanguageCode = this.localizationService.defaultLanguageCode;
		inputs.unshift({
			checked: this.localizationService.currentLanguageCode === defaultLanguageCode,
			label: getLabelOfLanguageCode(defaultLanguageCode),
			type: "radio",
			value: defaultLanguageCode,
		});

		inputs.sort((a, b) => { return a.label.localeCompare(b.label); });

		const alert = await this.alertController.create({
			header: "Sprache ändern",
			inputs: <any>inputs,
			buttons: [
				{
					role: "cancel",
					text: "Abbrechen",
				},
				{
					text: "Bestätigen",
					handler: (value) => {
						this.localizationService.loadLanguage(value, this.gastroService.$gastro.id);
					},
				},
			],
		});
		alert.present();
	}

	public getProductTranslation(product: Product): {
		name: string;
		description: string;
	} {
		return this.localizationService.getProductTranslation(product);
	}

	public getCategoryTranslation(category: Category): {
		name: string;
		description: string;
	} {
		return this.localizationService.getCategoryTranslation(category);
	}

	async openVytal() {
		const modal = await this.modalController.create({
			animated: true,
			backdropDismiss: true,
			component: VytalModalPage,
			id: "vytal-modal",
			mode: "ios",
			showBackdrop: true,
		});
		modal.present();
		modal.onDidDismiss().then(() => { this.sessionDataService.$hasSeenVytal = true; });
	}


	async askOverSixteen(dish, index) {
		const modal = await this.openAgeVerification(16);

		modal.onDidDismiss().then((modalRetVal: OverlayEventDetail) => {
			if (modalRetVal !== null && modalRetVal.data !== null && modalRetVal.data !== undefined) {
				if (modalRetVal.data === true) {
					this.sessionDataService.$overSixteen = true;
					this.addToCart(dish, index);
				} else {
					this.sessionDataService.$overSixteen = false;
				}
			}
		});
	}

	async askOverEighteen(dish, index) {
		const modal = await this.openAgeVerification(18);

		modal.onDidDismiss().then((modalRetVal: OverlayEventDetail) => {
			if (modalRetVal !== null && modalRetVal.data !== null && modalRetVal.data !== undefined) {
				if (modalRetVal.data === true) {
					this.sessionDataService.$overEighteen = true;
					this.addToCart(dish, index);
				} else {
					this.sessionDataService.$overEighteen = false;
				}
			}
		});
	}

	private async openAgeVerification(age) {
		const modal = await this.modalController.create({
			animated: true,
			backdropDismiss: true,
			component: AgeVerificationPage,
			componentProps: {
				age: age,
			},
			mode: "ios",
			showBackdrop: true,
		});
		modal.present();
		return modal;
	}

	/**
	 * Returns the options for the currencyPipe that are defined within gastro.menuOptions.priceFormatting if we are in the digital menu.
	 * If we are not in the digital menu, we return the default values leading to a formatting in the form of: "12.50 €".
	 * @returns 
	 */
	public getPriceFormat(): CurrencyPipeOptions {
		const options: CurrencyPipeOptions = {
			currencyCode: "EUR",
			digitsInfo: "1.2-2",
			display: "symbol-narrow",
			locale: "de",
		};

		// Return default if menu is clickable
		if (!this.menuService.isUnclickable()) {
			return options;
		}

		const templateConfig = this.templateStyleService.$templateConfig;

		return templateConfig.priceFormatting ?? options;
	}

	/**
	 * Whether or not to display the price. The price should only be displayed if it is unequal to 0.
	 * 
	 * @param product The product of which to display a price
	 * @param inhouse Whether or not we are trying to display a inhouse price or an outerhouse price
	 * @returns 
	 */
	public displayPrice(product: Product, inhouse: boolean): boolean {
		if (product.opt === false && (inhouse ? product.inhousePrice : product.outerhousePrice) === 0) {
			return false;
		}

		if (product.opt === true) {
			let price = (inhouse ? product.inhousePrice : product.outerhousePrice);
			if (product.extraData !== undefined) {
				price += (inhouse ? product.extraData.minInhousePrice : product.extraData.minOuterhousePrice);
			}
			if (price === 0) {
				return false;
			}
		}

		return true;
	}
	// go to table content tab

	goToTableContent() {

		this.router.navigate(["table-content"]);
	}
}
 

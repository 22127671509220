import { Injectable, NgZone } from "@angular/core";
import { UserService } from "../services/user.service";
import {
	AngularFirestore,
	AngularFirestoreCollection,
	DocumentSnapshot,
} from "@angular/fire/compat/firestore";
import { ToastController } from "@ionic/angular";
@Injectable({ providedIn: "root" })
export class CouponService {


	public currentCoupon = <any>{};
	public currentCouponList = <any>[];
	public couponToBeAppliedFromProfile = null;

	constructor(
		public db: AngularFirestore,
		public toastController: ToastController,
		public userService: UserService,
		public zone: NgZone,
	) { }

	/**
	 * This function checks if coupon code is exist in the gastro or in customer profile coupons.
	 * 
	 * @param code - coupon code
	 * @param gastroID 
	 * @returns Boolean
	 */
	async checkIfCouponCodeForGastroExists(code: string, gastroID) {
		const coupons = await this.db
			.collection("coupons", ref => ref
				.where("couponCode", "==", code)
				.where("gastroID", "==", gastroID))
			.get()
			.toPromise();
		let rewardCoupons;

		if (this.userService.profile.isLoggedIn) {
			rewardCoupons = <any> await this.db
				.collection("customer-profiles")
				.doc(this.userService.profile.userID)
				.collection("coupons", ref => ref
					.where("couponCode", "==", code)
					.where("gastroID", "==", gastroID))
				.get()
				.toPromise();
		}

		if (coupons.empty && (rewardCoupons == undefined || rewardCoupons.empty)) {
			return false;
		} else {
			const allCoupons = coupons.docs.concat(rewardCoupons?.docs);
			for (const coupon of allCoupons) {
				if (coupon.exists) {
					this.currentCoupon = coupon.data();
					return true;
				} else {
					this.currentCoupon = {};
					return false;
				}
			}
		}
	}

	/**
	 * This function checks if current coupon restriction price valid for the order.
	 * 
	 * @param totalPrice - Total price without delivery fee
	 * @returns 
	 */
	checkIfCouponCodeIsValidRestriction(totalPrice) {
		if (this.currentCoupon.restriction == "none") {
			return true;
		} else if (totalPrice < this.currentCoupon.restriction) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * This function checks if the current coupon is a splitometer and if so, if the gastro currently allows this type of coupon.
	 * 
	 * @param gastroID - ID from Gastro
	 * @param coupon - current coupon
	 * @returns 
	 */
	async checkIfSplitometerIsAllowed(gastroID) {
		const gastro = await this.db.collection<any>("gastro").doc(gastroID).get().toPromise();
		if (!gastro.exists) {
			return false;
		} else if (
			this.currentCoupon.couponType == "splitometer"
			&& gastro.data().loyaltyCard.loyaltyType != "splitometer"
		) {
			return false;

		} else {
			return true;
		}
	}

	/**
	 * This function checks all coupons in the coupon list if the restrictions still valid.
	 * If not, then delete the coupon from list and show a toast to notify the customer.
	 * 
	 * @param totalPrice - Total price without delivery price before discount
	 */
	async deleteIfCouponCodeIsNotValidRestriction(totalPrice) {
		for (const coupon of this.currentCouponList) {
			if (coupon.restriction == "none") {
				continue;
			} else if (totalPrice < coupon.restriction) {
				this.deleteCouponFromList(coupon.couponCode);
				this.failedToast(`Der Mindestbestellwert vom Gutschein: ${
					 coupon.couponCode
					 } von ${
					 coupon.restriction
					 } Euro wurde noch nicht erreicht.`);
			}
		}
	}


	/**
	 * This function deletes coupon one by one until totalPrice is not minus.
	 * 
	 * @param totalPrice - Total price without delivery price after discount
	 */
	async deleteCouponIfPriceMinus(totalPrice) {
		if (totalPrice < 0 && this.currentCouponList.length > 0) {
			const deletedCoupon = this.currentCouponList.pop();
			this.failedToast(`Gutschein : ${
				 deletedCoupon.couponCode
				 } kann nicht angewendet werden, weil der Endpreis negativ sein würde.`);
		}
	}


	/**
	 * This function checks if current coupons combineable.
	 * 
	 * @returns Boolean
	 */
	checkIfCouponCanCombine() {
		if (this.currentCouponList.length == 0) {
			return true;
		} else if (this.currentCouponList[0].combine == true && this.currentCoupon.combine == true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * This function checks if current coupon has uses left.
	 * 
	 * @returns Boolean
	 */
	checkIfCouponHasUsesLeft() {
		if (this.currentCoupon.numberOfUses == "none") {
			return true;
		}
		if (this.currentCoupon.numberOfUses > 0) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * This function checks if current coupon is already expired.
	 * 
	 * @returns Boolean
	 */
	checkIfCouponCodeIsValidDate() {
		const now = new Date().getTime();
		if (this.currentCoupon.expireAt == "none") {
			return true;
		} //this case is for special coupons that dont expire
		else if (
			this.currentCoupon.expireAt.toDate().getTime() > now
			&& this.currentCoupon.validFrom.toDate().getTime() < now
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * This function checks if there is no duplicate coupon in current coupon list.
	 * 
	 * @returns Boolean
	 */
	checkIfCouponCodeIsNotDuplicate() {
		for (const coupon of this.currentCouponList) {
			if (coupon.couponCode == this.currentCoupon.couponCode) {
				return false;
			}
		}
		return true;
	}

	/**
	 * This function simply adds current coupon to current coupon list.
	 */
	addCouponToList() {
		this.currentCouponList.push(this.currentCoupon);
		this.currentCoupon = {};
	}

	/**
	 * Add a coupon to a temporary variable for the next order
	 */
	addCouponFromProfile() {
		this.couponToBeAppliedFromProfile = this.currentCoupon;
		this.currentCoupon = {};
	}

	/**
	 * Apply a coupon that is saved in temporary couponToBeAppliedFromProfile variable for this order.
	 */
	applyCouponFromProfile() {
		if (this.couponToBeAppliedFromProfile != null) {
			this.currentCouponList.push(this.couponToBeAppliedFromProfile);
			this.couponToBeAppliedFromProfile = null;
		}
	}

	/**
	 * This function delete a coupon from current coupon list.
	 * 
	 * @param couponCode 
	 */
	deleteCouponFromList(couponCode) {
		for (const coupon of this.currentCouponList) {
			if (coupon.couponCode == couponCode) {
				const index = this.currentCouponList.indexOf(coupon);
				if (index > -1) {
					this.currentCouponList.splice(index, 1);
				}
			}
		}
	}

	/**
	 * This function subtracts the number of uses each coupon used in current coupon list.
	 * And then update the database. 
	 * 
	 * @param n - number of uses to be substracted.
	 */
	async subtractCouponUsesFromCouponList(n: number) {
		for (const coupon of this.currentCouponList) {
			if (coupon.numberOfUses == "none") {
				continue;
			}
			coupon.numberOfUses = (coupon.numberOfUses - n);
			const coupons = await this.db
				.collection("coupons", ref => ref.where("couponCode", "==", coupon.couponCode))
				.get()
				.toPromise();
			if (coupons.empty) {
				let rewardCoupons;
				if (this.userService.profile.isLoggedIn) {
					rewardCoupons = await this.db
						.collection("customer-profiles")
						.doc(this.userService.profile.userID)
						.collection("coupons", ref => ref.where("couponCode", "==", coupon.couponCode))
						.get()
						.toPromise();
				}
				if (rewardCoupons == undefined || rewardCoupons.empty) {
					return false;
				} else {
					for (const couponDoc of rewardCoupons.docs) {
						this.db
							.collection("customer-profiles")
							.doc(this.userService.profile.userID)
							.collection("coupons")
							.doc(couponDoc.id)
							.set({ numberOfUses: coupon.numberOfUses }, { merge: true });
					}
				}
			} else {
				for (const couponDoc of coupons.docs) {
					this.db
						.collection("coupons")
						.doc(couponDoc.id)
						.set({ numberOfUses: coupon.numberOfUses }, { merge: true });
				}
			}
		}
		if (this.userService.profile.isLoggedIn) {
			this.userService.fetchCoupons(this.userService.profile.userID);
		}
	}

	/** 
	 * This function reset all saved coupon in this service.
	 * called when the transaction in cart is complete.
	*/
	public resetCouponList() {
		this.currentCouponList = <any>[];
		this.currentCoupon = <any>{};
	}

	/**
	 * This function apply all available coupons from loyalty reward. 
	 * The oldest created coupon will be used first, if it is combineable, then apply others coupon.
	 * 
	 * @param gastroID - Gastro ID
	 * @param totalWithoutDeliveryFee - Total price without delivery
	 * @returns 
	 */
	public async applyCouponRewardAuto(gastroID, totalWithoutDeliveryFee) {
		const rewardCoupons = <any> await this.db.collection("customer-profiles").doc(this.userService.profile.userID)
			.collection("coupons", ref => ref.where("gastroID", "==", gastroID)).get().toPromise();
		if (rewardCoupons.empty) {
			return;
		}

		await rewardCoupons.docs.sort((a, b) => {
			if (a.data().createdAt != undefined && b.data().createdAt != undefined) {
				return a.data().createdAt.toDate() - b.data().createdAt.toDate();
			}
			return 0;
		});

		let totalRestriction = totalWithoutDeliveryFee;
		for (const rewardCoupon of rewardCoupons.docs) {

			if (rewardCoupon.exists) {
				let couponData = rewardCoupon.data();

				//fetch coupon data if coupon is not personalized (coupon that generated by gastro, not from loyalty card)
				if (couponData.personalized == false) {
					const nonPersonalizedCoupon = await this.db
						.collection("coupons", ref => ref
							.where("couponCode", "==", couponData.couponCode)
							.where("gastroID", "==", couponData.gastroID))
						.get()
						.toPromise();
					if (!nonPersonalizedCoupon.empty) {
						const nonPersonalizedCouponData = await nonPersonalizedCoupon.docs[0].data();
						//merge 2 object with spread operator
						couponData = {
							...couponData,
							...nonPersonalizedCouponData as object,
						};
					} else {
						continue;
					}
				}

				this.currentCoupon = couponData;
				if (
					this.checkIfCouponCodeIsValidDate()
					&& this.checkIfCouponHasUsesLeft()
					&& await this.checkIfSplitometerIsAllowed(gastroID)
					&& this.checkIfCouponCodeIsValidRestriction(totalRestriction)
					&& this.checkIfCouponCodeIsNotDuplicate() && this.checkIfCouponCanCombine()
				) {
					if (this.currentCoupon.rewardType == "flatAmount") {
						totalRestriction -= this.currentCoupon.rewardMagnitude;
					} else if (this.currentCoupon.rewardType == "flatPercentage") {
						totalRestriction -= totalWithoutDeliveryFee * (this.currentCoupon.rewardMagnitude / 100);
					}
					if (totalRestriction >= 0) {
						this.addCouponToList();
					}

				} else {
					this.currentCoupon = {};
				}
			} else {
				this.currentCoupon = {};
			}

		}

	}

	/**
	 * This function delete coupon entry in database
	 * 
	 * @param couponCode - coupon code to be deleted
	 * @param gastroID - gastro ID of the coupon
	 */
	public async deleteCouponEntry(couponCode, gastroID) {
		try {
			const coupon = await this.db
				.collection("customer-profiles")
				.doc(this.userService.profile.userID)
				.collection("coupons", ref => ref
					.where("couponCode", "==", couponCode)
					.where("gastroID", "==", gastroID))
				.get().toPromise();
			await this.db
				.collection("customer-profiles")
				.doc(this.userService.profile.userID)
				.collection("coupons")
				.doc(coupon.docs[0].id)
				.delete();
			this.userService.profile.coupons.splice(this.userService.profile.coupons.indexOf(coupon), 1);
			this.successToast("Gutschein wurde erfolgreich gelöscht");
		} catch (error) {
			this.failedToast("Es gibt ein Fehler beim Löschen");
			console.error(error);
		}
	}

	/**
	 * This function check if there is coupon inside coupon list
	 * 
	 * @returns - Boolean
	 */
	public isCouponUsed(): boolean {
		if (this.currentCouponList == undefined || this.currentCouponList.length == 0) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * This function adds a non personalized coupon (coupon that generated by gastro, not from loyalty card)
	 * into 'coupons' collection under 'customer-profile'
	 * 
	 * @param couponCode - coupon code to be added
	 */
	public async addNonPersonalizedCoupon(couponCode) {
		// check if no duplicate code in the profile
		const coupons = await this.db
			.collection("customer-profiles")
			.doc(this.userService.profile.userID)
			.collection("coupons", ref => ref.where("couponCode", "==", couponCode))
			.get()
			.toPromise();
		if (!coupons.empty) {
			this.failedToast("Dieses Gutschein ist schon gespeichert");
			return;
		}
		const nonPersonalizedCoupons = await this.db
			.collection("coupons", ref => ref.where("couponCode", "==", couponCode))
			.get()
			.toPromise();
		if (nonPersonalizedCoupons.empty) {
			this.failedToast("Gutschein wurde nicht gefunden");
		} else {
			try {
				const dateOptions = {
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
					month: "long",
					second: "numeric",
					weekday: "short",
					year: "numeric",
				};
				const nonPersonalizedCouponData:any = nonPersonalizedCoupons.docs[0].data(); //TODO typisieren
				//save to database
				await this.db
					.collection("customer-profiles")
					.doc(this.userService.profile.userID)
					.collection("coupons")
					.add({
						couponCode: couponCode,
						gastroID: nonPersonalizedCouponData.gastroID,
						personalized: false,
					});
				//check if all coupons still valid
				const now = new Date().getTime();
				if (
					nonPersonalizedCouponData.numberOfUses != undefined
					&& (
						nonPersonalizedCouponData.numberOfUses > 0
						|| nonPersonalizedCouponData.numberOfUses == "none"
					)
				) {
					if (
						nonPersonalizedCouponData.expireAt != undefined
						&& nonPersonalizedCouponData.expireAt == "none"
					) {
						nonPersonalizedCouponData.valid = true;
					} else if (
						nonPersonalizedCouponData.expireAt != undefined
						&& (
							nonPersonalizedCouponData.expireAt.toDate().getTime() > now
							&& nonPersonalizedCouponData.validFrom.toDate().getTime() < now
						)
					) {
						nonPersonalizedCouponData.valid = true;
					} else {
						nonPersonalizedCouponData.valid = false;
					}
				} else {
					nonPersonalizedCouponData.valid = false;
				}

				//fetch gastro data
				const tmpGastro = await this.userService.getGastroFromID(nonPersonalizedCouponData.gastroID);
				nonPersonalizedCouponData.gastro = tmpGastro;
				if (nonPersonalizedCouponData.createdAt != undefined) {
					nonPersonalizedCouponData.createdAt = nonPersonalizedCouponData
						.createdAt
						.toDate()
						.toLocaleDateString("de-DE", dateOptions);
				}
				nonPersonalizedCouponData.personalized = false;
				//add to local array
				this.userService.profile.coupons.push(nonPersonalizedCouponData);
				this.successToast("Gutschein wurde erfolgreich gespeichert");
			} catch (error) {
				this.failedToast("Es gibt ein Fehler beim Speicherung");
			}
		}
	}


	/**
	 * This function creates and shows success alert
	 * 
	 * @param message - message to be showed
	 */
	public async successToast(message: string) {
		const toast = await this.toastController.create({
			color: "success",
			duration: 3000,
			message: message,
			position: "bottom",
		});
		toast.present();
	}

	/**
	 * This function creates and shows failed alert
	 * 
	 * @param message - message to be showed
	 */
	public async failedToast(message: string) {
		const toast = await this.toastController.create({
			color: "danger",
			duration: 3000,
			message: message,
			position: "bottom",
		});
		toast.present();
	}


}
export class Coupon {
	couponCode: string;
	rewardType: string;
	rewardMagnitude: number;
	gastroID?: string[];
	restriction: string;
	expireAt: Date;
	numberOfUses: number;
}

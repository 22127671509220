// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyDp1IP83ZjhX1c13fUqZda88WYWWZcRg0s",
		authDomain: "split-test-1.firebaseapp.com",
		databaseURL: "https://split-test-1.firebaseio.com",
		projectId: "split-test-1",
		storageBucket: "",
		messagingSenderId: "424925011673",
		appId: "1:424925011673:web:dda8c3ee689682fa",
		measurementId: "G-FPS65V51QT",
	},
	functionsUrl: "https://us-central1-split-test-1.cloudfunctions.net/",
	functionsUrlEU: "https://europe-west3-split-test-1.cloudfunctions.net/",
	stripePK: "pk_test_yDzAbQwZZIFXwZxacICWYI8S00IN3MtoTA",
	payment: {
		paypal: {
			clientId: "AUkDQFg5mFFDxj5VzCMtirbvX-VwelGJ_jUmVHYM_qpv0Djs3KNEg9EoRTM0CXYtTeaFDVNc5ADhv-QJ",
		},
	},
	orderbird: {
		myObUrl: "https://staging-myob.orderbird.com/",
	},
	globalsID: "lHWzjXutxy8VvyytHVh9",
	dibsCheckoutUrl: "https://test.checkout.dibspayment.eu/v1/checkout.js?v=1",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

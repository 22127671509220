import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Gastro } from "src/shared/split-submodules/types/types";
import { PlatformService } from "./platform.service";

@Injectable({ providedIn: "root" })
export class LocationsService {

	public gastroList: any[] = [];
	public displayedGastros: any[] = [];
	public citys: any[] = [];
	public goLinks: Observable<any[]>;
	public listSub: Subscription;
	public previousCity = "Gesamt";
	private numberOfGastrosToStartWith = 5;
	private numberOfGastrosToLoadOnceAtTheEndOfThePage = 3; //At least one full new row has to be loaded!


	constructor(
		public db: AngularFirestore,
		public router: Router,
		public navCtrl: NavController,
		public platformService: PlatformService,
	) {
		this.loadList(); this.getCitys();
	}

	loadList() {
		this.listSub = this.db.collection("gastro", ref => ref.where("isListed", "==", true)).get().subscribe(docs => {
			docs.docs.sort((a, b) => {
				return this.sortByOpeningHoursAndPriority(a, b);
			});
			this.gastroList = docs.docs;
			let oldLength = this.displayedGastros.length;
			if (oldLength == 0) {
				oldLength = this.numberOfGastrosToStartWith;
			}
			this.displayedGastros = [];
			for (let i = 0; i < oldLength && i < this.gastroList.length; i++) {
				this.gastroList[i] = this.addPlatformValues(this.gastroList[i]);
				console.log(this.gastroList[i]);
				this.displayedGastros.push(this.gastroList[i]);
			}
		});
		this.goLinks = this.db.collection("go").valueChanges();
	}

	addPlatformValues(gastro: Gastro) {
		const ret: any = gastro;
		ret.displayedDiscounts = this.platformService.getGastroDiscounts(gastro);
		return ret;
	}

	loadMore(event?) {
		const startIndex = this.displayedGastros.length;
		const numberToLoad = this.numberOfGastrosToLoadOnceAtTheEndOfThePage;
		for (let i = startIndex; i < this.gastroList.length && i < startIndex + numberToLoad; i++) {
			this.gastroList[i] = this.addPlatformValues(this.gastroList[i]);
			this.displayedGastros.push(this.gastroList[i]);
		}
		if (event) {
			event.target.complete();
			if (this.displayedGastros.length == this.gastroList.length) {
				event.target.disabled = true;
			}
		}
	}

	redirectWithID(id) {
		this.db
			.collection("qrcodes", ref => ref.where("gastroID", "==", id).where("inhouse", "==", false))
			.get()
			.subscribe((e) => {
				if (e.empty) {
					this.db.collection("qrcodes", ref => ref.where("gastroID", "==", id)).get().subscribe((docs) => {
						this.navCtrl.navigateRoot(`/qrcode?id=${docs.docs[0].id}`);
					});
				} else {
					this.navCtrl.navigateRoot(`/qrcode?id=${e.docs[0].id}`);
				}
			});
	}

	private getTimeString(date) {
		let hours = date.getHours().toString();
		let minutes = date.getMinutes().toString();

		if (hours.length < 2) {
			hours = `0${hours}`;
		}

		if (minutes.length < 2) {
			minutes = `0${minutes}`;
		}

		return `${hours}:${minutes}`;
	}

	isOpen(gastro) {
		if (gastro.active == false) {
			return false;
		}

		if (gastro.pickupInactive === true && gastro.deliveryInactive === true) {
			return false;
		}

		const now = new Date();
		if (gastro.openingHours == undefined) {
			return true;
		}
		const times = gastro.openingHours.filter(elem => elem.day == now.getDay());
		const timeString = this.getTimeString(now);

		let nowWithinIntervall = false;

		times.forEach(time => {
			if (time.from <= timeString && timeString <= time.to) {
				nowWithinIntervall = true;
			}
		});

		return nowWithinIntervall;
	}

	private convertPriority(prio) {
		switch (prio) {
		case "Gesponsort": return 0;
		case "Neu": return 1;
		case "Sehr Hoch": return 2;
		case "Hoch": return 3;
		case "Mittel": return 4;
		case "Gering": return 5;
		case "Sehr Gering": return 6;
		default: return 100;
		}
	}

	sortByOpeningHoursAndPriority(a: any, b: any) {
		const openA = this.isOpen(a);
		const openB = this.isOpen(b);
		if (openA == openB) {
			return this.convertPriority(a.priority) - this.convertPriority(b.priority);
		} else if (openA) {
			return -1;
		} else {
			return 1;
		}
	}

	getCitys() {
		this.db.collection<any>("location-summary").get().toPromise().then(docs => {
			this.citys = [];
			docs.forEach((doc) => {
				if (doc.data().name != "overall" && doc.data().name != "Overall") {
					this.citys.push(doc.data());
				}
			});
		});
	}

	getGastrosOfCity(city) {
		let PLZs = [];
		console.log(city);
		this.listSub.unsubscribe();
		this.db
			.collection<any>("location-summary", ref => ref.where("base", "array-contains", city))
			.get()
			.toPromise()
			.then(docs => {
				docs.forEach(doc => {
					PLZs = [];
					doc.data().plzList.forEach(plz => {
						PLZs.push(plz);
					});
				});
			}).then(() => {
				if (PLZs.length > 0) {
					this.gastroList = [];
					//Split the array into parts because 'in' operator only supports up to 10 array-entries
					for (let i = 0; i < PLZs.length; i += 10) {
						const tmp = PLZs.slice(i, i + 10);
						this.db
							.collection(
								"gastro",
								ref => ref.where("isListed", "==", true).where("address.PLZ", "in", tmp),
							)
							.get()
							.subscribe(docs => {
								docs.docs.forEach((doc) => {
									const gastro = this.addPlatformValues(doc.data() as Gastro);
									this.gastroList.push(gastro);
								});
								for (let i = 0; i < this.gastroList.length; i++) {
									const selectedGastro = this.gastroList[i];
									for (let p = i + 1; p < this.gastroList.length; p++) {
										const gastroToCompare = this.gastroList[p];
										if (selectedGastro.id == gastroToCompare.id) {
											this.gastroList[i] = gastroToCompare;
											this.gastroList.splice(p, 1);
										}
									}
								}
								this.gastroList.sort((a, b) => {
									return this.sortByOpeningHoursAndPriority(a, b);
								});
								let oldLength = this.displayedGastros.length;
								if (oldLength == 0) {
									oldLength = this.numberOfGastrosToStartWith;
								}
								this.displayedGastros = [];
								for (let i = 0; i < oldLength && i < this.gastroList.length; i++) {
									this.displayedGastros.push(this.gastroList[i]);
								}
							});
					}
				} else {
					this.gastroList = [];
				}
			});


	}
}

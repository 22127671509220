import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterAgbComponent } from "./footer-agb/footer-agb.component";
import { IonicModule } from "@ionic/angular";
import { SimpleImgModalComponent } from "./simple-img-modal/simple-img-modal.component";
import { TagListComponent } from "./tag-list/tag-list.component";
import {
	ProductsInCartDeletedAlertedComponent,
} from "../modals/products-in-cart-deleted-alerted/products-in-cart-deleted-alerted.component";
import { ModalObImgTextComponent } from "./modal-ob-img-text/modal-ob-img-text.component";
import { ShowMoreComponent } from "./show-more/show-more.component";
@NgModule({
	declarations: [
		ModalObImgTextComponent,
		FooterAgbComponent,
		SimpleImgModalComponent,
		TagListComponent,
		ProductsInCartDeletedAlertedComponent,
		ShowMoreComponent,
	],
	exports: [
		SimpleImgModalComponent,
		FooterAgbComponent,
		TagListComponent,
		ProductsInCartDeletedAlertedComponent,
		ShowMoreComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
	],
})
export class ComponentsModule { }

import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Table } from "src/shared/split-submodules/types/types";
import { GastroService } from "./gastro.service";
import { GastroResettable, ResetService } from "./reset.service";
import { StorageService } from "./storage.service";
@Injectable({ providedIn: "root" })
export class TableService implements GastroResettable {

	private table: Table;
	private tablePromise: Promise<Table>;

	public getTable(): Table {
		return this.table;
	}

	public async getAsyncTable() {
		return await this.tablePromise;
	}

	public async setTable(tableId: string, gastroId: string = undefined) {
		const gId = gastroId === undefined ? this.gastroService.$gastroId : gastroId;
		if (tableId === undefined || tableId === "") {
			this.table = undefined;
			this.storageService.clear("tableId");
		}
		if (gId === undefined || gId === null) {
			return;
		}

		const tableRef = await this.afs
			.collection("gastro")
			.doc(gId)
			.collection("table")
			.doc(tableId)
			.get()
			.toPromise();

		this.table = <Table>tableRef.data();
		this.tablePromise = new Promise<Table>((resolve) => {
			resolve(this.table);
		});

		await this.storageService.store("tableId", tableId);
	}

	public reserveTable(id: string) {
		this.setTable(id);
		this.afs
			.collection("gastro")
			.doc(this.gastroService.$gastroId)
			.collection("table")
			.doc(id)
			.update({ free: 0 });
	}

	public isInside(): boolean {
		return this.table.inside !== false;
	}

	private tableNr = 0;
	private hotel = false;

	constructor(
		public storage: Storage,
		public afs: AngularFirestore,
		private gastroService: GastroService,
		private storageService: StorageService,
		private resetService: ResetService,
	) {
		this.tablePromise = new Promise((resolve, reject) => {
			this.storage.get("tableId").then(async(id: string) => {
				const gId = await this.storage.get("gastroId");
				if (id) {
					await this.setTable(id, gId);
					resolve(this.table);
				}
			});
		});
		this.storage.get("hotel").then(val => {
			if (val) {
				this.hotel = val;
			}
		});
	}

	onGastroLogout(): void {
		this.$hotel = false;
		this.$tableNr = 0;
	}

	registerGastroReset(): void {
		this.resetService.registerGastroReset(this);
	}
	

	/**
	 * Getter $hotel
	 * @return {boolean }
	 */
	public get $hotel(): boolean {
		return this.hotel;
	}

	/**
	 * Setter $hotel
	 * @param {boolean } value
	 */
	public set $hotel(value: boolean) {
		this.storage.set("hotel", value);
		this.hotel = value;
	}

	/**
	 * Getter $tableNr
	 * @return {number }
	 */
	public get $tableNr(): number {
		return this.tableNr;
	}

	/**
	 * Setter $tableNr
	 * DEPRECATED?: this duplicated session service $tableNr
	*  DEPRECATED?:tableNr in tableService is never set
	 * @param {number } value
	 */
	public set $tableNr(value: number) {
		console.log(`The Table Number was set to: ${value.toString()}`);
		this.storage.set("tableNr", value);
		this.tableNr = value;
	}

	public getTableRef(gastroId: string) {

		return this.afs.collection("gastro").doc(gastroId)
			.collection("table").doc(this.$tableNr.toString()).get();
	}

}

import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { GastroService } from "./gastro.service";
import { HistoryService } from "./history.service";
@Injectable({ providedIn: "root" })
export class DeviceService {
	private deviceID = "";
	private showedSplash = false;
	private logInTime: Date;
	public isInLoginState = false;
	public waiterCoronaMode;

	constructor(
		private storage: Storage,
		private gastroService: GastroService,
		public historyService: HistoryService,
	) {
		this.storage.get("deviceId").then(val => {
			if (val) {
				this.deviceID = val;
			} else {
				this.generateDeviceID();
			}
		});
		this.storage.get("waiterCoronaMode").then(val => {
			if (val != null) {
				this.waiterCoronaMode = val;
			}
		});
		this.storage.get("logInTime").then(val => {
			if (val != null) {
				this.logInTime = val;
				if (this.shouldLogOut()) {
					this.historyService.$allOrderedItemsIds = [];
					this.gastroService.gastroQuit();
				}
			}
		});
		this.storage.get("showedSplash").then(val => {
			if (val) {
				this.showedSplash = val;
			}
		});
		setInterval(() => {
			if (this.shouldLogOut()) {
				this.gastroService.gastroQuit();
			}
		}, 60000);
	}

	isInLogin() {
		const ret = window.location.href.includes("/qrcode-scanner")
			|| window.location.href.includes("/qrcode")
			|| window.location.href.includes("/go")
			|| window.location.href.includes("/select-location");

		console.log(ret);
		return ret;
	}

	isNotInRestaurant() {
		const ret = window.location.href.includes("/restaurant-page")
			|| window.location.href.includes("/status")
			|| window.location.href.includes("/checkout")
			|| window.location.href.includes("/cart")
			|| window.location.href.includes("/dish-detail")
			|| window.location.href.includes("/tipping-modal");

		return !ret;
	}

	public shouldLogOut(loginCall = false) {
		//these lines remove the login bug - do not remove
		if ((this.isInLoginState || this.isInLogin()) && !loginCall) {
			return false;
		}

		if (this.isNotInRestaurant() && !loginCall) {
			return false;
		}

		const now = new Date();
		const diffTime = Math.abs(<any>now - <any> this.logInTime) / (60 * 1000);
		if (diffTime > 100) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * creates a random ID and sets the storage variable 'deviceID' to this code
	 */
	public generateDeviceID() {
		this.deviceID
			= `_${
			 Math.random()
					.toString(36)
					.substr(2, 20)}`;
		this.storage.set("deviceId", this.deviceID);
	}

	/**
	 * 
	 * @returns deviceID
	 */
	public getDeviceID() {
		return this.deviceID;
	}

	/**
	 * Getter $logInTime
	 * @return {Date}
	 */
	public get $logInTime(): Date {
		return this.logInTime;
	}

	/**
	 * Setter $logInTime
	 * @param {Date} value
	 */
	public set $logInTime(value: Date) {
		this.storage.set("logInTime", value);
		this.logInTime = value;
	}

	/**
	 * Getter $showedSplash
	 * @return {Boolean }
	 */
	public get $showedSplash(): boolean {
		return this.showedSplash;
	}

	/**
	 * Setter $showedSplash
	 * @param {Boolean } value
	 */
	public set $showedSplash(value: boolean) {
		this.storage.set("showedSplash", value);
		this.showedSplash = value;
	}
}

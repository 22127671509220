import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Storage } from "@ionic/storage";
import firebase from "firebase/compat/app";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import {
	getDateString,
	getHoursAndMinutes,
	getWeekDayAsString,
	roundX,
} from "src/shared/split-submodules/functions/functions";
import { Address, Slots } from "src/shared/split-submodules/types/types";
import { OrderService } from "./order.service";
import { GastroResettable, ResetService } from "./reset.service";
import { SentryService } from "./sentry.service";
import { StorageService } from "./storage.service";
import { UtilService } from "./util.service";

/**
 * This service is used to keep track of all relevant data for a user session
 * It shouldn't have any dependencies on other services to avoid ring dependencies
 */

@Injectable({ providedIn: "root" })
export class SessionDataService implements GastroResettable {
	public gastroId: Subject<string> = new Subject<string>();
	private tableNr: number;
	public inhouseLink: boolean;

	private userAddress: Address;
	private comesFromPlatform: boolean;
	private overEighteen;
	private overSixteen;

	private inhouse: boolean;

	private inhouseCanOrder: boolean;
	private popoverTriggered: boolean;

	private coronaInformation = {
		address: "",
		name: "",
		phone: "",
		saveInfo: false,
		time: "",
	};

	public hasSeenVytal: boolean;

	public vytalQR: string;

	private isGastroLoggedOut: boolean;

	public golinkPostParameter: ActivatedRouteSnapshot;
	public inhousePickupTable = false;
	
	public get $isGastroLoggedOut(): boolean {
		return this.isGastroLoggedOut;
	}
	public set $isGastroLoggedOut(value: boolean) {
		this.localStorage.set("isGastroLoggedOut", value);
		this.isGastroLoggedOut = value;
	}
	public async setIsGastroLoggedOutTrueAsync() {
		await this.localStorage.set("isGastroLoggedOut", true);
		this.isGastroLoggedOut = true;
	}
	private comesFromPlatformTemp: boolean;
	public get $comesFromPlatformTemp(): boolean {
		return this.comesFromPlatformTemp;
	}
	public set $comesFromPlatformTemp(value: boolean) {
		this.localStorage.set("comesFromPlatformTemp", value);
		this.comesFromPlatformTemp = value;
	}

	public get $overEighteen(): boolean {
		return this.overEighteen;
	}
	public set $overEighteen(value: boolean) {
		this.localStorage.set("overEighteen", value);
		this.overEighteen = value;
	}

	public get $overSixteen(): boolean {
		return this.overSixteen;
	}
	public set $overSixteen(value: boolean) {
		this.localStorage.set("overSixteen", value);
		this.overSixteen = value;
	}

	public get $vytalQR(): string {
		return this.vytalQR;
	}

	public set $vytalQR(value: string) {
		this.localStorage.set("vytalQR", value);
		this.vytalQR = value;
	}
	public get $hasSeenVytal(): boolean {
		return this.hasSeenVytal;
	}

	public set $hasSeenVytal(value: boolean) {
		this.localStorage.set("hasSeenVytal", value);
		this.hasSeenVytal = value;
	}

	//#region Get/Set

	// @ts-ignore
	public set $gastroId(id: string) {
		this.localStorage.set("gastroId", id);
		this.gastroId.next(id);
	}

	// @ts-ignore
	public get $gastroId(): Observable<string> {
		return this.gastroId.asObservable();
	}

	public get $tableNr(): number {
		return this.tableNr;
	}

	public set $tableNr(id: number) {
		this.tableNr = id;
		this.localStorage.set("tableNr", id);
	}

	public get $inhouseLink(): boolean | undefined {
		return this.inhouseLink;
	}

	public set $inhouseLink(newValue: boolean) {
		this.inhouseLink = newValue;
		this.localStorage.set("inhouseLink", newValue);
	}

	public get $userAddress(): Address {
		return this.userAddress;
	}

	public set $userAddress(newAddress: Address) {
		this.userAddress = newAddress;
	}

	public set $popoverTriggered(newValue: boolean) {
		this.popoverTriggered = newValue;
		this.localStorage.set("popoverTriggered", newValue);
	}

	public get $popoverTriggered(): boolean {
		return this.popoverTriggered;
	}

	public get $inhouse(): boolean {
		return this.inhouse;
	}

	public set $inhouse(newValue: boolean) {
		this.inhouse = newValue;
	}

	private deliveryInformations: any;
	public get $deliveryInformations(): any {
		return this.deliveryInformations;
	}
	public set $deliveryInformations(value: any) {
		this.deliveryInformations = value;
	}

	//CLEANUP: CartPage
	public offerAffectsMaxTime = false;

	/**
	 * Getter $comesFromPlatform
	 * @return {boolean }
	 */
	 public get $comesFromPlatform(): boolean {
		return this.comesFromPlatform;
	}

	/**
	 * Setter $comesFromPlatform
	 * @param {boolean } value
	 */
	public set $comesFromPlatform(value: boolean) {
		this.localStorage.set("comesFromPlatform", value);
		this.comesFromPlatform = value;
	}

	public get $inhouseCanOrder(): boolean {
		return this.inhouseCanOrder;
	}

	public set $inhouseCanOrder(newValue: boolean) {
		this.inhouseCanOrder = newValue;
		this.localStorage.set("inhouseCanOrder", newValue);
	}

	public get $coronaInformation() {
		return this.coronaInformation;
	}

	public set $coronaInformation(newValue: {
		name: string;
		phone: string;
		address: string;
		time: string;
		saveInfo: boolean;
	}) {
		this.coronaInformation = newValue;
	}

	private pickUpTime = "So bald wie möglich";
	public get $pickUpTime(): any {
		return this.pickUpTime;
	}
	public set $pickUpTime(value: any) {
		if (value === "NaN:NaN") {
			value = "";
		}
		this.pickUpTime = value;
	}
	private deliveryTime = "So bald wie möglich";
	public get $deliveryTime(): any {
		return this.deliveryTime;
	}
	public set $deliveryTime(value: any) {
		this.deliveryTime = value;
	}

	private pickupDate = getDateString(new Date());
	public get $pickupDate() {
		return this.pickupDate;
	}
	public set $pickupDate(value) {
		this.pickupDate = value;
		const dateArray = value.split(".");
		const dateUS = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
		this.$pickupDateShown = `${getWeekDayAsString(new Date(dateUS))}, den ${value}`;
	}

	//CLEANUP: OrderService
	private deliveryDate = getDateString(new Date());
	public get $deliveryDate() {
		return this.deliveryDate;
	}
	public set $deliveryDate(value) {
		this.deliveryDate = value;
		const dateArray = value.split(".");
		const dateUS = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
		this.$deliveryDateShown = `${getWeekDayAsString(new Date(dateUS))}, den ${value}`;
	}

	//CLEANUP: CartPage
	private pickupDateShown = `${getWeekDayAsString(new Date())}, den ${this.$pickupDate}`;
	public get $pickupDateShown() {
		return this.pickupDateShown;
	}
	public set $pickupDateShown(value) {
		this.pickupDateShown = value;
	}

	//CLEANUP: CartPage
	public deliveryDateShown = `${getWeekDayAsString(new Date())}, den ${this.deliveryDate}`;
	public get $deliveryDateShown() {
		return this.deliveryDateShown;
	}
	public set $deliveryDateShown(value) {
		this.deliveryDateShown = value;
	}

	private isPickUp: boolean;
	public get $isPickUp(): boolean {
		return this.isPickUp;
	}
	public set $isPickUp(value: boolean) {
		this.isPickUp = value;
	}
	private isDelivery: boolean;
	public get $isDelivery(): boolean {
		return this.isDelivery;
	}
	public set $isDelivery(value: boolean) {
		this.isDelivery = value;
	}
	private isToGo: boolean;
	public get $isToGo(): boolean {
		return this.isToGo;
	}
	public set $isToGo(value: boolean) {
		this.isToGo = value;
	}

	private nickname: string;
	public get $nickname(): string {
		return this.nickname;
	}
	public set $nickname(value: string) {
		this.nickname = value;
	}

	public get $inhousePickupTable(): boolean {
		return this.inhousePickupTable;
	}
	public set $inhousePickupTable(value: boolean) {
		this.localStorage.set("inhousePickupTable", value);
		this.inhousePickupTable = value;
	}

	//#endregion

	constructor(
		private localStorage: Storage,
		private storageService: StorageService,
		private utilService: UtilService,
		private sentryService: SentryService,
		private resetService: ResetService,
		public afs: AngularFirestore,
	) {
		this.registerGastroReset();
		if (!this.isInLogin()) {
			this.loadFromStorage();
			this.sentryService.setSentryTag("version", "7");
		}
	}
	onGastroLogout(): void {
		this.$gastroId = undefined;
		this.$tableNr = undefined;
		this.$pickUpTime = "So bald wie möglich";
		this.$pickupDate = getDateString(new Date());
		this.$deliveryTime = "So bald wie möglich";
		this.$deliveryDate = getDateString(new Date());
		this.$nickname = undefined;
		this.$coronaInformation = {
			address: "",
			name: "",
			phone: "",
			saveInfo: false,
			time: "",
		};
		this.$vytalQR = "";
		this.$hasSeenVytal = false;
		this.$inhousePickupTable = false;
	}

	registerGastroReset(): void {
		this.resetService.registerGastroReset(this);
	}

	isInLogin() {
		const ret = window.location.href.includes("/qrcode-scanner")
			|| window.location.href.includes("/qrcode")
			|| window.location.href.includes("/go")
			|| window.location.href.includes("/select-location");

		return ret;
	}

	/**
	 * Try to load all values from the localStorage
	 * !can cause race conditions when loaded in constructor!
	 */
	public async loadFromStorage() {
		const id: string = await this.localStorage.get("gastroId");
		if (id !== undefined) {
			this.gastroId.next(id);
			this.sentryService.setSentryTag("gastroID", this.$gastroId);
		}

		const nr: number = await this.localStorage.get("tableNr");
		if (nr !== undefined) {
			this.$tableNr = nr;
			this.sentryService.setSentryTag("tableID", this.$tableNr);
		}

		const link = await this.localStorage.get("inhouseLink");
		if (link !== undefined) {
			this.$inhouseLink = link;
		}

		const inhouseCanOrder = await this.localStorage.get("inhouseCanOrder");
		if (inhouseCanOrder !== undefined) {
			this.$inhouseCanOrder = inhouseCanOrder;
		}
		const comesFromPlatform = await this.localStorage.get("comesFromPlatform");
		if (comesFromPlatform !== undefined) {
			this.$comesFromPlatform = comesFromPlatform;
		}
		const popoverTriggered = await this.localStorage.get("popoverTriggered");
		if (popoverTriggered !== undefined) {
			this.$popoverTriggered = popoverTriggered;
		}

		const vytalQR = await this.localStorage.get("vytalQR");
		if (vytalQR !== undefined) {
			this.$vytalQR = vytalQR;
		}

		const hasSeenVytal = await this.localStorage.get("hasSeenVytal");
		if (hasSeenVytal !== undefined) {
			this.$hasSeenVytal = hasSeenVytal;
		}

		const overEighteen = await this.localStorage.get("overEighteen");
		if (overEighteen !== undefined) {
			this.$overEighteen = overEighteen;
		}

		const overSixteen = await this.localStorage.get("overSixteen");
		if (overSixteen !== undefined) {
			this.$overSixteen = overSixteen;
		}

		const inhousePickupTable = await this.localStorage.get("inhousePickupTable");
		if (inhousePickupTable !== undefined) {
			this.inhousePickupTable = inhousePickupTable;
		}
	}

	public clearStorage() {
		this.localStorage.remove("gastroId");
		this.localStorage.remove("tableNr");
		this.localStorage.remove("inhouseLink");
	}

	//CLEANUP: CartPage
	initDate(gastro: any, cart: any[], foodAmount: number) {
		if (gastro.hasPickup && !gastro.pickupInactive) {
			const options = this.getColumnsForDatesPickup(gastro, cart, foodAmount)[0].options;
			if (options.length > 0) {
				this.$pickupDate = options[0].text;
			}
		}
		if (gastro.hasDelivery && !gastro.deliveryInactive) {
			const options = this.getColumnsForDatesDelivery(gastro, cart, foodAmount)[0].options;
			if (options.length > 0) {
				this.$deliveryDate = options[0].text;
			}
		}
	}

	//CLEANUP: CartPage
	public getColumnsForDatesDelivery(gastro: any, cart: any[], foodAmount: number) {
		let ret = [];
		if (gastro.deliveryTimes != undefined && gastro.deliveryTimes.length != 0) {
			ret = this.getColumnsForDates(gastro.deliveryTimes, gastro.deliveryPreTime, gastro, cart, foodAmount);
		} else {
			ret = this.getColumnsForDatesMinMax(gastro.deliveryPreTime, gastro, cart, foodAmount);
		}
		return ret;
	}

	//CLEANUP: CartPage
	public getColumnsForDatesPickup(gastro: any, cart: any[], foodAmount: number) {
		let ret = [];
		if (gastro.pickupTimes != undefined && gastro.pickupTimes.length != 0) {
			ret = this.getColumnsForDates(gastro.pickupTimes, gastro.pickupPreTime, gastro, cart, foodAmount);
		} else {
			ret = this.getColumnsForDatesMinMax(gastro.pickupPreTime, gastro, cart, foodAmount);
		}
		return ret;
	}

	//CLEANUP: CartPage
	private getColumnsForDates(
		timesArr: any,
		preTime: number,
		gastro: any,
		cart: any[],
		foodAmount: number,
		overtime = 0,
	) {
		const now = new Date();
		let minusDay = 0;
		if (gastro.isBreakfastShop === true) {
			let ot;
			let now2;
			minusDay = 1;
			if (environment.production == true) {
				ot = firebase.firestore.Timestamp.now().toDate();
				now2 = firebase.firestore.Timestamp.now().toDate();
			} else {
				ot = new Date();
				now2 = new Date();
			}
			const overtimeLimit = gastro.overtimeLimit.split(":");
			ot.setHours(Number.parseInt(overtimeLimit[0]));
			ot.setMinutes(Number.parseInt(overtimeLimit[1]));
			if (ot <= now2) {
				now.setDate(now.getDate() + 1);
				minusDay = 2;
			}
			now.setMinutes(now.getMinutes() + this.utilService.minutesUntilMidnight());
		} else {
			now.setMinutes(roundX(now.getMinutes() + preTime + overtime, 10));
		}
		if (gastro.hasPreTimePerFood) {
			now.setMinutes(roundX(now.getMinutes() + (gastro.preTimePerFood * foodAmount), 10));
		}
		const ret = [{ name: "col-0", options: [] }];
		let preDays = 0;
		if (gastro.orderingInAdvanceByDays != undefined) {
			preDays = gastro.orderingInAdvanceByDays;
		}

		preDays -= minusDay;

		for (let i = 0; i <= preDays; i++) {
			if (timesArr.filter(a => a.day == now.getDay()).length > 0) {
				if (!this.selectedOfferAffectsDates(gastro, cart) || i == 0) {
					ret[0].options.push({ text: getDateString(now) });
				}
			}
			now.setDate(now.getDate() + 1);
		}

		return ret;
	}

	//CLEANUP: CartPage
	private getColumnsForDatesMinMax(preTime: number, gastro: any, cart: any[], foodAmount: number) {
		const now = new Date();
		now.setMinutes(roundX(now.getMinutes() + preTime, 10));
		if (gastro.hasPreTimePerFood) {
			now.setMinutes(roundX(now.getMinutes() + (gastro.preTimePerFood * foodAmount), 10));
		}
		const ret = [{ name: "col-0", options: [] }];
		let preDays = 0;
		if (gastro.orderingInAdvanceByDays != undefined) {
			preDays = gastro.orderingInAdvanceByDays;
		}
		for (let i = 0; i <= preDays; i++) {
			if (!this.selectedOfferAffectsDates(gastro, cart) || i == 0) {
				ret[0].options.push({ text: getDateString(now) });
			}
			now.setDate(now.getDate() + 1);
		}

		return ret;
	}

	public initTimes(gastro: any, cart: any[], foodAmount: number) {
		this.initDate(gastro, cart, foodAmount);
		this.initTime(gastro, cart, foodAmount);
	}

	//CLEANUP: CartPage
	public async initTime(gastro: any, cart: any[], foodAmount: number) {
		if (gastro.hasPickup && !gastro.pickupInactive) {
			let options = this.getColumnsForTimesPickup(gastro, cart, foodAmount)[0].options;
			if (gastro.pickupSlots != undefined && gastro.pickupSlots.length > 0) {
				const now = new Date();
				const pickupSlot = gastro.pickupSlots.filter(slot => slot.day === parseInt(now.getDay().toString()))[0];
				if (pickupSlot !== undefined) {
					options = await this.deleteOverlaps(options, "pickup", pickupSlot, gastro.id);
				}
			}
			if (options != undefined && options.length > 0) {
				this.$pickUpTime = options[0].text;
			}
		}
		if (gastro.hasDelivery && !gastro.deliveryInactive) {
			let options = this.getColumnsForTimesDelivery(gastro, cart, foodAmount)[0].options;
			if (gastro.deliverSlots != undefined && gastro.deliverSlots.length > 0) {
				const now = new Date();
				const deliverSlot = gastro.deliverSlots
					.filter(slot => slot.day === parseInt(now.getDay().toString()))[0];
				if (deliverSlot !== undefined) {
					options = await this.deleteOverlaps(options, "delivery", deliverSlot, gastro.id);
				}
			}
			if (options != undefined && options.length > 0) {
				this.$deliveryTime = options[0].text;
			}
		}
	}

	/*
	* Deletes too early times from dropdown for timeslots 
	* @param options -> options of dropdown
	* @param type -> type of order (pickup|delivery)
	* @return returns new array without the early times
	*/
	async deleteOverlaps(options, type:string, slotInfo:Slots, gastroId:string) {
		const newOptions = [];
		const firstTime = this.getTimeFromTimestamp(new Date());
		const orders = await this.getOrdersForRestOfDay(firstTime, gastroId, type);
		const activeOrders = [];
		if (orders != undefined) {
			orders.forEach((order)=>{
				if (activeOrders.filter(activeOrder => activeOrder.cartID === order.data().cartID).length == 0) {
					activeOrders.push(order.data());
				}
			});
		}
		for (const option of options) {
			if (option.text != "Sobald wie möglich") {
				newOptions.push(option);
			}
			let ordersInTimeInterval = 0;
			let time;
			for (const order of activeOrders) {
				if (type == "delivery") {
					time = order.deliveryInformations.expectedDeliveryTime;
				} else if (type == "pickup") {
					time = order.pickupTime;
				}

				const calculatetInterval = SessionDataService.calculatedInterval(slotInfo.interval);

				const to = this.getTimeFromTimestamp(new Date(SessionDataService.getTimestampFromData(time).getTime()
				+ calculatetInterval[0]));
				const from = this.getTimeFromTimestamp(new Date(SessionDataService.getTimestampFromData(time).getTime()
				- calculatetInterval[1]));
				if (option.text >= from && option.text < to) {
					ordersInTimeInterval++;
					if (ordersInTimeInterval >= slotInfo.numberPerInterval) {
						const indexOfOption = newOptions.indexOf(option);
						if (indexOfOption !== -1) {
							newOptions.splice(indexOfOption, 1);
						}
					}
				}
			}
		}
		return newOptions;
	}

	static calculatedInterval(interval:number): number[] {
		let firstInterval:number;
		let secondInterval:number;
		if ((interval / 2) % 2 === 1) {
			//in case that the interval is 30 or 50 -> interval would 15 or 25 and we would loose 10 minutes in the calculation
			//so we split the interval in 10 and 20 (for interval 30) or 20 and 30 (for interval 50) 
			firstInterval = (((interval / 2) + 5) * 60000);
			secondInterval = (((interval / 2) - 5) * 60000);
		} else {
			firstInterval = ((interval / 2) * 60000);
			secondInterval = ((interval / 2) * 60000);
		}
		return ([firstInterval, secondInterval]);
	}

	/*
	* Returns Time String from timestamp, like they are saved in Firestore 
	* @input unix timestamp
	* return example: "20:10"
	*/
	getTimeFromTimestamp(timestamp) {
		return `${(`0${timestamp.getHours()}`).slice(-2)}:${(`0${timestamp.getMinutes()}`).slice(-2)}`;
	}

	/*
	* @input givenTime -> Time String -> "20:10"
	* @return unix timestamp
	*/
	static getTimestampFromData(givenTime) {
		const timestamp = new Date();
		timestamp.setMinutes(parseInt(givenTime.slice(3, 5)));
		timestamp.setHours(parseInt(givenTime.slice(0, 2)));
		return timestamp;
	}

	/*
	* Function to make a call to firebase returning 'order' documents since a given start time
	* @input start -> start of interval -> "20:10"
	* @input gastroId -> Id of gastro, where orders should be fetched from
	* @input type -> type of order to be fetched ("delivery|pickup")
	* @return -> Array of all documents found in interval, if none found -> undefined
	*/
	async getOrdersForRestOfDay(start, gastroId, type) {
		let orders;
		if (type == "pickup") {
			 orders = await this.afs
				.collection("gastro")
				.doc(gastroId)
				.collection("order", (ref) => ref.where("pickupDate", "==", this.$pickupDate)
					.where("pickupTime", ">=", start))
				.get()
				.toPromise();
			if (orders != undefined && orders.docs.length > 0) {
				return orders.docs;
			}
		} else {
			const orders = await this.afs
				.collection("gastro")
				.doc(gastroId)
				.collection("order", (ref) => ref
					.where("deliveryInformations.deliveryDate", "==", this.$deliveryDate)
					.where("deliveryInformations.expectedDeliveryTime", ">=", start))
				.get()
				.toPromise();
			if (orders != undefined && orders.docs.length > 0) {
				return orders.docs;
			}
		}
		return undefined;
	}

	//CLEANUP: CartPage
	public getColumnsForTimesPickup(gastro: any, cart: any[], foodAmount: number) {
		let ret = [];
		if (gastro.pickupTimes == undefined || gastro.pickupTimes.length == 0) {
			ret = this.getColumnsForTimesMinMax(
				gastro.minPickup,
				gastro.maxPickup,
				this.$pickupDate,
				gastro.pickupPreTime,
				gastro,
				cart,
				foodAmount,
			);
		} else {
			ret = this.getColumnsForTimes(
				gastro.pickupTimes,
				this.$pickupDate,
				gastro.pickupPreTime,
				gastro,
				cart,
				foodAmount,
			);
		}

		if (ret[0].options.length == 0) {
			ret[0].options.push({ text: "Heute ist leider kein Pickup mehr verfügbar." });
		}
		return ret;
	}

	//CLEANUP: CartPage
	private getColumnsForTimesMinMax(
		min: any,
		max: any,
		dateString: string,
		preTime: number,
		gastro: any,
		cart: any[],
		foodAmount: number,
	) {
		const now = new Date();
		now.setMinutes(roundX(now.getMinutes() + preTime, 10));
		if (gastro.hasPreTimePerFood) {
			now.setMinutes(roundX(now.getMinutes() + (gastro.preTimePerFood * foodAmount), 10));
		}
		const date = parseInt(dateString.split(".")[0]);
		if (now.getDate() != date) {
			let safetyBreak = 0;
			while (now.getDate() != date) {
				now.setDate(now.getDate() + 1);
				safetyBreak++;
				if (safetyBreak > 30) {
					return undefined;
				}
			}
			now.setHours(0);
			now.setMinutes(0);
		}
		dateString = getHoursAndMinutes(now);
		const ret = [{ name: "col-0", options: [] }];
		while (true) {
			if (min <= dateString && dateString <= max) {
				if (!this.selectedOfferAffectsTime(dateString, cart)) {
					ret[0].options.push({ text: dateString });
				}
			}
			now.setMinutes(now.getMinutes() + 10);
			dateString = getHoursAndMinutes(now);
			if (dateString == "00:00") {
				break;
			}
		}
		return ret;
	}

	//CLEANUP: CartPage
	private getColumnsForTimes(
		timesArr: any,
		dateString: string,
		preTimeParam: number,
		gastro: any,
		cart: any[],
		foodAmount: number,
	) {
		let preTime = preTimeParam;
		let now = new Date();
		//remove when klub kitchen and saveur chrun
		if (this.inhousePickupTable === true) {
			preTime = 0;
		}
		now.setMinutes(roundX(now.getMinutes() + preTime, 10));
		if (gastro.hasPreTimePerFood) {
			now = new Date();
			now.setMinutes(now.getMinutes() + preTime);
			now.setMinutes(roundX(now.getMinutes() + (gastro.preTimePerFood * foodAmount), 10));
		}
		const date = parseInt(dateString.split(".")[0]);
		if (now.getDate() != date) {
			let safetyBreak = 0;
			while (now.getDate() != date) {
				now.setDate(now.getDate() + 1);
				safetyBreak++;
				if (safetyBreak > 30) {
					console.log("ERROR: getColumnsForTimes.");
					return undefined;
				}
			}
			now.setHours(0);
			now.setMinutes(0);
		}
		const dayArray = timesArr.filter(day => now.getDay() == day.day);
		dateString = getHoursAndMinutes(now);
		const ret = [{ name: "col-0", options: [] }];
		let safetyBreak = 0;
		while (true) {
			dayArray.forEach((intervall) => {
				if (intervall.from <= dateString && dateString <= intervall.to) {
					if (!this.selectedOfferAffectsTime(dateString, cart)) {
						ret[0].options.push({ text: dateString });
					}
				}
			});
			if (gastro.isBreakfastShop === true) {
				now.setMinutes(now.getMinutes() + 30);
			} else {
				now.setMinutes(now.getMinutes() + 10);
			}
			dateString = getHoursAndMinutes(now);
			if (dateString == "00:00") {
				break;
			}

			safetyBreak++;
			if (safetyBreak > 300 && gastro.isBreakfastShop === true) {
				break;
			}
		}
		return ret;
	}

	//CLEANUP: CartPage
	private selectedOfferAffectsTime(time: any, cart: any[]) {
		for (let i = 0; i < cart.length; i++) {
			if (cart[i].offer == true && cart[i].offerData != undefined) {
				if (cart[i].offerData.to < time) {
					this.offerAffectsMaxTime = true;
					return true;
				}
			}
		}
		return false;
	}

	//CLEANUP: CartPage
	private selectedOfferAffectsDates(gastro: any, cart: any[]) {
		for (let i = 0; i < cart.length; i++) {
			if (cart[i].offer == true && cart[i].offerData != undefined && cart[i].offerData.to < "24:00") {
				if (gastro.orderingInAdvanceByDays != 0) {
					this.offerAffectsMaxTime = true;
				}
				return true;
			}
		}
		return false;
	}

	//CLEANUP: CartPage
	public getColumnsForTimesDelivery(gastro: any, cart: any[], foodAmount: number) {
		let ret = [];
		if (gastro.deliveryTimes != undefined && gastro.deliveryTimes.length != 0) {
			ret = this.getColumnsForTimes(
				gastro.deliveryTimes,
				this.$deliveryDate,
				gastro.deliveryPreTime,
				gastro,
				cart,
				foodAmount,
			);
		} else {
			ret = this.getColumnsForTimesMinMax(
				gastro.minDelivery,
				gastro.maxDelivery,
				this.$deliveryDate,
				gastro.deliveryPreTime,
				gastro,
				cart,
				foodAmount,
			);
		}

		if (ret[0].options.length == 0) {
			ret[0].options.push({ text: "Heute ist leider keine Lieferung mehr verfügbar." });
			return ret;
		}

		// Sobald wie möglich auswählbar 
		// (Wenn Tag == heute und innerhalb der deliveryPretime ist eine deliveryZeit auswählbar)
		const now = new Date();
		if (this.$deliveryDate == getDateString(now) && ret[0].options.length > 0) {
			now.setMinutes(roundX(now.getMinutes(), 10));
			const nowString = getHoursAndMinutes(now);
			now.setMinutes(roundX(
				now.getMinutes() + (gastro.preTimePerFood * foodAmount) + gastro.deliveryPreTime,
				10,
			));
			const DeliveryPreTimeString = getHoursAndMinutes(now);
			if (nowString <= ret[0].options[0].text && ret[0].options[0].text <= DeliveryPreTimeString) {
				ret[0].options.unshift({ text: "Sobald wie möglich" });
			}
		}
		return ret;
	}

	scanSuccess() {

	}
}


<ion-content>
  <ion-col class="main-container">
    <!-- row for logo -->
    <ion-row>
      <ion-col *ngIf="gastroService.$gastro?.newStyle" class="logo-container">
        <img src='{{gastroService.$gastro.logoImage}}'>
      </ion-col>
    </ion-row>
  
  <!--  row for table number -->
    <ion-row class="table-number-container">
      <ion-col class="line-container-left">
        <hr>
      </ion-col>
      <ion-col class="table-number">
        <ion-label><b>Tisch {{this.tableNr}}</b></ion-label>
      </ion-col>
      <ion-col class="line-container-right">
        <hr>
      </ion-col>
    </ion-row>
  
  <!--  row for "schön dass du da bist text" -->
    <ion-row class="text-container">
      <ion-col class="text-box">
        <ion-row>
          <ion-col class="text1">
            <h1><b>Schön, dass du da bist.</b></h1>
          </ion-col>
        </ion-row> 
        <ion-row>
          <ion-col class="text2">
            <h2>Was möchtest du tun?</h2>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  
    <!--  row for payment and go-to-restaurant-page buttons-->
    <ion-row class="button-container">
      <ion-col>
        <ion-row style="justify-content: center;" *ngIf="gastroService.$gastro.isMenuAvailable !== false">
          <ion-button class="action-button" (click)="goToMenu()"> {{ (menuService.isUnclickable() === true) ? "Zur Speisekarte" : "Zur Bestellung"}} </ion-button>
        </ion-row>
        

 <!-- disbled row when table is empty -->
        <ion-row style="justify-content: center;" *ngIf="this.isTableEmpty == true || this.isTableLoading == true">
          <ion-button class="action-button" disabled (click)="goToPayAtTable()">
            <div class="container">
              <div  class="item-amount count" *ngIf=" this.isTableLoading == true">
                <ion-skeleton-text [animated]="true" style="width:24px;"></ion-skeleton-text>
              </div>
              <div *ngIf=" this.isTableLoading == false" class="item-amount count" slot="start" >
                  {{this.tableContentService.getTotalCount()}}
              </div>
              <div class="buttonText" >
                Zur Bezahlung
              </div>
              <div *ngIf=" this.isTableLoading == true">
                <ion-skeleton-text [animated]="true" style="width:24px;"></ion-skeleton-text>
              </div>
              <div *ngIf=" this.isTableLoading == false" class="price" slot="end">
                {{this.tableContentService.getTotal()|
                  currency:'EUR':'symbol-narrow':'1.2-2':'de' }}
              </div>
            </div>
          </ion-button>
        </ion-row>

<!--  row for displaying table preview and navigation to payment -->
        <ion-row style="justify-content: center;" *ngIf="this.isTableEmpty == false && this.isTableLoading == false">
          <ion-button class="action-button" (click)="goToPayAtTable()"> 
            <div class="container">
              <div class="item-amount count" slot="start" >
                  {{this.tableContentService.getTotalCount()}}
              </div>
              <div class="buttonText" >
                Zur Bezahlung 
              </div>
              <div class="price" slot="end">
                {{this.tableContentService.getTotal()|
                  currency:'EUR':'symbol-narrow':'1.2-2':'de' }}
              </div>
            </div>
          </ion-button>
        </ion-row>
      </ion-col>
    </ion-row>

  </ion-col>
</ion-content>

import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { CartService } from "./cart.service";
import { GastroService } from "./gastro.service";
import { SessionDataService } from "./session-data.service";
import { UserService } from "./user.service";
import { UtilService } from "./util.service";
import * as $ from "jquery";
@Injectable({ providedIn: "root" })
export class GeoService {

	//CLEANUP: GeoService
	public isInFence = false;
	//CLEANUP: GeoService
	isTracking;

	//CLEANUP: GeoService
	public deliveryDistanceIndex = -1;

	//CLEANUP: GeoService
	public minValueForDelivery = 0;

	//CLEANUP: GeoService
	public minValueForFreeDelivery = 0;

	//CLEANUP: CartPage
	public minValueReached = false;

	public outOfDistance = false;

	private userPosition:any;

	minOrderReached = true;

	minimalOrder = false;

	//CLEANUP: GeoService
	public plzInWhiteList = false;

	public askForTrackingPermission = true;

	constructor(
		private gastroService: GastroService,
		private userService: UserService,
		private sessionDataService: SessionDataService,
		private alertController: AlertController,
		private utilService: UtilService,
		private cartService: CartService,
	) { }

	getDeliveryFee(totalWithDiscountAndTips: number): number {
		if (this.sessionDataService.$isDelivery !== true) {
			return 0;
		}

		const gastro = this.gastroService.$gastro;

		if (gastro.multipleCenter) {
			return this.calcDeliveryFeeMultipleCenter(totalWithDiscountAndTips);
		} else if (gastro.useWhitelist === true) {
			return this.calcDeliveryFeeWhitelist(totalWithDiscountAndTips);
		} else if (
			(
				gastro.deliveryOptions.deliveryDistance == undefined
				|| gastro.deliveryOptions.deliveryDistance.length == 0
			)
			&& gastro.deliveryOptions
			&& gastro.deliveryOptions.fee > 0
			&& this.cartService.$cart.length > 0
			&& totalWithDiscountAndTips < gastro.deliveryOptions.freeDeliveryAmount
			|| (
				gastro.deliveryOptions.freeDeliveryAmount == -1
				&& (
					gastro.deliveryOptions.deliveryDistance == undefined
					|| gastro.deliveryOptions.deliveryDistance.length == 0
				)
			)
		) {
			return gastro.deliveryOptions.fee;
		} else if (
			this.deliveryDistanceIndex !== -1
			&& gastro.deliveryOptions
			&& gastro.deliveryOptions.deliveryDistance != undefined
			&& gastro.deliveryOptions.deliveryDistance.length > 0
			&& this.cartService.$cart.length > 0
			&& (totalWithDiscountAndTips < gastro.deliveryOptions.freeDeliveryAmount
				|| gastro.deliveryOptions.deliveryDistance[this.deliveryDistanceIndex].freeDeliveryAmount == -1)
		) {
			return gastro.deliveryOptions.deliveryDistance[this.deliveryDistanceIndex].fee;
			// return this.getDeliveryRadius().deliveryFee;
		} else {
			return 0;
		}
	}

	calcDeliveryFeeWhitelist(totalWithDiscountAndTips:number) {
		if (this.userService.user.address.PLZ != "") {
			this.checkWhitelistForPlz(this.userService.user.address.PLZ, totalWithDiscountAndTips);
		}
		const option = this.getDeliveryRadius();
		if (option?.freeValue === -1) {
			return option.deliveryFee;
		}

		if (totalWithDiscountAndTips < option?.freeValue) {
			return option.deliveryFee;
		}

		return 0;
	}

	calcDeliveryFeeMultipleCenter(totalWithDiscountAndTips:number) {
		if (this.userPosition === undefined) {
			return 0;
		}

		const center = this.calcMultipleCenter(this.userPosition);

		if (center !== -1) {
			if (center.freeDeliveryAmount !== -1 && totalWithDiscountAndTips >= center.freeDeliveryAmount) {
			  return 0;
			} else {
			  return center.fee;
			}
		}
	}

	getMinOrderAmount(): number {
		return this.getDeliveryRadius().minValue;
	}

	getFreeAmount(): number {
		return this.getDeliveryRadius().freeValue;
	}

	private getDeliveryRadius() {
		const userAddress = this.userService.user.address;
		const gastro = this.gastroService.$gastro;
		if (gastro.useWhitelist) {
			return this.getDeliveryRadiusByWhitelist(userAddress?.PLZ);
		}
	}

	private getDeliveryRadiusByWhitelist(plz: string): undefined | {
		deliveryFee: number;
		freeValue: number;
		minValue: number;
		plzList: string[];
	} {
		const gastro = this.gastroService.$gastro;
		for (const deliveryRadius of gastro.whitelist) {
			if (deliveryRadius.plzList.includes(plz) || deliveryRadius.plzList.includes(parseInt(plz))) {
				return deliveryRadius;
			}
		}
		return undefined;
	}

	//CLEANUP: GeoService
	trackMe() {
		/**console.log(this.gastro.lat)
	console.log(this.gastro.long)
	this.isInFence = true;
	if (navigator.geolocation) {
		this.isTracking = true;
		navigator.geolocation.watchPosition(
			position => {
				console.log( position.coords.latitude)
				console.log( position.coords.longitude)
				if (
					position.coords.latitude - this.gastro.lat > 0.0009 ||
					position.coords.latitude - this.gastro.lat < -0.0009 ||
					position.coords.longitude - this.gastro.long > 0.0009 ||
					position.coords.longitude - this.gastro.long < -0.0009
				) {
					this.isInFence = true;
				} else {
					this.isInFence = true;
				}
			},
			() => {},
			{ enableHighAccuracy: true }
		);
	} else {**/
		this.isInFence = true;
		// alert('Geolocation is not supported by this browser.');
		//}
	}

	// TODo Fix text for mindestebestellwert and check for bugs with popup.
	//CLEANUP: GeoService
	checkWhitelistForPlz(customerPlz, totalWithDiscountAndTips) {

		let isValid = false;
		this.minValueReached = false;
		this.plzInWhiteList = false;


		// const cost = this.getTotalWithoutDeliveryFee();

		if (this.gastroService.$gastro.whitelist.length > 0) {
			this.gastroService.$gastro.whitelist.forEach((element) => {
				element.plzList.forEach((plz: string) => {


					if (plz == customerPlz) {
						this.minValueForDelivery = element.minValue;
						this.minValueForFreeDelivery = element.freeValue;
						this.plzInWhiteList = true;
						if (totalWithDiscountAndTips >= element.minValue) {
							isValid = true;
							this.minValueReached = true;
						} else {
							isValid = false;
						}
					}
				});
			});
			return isValid;
		} else {
			return isValid;
		}
	}

	useRadiusForDelivery(e, totalWithDiscountAndTips) {
		let ret = true;
		//CONA CHANGES MULTIPLE CENTERS
		if (this.gastroService.$gastro.multipleCenter == true) {
			const radius = this.calcMultipleCenter(e.results[0]);
			if (radius == -1) {
				ret = false;
				this.outOfDistance = true;
				this.alertOutOfDistance();
			} else if (totalWithDiscountAndTips < radius.minOrder) {
				this.minOrderReached = false;
				this.utilService.presentToast("Mindestbestellwert nicht erreicht", "top", "#Warenkorb");
				ret = false;
			} else {
				// if (radius.freeDeliveryAmount != -1 && totalWithDiscountAndTips >= radius.freeDeliveryAmount) {
				//   this.cartService.deliveryFee = 0
				// } else {
				//   this.cartService.deliveryFee = radius.fee
				// }
			}
			return ret;
		}
		if (
			(this.gastroService.$gastro.deliveryOptions.deliveryDistance
				== undefined
				|| this.gastroService.$gastro.deliveryOptions.deliveryDistance
					.length == 0)
			&& this.calcDistance(e.results[0])
			<= this.gastroService.$gastro.deliveryOptions.maxDistance
		) {
			ret = true;
		} else if (
			this.gastroService.$gastro.deliveryOptions.deliveryDistance
			!= undefined && this.gastroService.$gastro.deliveryOptions.deliveryDistance.length
			> 0
		) {
			const fee = this.calcDeliveryCost(e.results[0], totalWithDiscountAndTips);
			if (fee == -1) {
				this.outOfDistance = true;
				if (this.minOrderReached) {
					this.alertOutOfDistance();
				}
				ret = false;
			} else if (
				this.minimalOrder == false
				&& this.gastroService.$gastro.deliveryOptions.deliveryDistance
					.length > 0
			) {
				this.utilService.presentToast("Mindestbestellwert nicht erreicht für Lieferung");
				ret = false;
			} else {
				// this.cartService.deliveryFee = fee;
				ret = true;
			}
		} else {
			this.outOfDistance = true;
			this.alertOutOfDistance();
			ret = false;
		}

		return ret;
	}


	/**
	 * takes a position and calcs if they are in multiple radius with multiple centrs
	 * returns -1 if no item is found == out of distance. returns the radius if in range
	 * @param e google maps position object
	 */
	calcMultipleCenter(e): any {
		const lat2 = e.geometry.location.lat;
		const long2 = e.geometry.location.lng;
		let ret = -1;
		this.gastroService.$gastro.deliveryOptions.deliveryDistance.forEach((radius) => {
			if (ret == -1 && radius.lat && radius.long) {
				const lat1 = radius.lat;
				const long1 = radius.long;
				const dist = this.calcDistanceHelper(lat1, long1, lat2, long2);
				if (dist <= radius.distance) {
					ret = radius;
				}
			}
		});
		return ret;
	}

	async alertOutOfDistance() {
		const alert = await this.alertController.create({
			header: "Achtung",
			message: "Adresse außerhalb des Bestellradius",
			buttons: [
				{
					text: "Ok",
					role: "ok",
					cssClass: "primary",
				},
			],
		});
		await alert.present();
	}

	async checkDistance(totalWithDiscountAndTips) {
		const street = this.utilService.replaceUmlaute(this.userService.user.address.street);
		const data = {
			plz: this.userService.user.address.PLZ,
			street: street,
			streetNr: this.userService.user.address.houseNr,
		};
		const url = `${environment.functionsUrl}checkDistance`;
		let ret = true;
		if (
			this.gastroService.$gastro.deliveryOptions.blackListPLZ != undefined
			&& this.gastroService.$gastro.deliveryOptions.blackListPLZ.length > 0
		) {
			this.gastroService.$gastro.deliveryOptions.blackListPLZ.forEach((plz) => {
				if (plz == this.userService.user.address.PLZ) {
					this.alertOutOfDistance();
					ret = false;
				}
			});
			if (!ret) {
				return false;
			}
		}

		console.log("data", data);

		await $.post(url, data, async(e) => {
			this.userPosition = e.results[0];
			this.outOfDistance = false;
			this.minOrderReached = true;
			if (e.status == "OK") {


				const formattedPLz = e.results[0].formatted_address
					.split(",", 2)[1]
					.split(" ", 2)[1];
				if (this.gastroService.$gastro.useWhitelist == true) {
					console.log("formattedplz", formattedPLz);
					ret = this.checkWhitelistForPlz(formattedPLz, totalWithDiscountAndTips);

					if (!this.plzInWhiteList) {
						this.alertAdressNotOnWhitelist();
					} else if (!this.minValueReached) {
						this.utilService.presentToast("Mindestbestellwert nicht erreicht für Lieferung");
					}

				} else {
					ret = this.useRadiusForDelivery(e, totalWithDiscountAndTips);
				}
			} else if (e.status == "ZERO_RESULTS") {
				this.alertWrongAdress();
				ret = false;
			} else {
				ret = false;
			}
		});
		return ret;
	}


	async alertAdressNotOnWhitelist() {
		this.outOfDistance = true;
		const alert = await this.alertController.create({
			header: "Achtung",
			message: "Deine Adresse liegt nicht im Liefergebiet",
			buttons: [
				{
					text: "Ok",
					role: "ok",
					cssClass: "primary",
				},
			],
		});
		await alert.present();
	}

	async alertWrongAdress() {
		this.outOfDistance = true;
		const scrollElement = document.querySelector("#whereToDelivery");
		const alert = await this.alertController.create({
			header: "Achtung",
			message: "Adresse enthält Fehler",
			buttons: [
				{
					text: "Ok",
					role: "ok",
					cssClass: "primary",
				},
			],
		});
		await alert.present();
		alert.onDidDismiss().then(()=>scrollElement.scrollIntoView({ behavior: "smooth" }));
	}

	calcDistanceHelper(lat1, lon1, lat2, lon2): number {
		const R = 6371; // km (change this constant to get miles)
		const dLat = ((lat2 - lat1) * Math.PI) / 180;
		const dLon = ((lon2 - lon1) * Math.PI) / 180;
		const a
			= Math.sin(dLat / 2) * Math.sin(dLat / 2)
			+ Math.cos((lat1 * Math.PI) / 180)
			* Math.cos((lat2 * Math.PI) / 180)
			* Math.sin(dLon / 2)
			* Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d;
	}

	calcDistance(location) {
		const long1 = this.gastroService.$gastro.long;
		const lat1 = this.gastroService.$gastro.lat;
		const lat2 = location.geometry.location.lat;
		const long2 = location.geometry.location.lng;
		return this.calcDistanceHelper(lat1, long1, lat2, long2);
	}

	calcDeliveryCost(location, totalWithDiscountAndTips) {
		const dist = this.calcDistance(location);
		let found = false;
		let deliveryCost = 0;
		if (this.checkMinDelivery(location, totalWithDiscountAndTips) == true) {
			this.gastroService.$gastro.deliveryOptions.deliveryDistance.forEach((i, index) => {
				if (dist <= i.distance && !found) {
					this.deliveryDistanceIndex = index;
					if (i.freeDeliveryAmount == -1) {
						deliveryCost = i.fee;
						found = true;
					} else if (totalWithDiscountAndTips >= i.freeDeliveryAmount) {
						deliveryCost = 0;
						found = true;
					} else {
						deliveryCost = i.fee;
						found = true;
					}
				}
			});

			this.minimalOrder = true;
			if (found == false) {
				return -1;
			}
		}

		if (found == false) {
			return -1;
		}

		return deliveryCost;
	}

	/**
		 * Checken ob bei mehreren Radien die min gebühr erreicht wurde
		 * @param location location von der google maps abfrage
		 */
	checkMinDelivery(location, totalWithDiscountAndTips) {
		let minimalOrder = false;
		const dist = this.calcDistance(location);
		this.gastroService.$gastro.deliveryOptions.deliveryDistance.forEach((i) => {
			if (dist <= i.distance && totalWithDiscountAndTips >= i.minOrder) {
				minimalOrder = true;
			} else if (
				dist <= i.distance
				&& totalWithDiscountAndTips <= i.minOrder
				&& !minimalOrder
			) {
				this.minOrderReached = false;
				this.utilService.presentToast("Mindestbestellwert nicht erreicht", "top", "#Warenkorb");
			}
		});
		return minimalOrder;
	}

}

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { GastroService } from "../services/gastro.service";
import { StorageService } from "../services/storage.service";

@Injectable({ providedIn: "root" })
export class GastroActiveGuard implements CanActivate {

	gastro;
	constructor(
		public gastroService: GastroService,
		public router: Router,
		public storageService: StorageService,
		public db: AngularFirestore,
	) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise(async(resolve) => {
			const id = await this.storageService.load("gastroId");
			if (id != undefined && id != null) {
				const doc = await this.db.collection("gastro").doc(id).get().toPromise();
				this.gastro = doc.data();
				if (!this.gastroService.isActive()) {
					this.router.navigate(["inactive"]);
					resolve(false);
				} else {
					resolve(true);
				}
			} else {
				resolve(false);
			}
		});
	}

	isActive() {
		if (this.gastro.active == undefined) {
			return true;
		} else {
			return this.gastro.active;
		}
	}


}

import { Injectable } from "@angular/core";
import { CheckoutSystem } from "../checkout-system-api.service";
import { Category, Extra, Order, PaymentMethod, Product } from "../../util.service";
import { GastroService } from "../../gastro.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { SessionDataService } from "../../session-data.service";
import { environment } from "src/environments/environment";
import * as $ from "jquery";
import { PaymentService } from "../../payment.service";
@Injectable({
	providedIn: "root",
})
export class OrderbirdService implements CheckoutSystem {
	
	public active: Promise<boolean>;
	private gastroId: string;
	hasMobilePayment: boolean;
	
	constructor(
		private afs: AngularFirestore,
		private sessionDataService: SessionDataService,
	) {
		
		this.active = new Promise((resolve, reject) => {
			const gastroIdSubscription = this.sessionDataService.$gastroId.subscribe(async id => {
				if (id !== undefined && id !== null && id !== this.gastroId) {
				  this.gastroId = id;
				  const doc = await this.afs.collection("gastro")
				  .doc(id)
				  .collection("checkoutSystemApi")
				  .doc("orderbird")
				  .get()
				  .toPromise();
				  if (doc.exists === false) {
					  resolve(false);
				  } else {
						const active = doc.data().active;
						resolve(active);
						//TODO: What happens when the gastroId changes a second time
						gastroIdSubscription.unsubscribe();
				  }
			  }
		  });
	  });
	}

	apiName(): string {
		return "orderbird";
	}
	isActiveAndEnabled(): Promise<boolean> {
		return this.active;
	}

	// Deprecated
	getCategories(): Category[] {
		throw new Error("Method not implemented.");
	}

	// Deprecated
	getProducts(): Product[] {
		throw new Error("Method not implemented.");
	}

	// Deprecated
	getExtra(extraId: string): Extra {
		throw new Error("Method not implemented.");
	}

	// Deprecated
	getPaymentMethods(): PaymentMethod[] {
		throw new Error("Method not implemented.");
	}

	async sendOrder(splitCart: Order): Promise<[boolean, any]> {
		// TODO: Fill data appropriately
		const data = splitCart;
		
		// TODO: Adjust url
		const url = `${environment.functionsUrlEU}orderbirdSendOrder`;

		const resp = await $.ajax({
			contentType: "application/json",
			data: JSON.stringify(data),
			type: "POST",
			url: url,
		});
		
	  
		if (resp.status === undefined || resp.status === 200) {
			return [true, resp];
		}
		else {
			return [false, undefined];
		}
	}
	
	getMobilePaymentSplitObject(): PaymentMethod {
		throw new Error("Method not implemented.");
	}
	sendPayment(amount: number, partyId?: number, tip?: number) {
		console.log("does nothing");
	}
}

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Storage } from "@ionic/storage";
import { Router } from "@angular/router";
import { SessionDataService } from "./session-data.service";
import { Gastro } from "src/shared/split-submodules/types/types";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { ModalController, NavController } from "@ionic/angular";
import { StorageService } from "./storage.service";
import { getDateString, getHoursAndMinutes } from "src/shared/split-submodules/functions/functions";
import { GastroResettable, ResetService } from "./reset.service";
import { UtilService } from "./util.service";
import { LocalizationService } from "./localization.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CheckoutSystemApiService } from "./api/checkout-system-api.service";
import { OrderbirdService } from "./api/orderbird/orderbird.service";
import { ModalObImgTextComponent } from "../components/modal-ob-img-text/modal-ob-img-text.component";
import * as $ from "jquery";
declare let ApplePaySession;
const resetGastro: Gastro = {
	active: true,
	address: {
		city: "",
		country: "",
		housenumber: "",
		PLZ: "",
		streetname: "",
	},
	allergicLink: "",
	askCorona: false,
	bar: false,
	country: "DE",
	credit: false,
	creditcard: false,
	deliverSlots: [],
	deliveryInactive: false,
	deliveryOptions: {
		blackListPLZ: [],
		deliveryDistance: [],
		fee: 0,
		freeDeliveryAmount: 0,
		maxDistance: 0,
		minOrderAmount: 0,
	},
	deliveryPreTime: 0,
	deliveryPreTimeStatic: 0,
	deliveryTimes: [],
	discount: [],
	dontShowBillDownload: false,
	fee: 0,
	feeName: "",
	geoFence: false,
	googlePlaceId: "",
	hasArticleNr: false,
	hasCard: true,
	hasCustomerCoupon: false,
	hasCustomerLoyality: false,
	hasDelivery: false,
	hasDibsPayment: false,
	hasFee: false,
	hasLanguageSystem: false,
	hasNoExtraWishes: false,
	hasNoOfferFlag: false,
	hasOnlyPDF: false,
	hasPayAtTable: false,
	hasPickup: false,
	hasPreTimePerFood: false,
	hasTipping: false,
	hasToGo: false,
	hasVytal: false,
	hideProfile: false,
	hideSplitLogo: false,
	hotel: false,
	id: "",
	inhouseInactive: false,
	inhouseUnclickable: false,
	invoiceCounter: 0,
	invoiceData: {},
	invoicePre: "",
	isBreakfastShop: false,
	languageCode: "de",
	lat: 0,
	logoImage: "",
	long: 0,
	loyaltyCard: {
		active: false,
	},
	mail: "",
	maxDelivery: "",
	maxPickup: "",
	menuImage: "",
	menuOnly: false,
	menuOptions: {
		menuOnlyActivated: false,
		orderMenuActivated: true,
	},
	minDelivery: "",
	minPickup: "",
	mobileNumberRequired: false,
	mobilePaySecure: false,
	multipleCenter: false,
	name: "",
	newStyle: false,
	nicknameDB: "",
	nicknameRequired: false,
	openingHours: [],
	orderingInAdvanceByDays: 0,
	outerhouseUnclickable: false,
	overtimeLimit: "00:00",
	ownNicknames: false,
	paymentOptions: [],
	paypal: false,
	paypalFee: {
		fixed: 0,
		percentual: 0,
	},
	paypalID: "",
	pdfURL: "",
	pickup: false,
	pickupInactive: false,
	pickupPreTime: 0,
	pickupSlots: [],
	pickupTimes: [],
	plz: "",
	popDishesInhouseShown: false,
	popDishesOuterhouseShown: false,
	preTimePerFood: 0,
	self: false,
	selfOrderingInactive: false,
	selfService: false,
	showCustomerEmail: false,
	sms: false,
	specialTimes: [],
	splitFee: {
		fixed: 0,
		percentual: 0.0,
	},
	steuerNr: "",
	street: "",
	stripeFee: {
		fixed: 0,
		percentual: 0,
	},
	timezone: "Europe/Berlin",
	useWhitelist: false,
	waiterCoronaMode: false,
	websiteData: {},
	whereOptions: [],
	whitelist: [],
};

@Injectable({
	providedIn: "root",
})
export class GastroService implements GastroResettable {

	onGastroLogout(): void {
		this.gastroId = undefined;
		this.gastro = resetGastro;
		if (this.gastroSubscription !== undefined) {
			this.gastroSubscription.unsubscribe();
			this.gastroSubscription = undefined;
		}
	}

	registerGastroReset(): void {
		this.resetService.registerGastroReset(this);
	}

	private gastroId: string;

	private gastroSubscription: Subscription;
	//CLEANUP: ???
	gastroLoaded = false;
	dibsPublicKey = "";
	isTogoOptionAvailable = false;

	public gastro: Gastro = {
		active: true,
		address: {
			city: "",
			country: "",
			housenumber: "",
			PLZ: "",
			streetname: "",
		},
		allergicLink: "",
		askCorona: false,
		bar: false,
		country: "DE",
		credit: false,
		creditcard: false,
		deliverSlots: [],
		deliveryInactive: false,
		deliveryOptions: {
			blackListPLZ: [],
			deliveryDistance: [],
			fee: 0,
			freeDeliveryAmount: 0,
			maxDistance: 0,
			minOrderAmount: 0,
		},
		deliveryPreTime: 0,
		deliveryPreTimeStatic: 0,
		deliveryTimes: [],
		discount: [],
		dontShowBillDownload: false,
		fee: 0,
		feeName: "",
		geoFence: false,
		googlePlaceId: "",
		hasArticleNr: false,
		hasCard: true,
		hasCustomerCoupon: false,
		hasCustomerLoyality: false,
		hasDelivery: false,
		hasDibsPayment: false,
		hasFee: false,
		hasLanguageSystem: false,
		hasNoExtraWishes: false,
		hasNoOfferFlag: false,
		hasOnlyPDF: false,
		hasPayAtTable: false,
		hasPickup: false,
		hasPreTimePerFood: false,
		hasTipping: false,
		hasToGo: false,
		hasVytal: false,
		hideProfile: false,
		hideSplitLogo: false,
		hotel: false,
		id: "",
		inhouseInactive: false,
		inhouseUnclickable: false,
		invoiceCounter: 0,
		invoiceData: {},
		invoicePre: "",
		isBreakfastShop: false,
		languageCode: "de",
		lat: 0,
		logoImage: "",
		long: 0,
		loyaltyCard: {
			active: false,
		},
		mail: "",
		maxDelivery: "",
		maxPickup: "",
		menuImage: "",
		menuOnly: false,
		menuOptions: {
			menuOnlyActivated: false,
			orderMenuActivated: true,
		},
		minDelivery: "",
		minPickup: "",
		mobileNumberRequired: false,
		mobilePaySecure: false,
		multipleCenter: false,
		name: "",
		newStyle: false,
		nicknameDB: "",
		nicknameRequired: false,
		openingHours: [],
		orderingInAdvanceByDays: 0,
		outerhouseUnclickable: false,
		overtimeLimit: "00:00",
		ownNicknames: false,
		paymentOptions: [],
		paypal: false,
		paypalFee: {
			fixed: 0,
			percentual: 0,
		},
		paypalID: "",
		pdfURL: "",
		pickup: false,
		pickupInactive: false,
		pickupPreTime: 0,
		pickupSlots: [],
		pickupTimes: [],
		plz: "",
		popDishesInhouseShown: false,
		popDishesOuterhouseShown: false,
		preTimePerFood: 0,
		self: false,
		selfOrderingInactive: false,
		selfService: false,
		showCustomerEmail: false,
		sms: false,
		specialTimes: [],
		splitFee: {
			fixed: 0,
			percentual: 0.0,
		},
		steuerNr: "",
		street: "",
		stripeFee: {
			fixed: 0,
			percentual: 0,
		},
		timezone: "Europe/Berlin",
		useWhitelist: false,
		waiterCoronaMode: false,
		websiteData: {},
		whereOptions: [],
		whitelist: [],

	};

	public get $gastro(): Gastro {
		return this.gastro;
	}

	constructor(
		public db: AngularFirestore,
		public storage: Storage,
		public router: Router,
		private sessionDataService: SessionDataService,
		private navCntrl: NavController,
		private storageService: StorageService,
		private resetService: ResetService,
		private utilService: UtilService,
		private localizationService: LocalizationService,
		private modalController: ModalController,
		private orderbirdService: OrderbirdService,
	) {
		this.registerGastroReset();
		this.storageService.load("gastroId").then(async(id: string) => {
			console.log("ID", id);
			if (id != undefined && id != null) {
				await this.setGastroIdAndLoadGastro(id);
			}
		});
	}

	public get $gastroId(): string {
		return this.gastroId;
	}

	public isInShift: boolean;
	public hasRecentHeartbeat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
	private mostRecentHeartbeat: Date;
	private heartBeatSubscription: Subscription;
	private computeHeartbeatInterval: any;

	/**
	 * Computes if the QR ordering is available based on the heartbeat and the out of shift status
	 * 
	 * It will start a subscription on the devices collection of the gastro for the heartbeat and will periodically compare the current time with the last heartbeat
	 * The out of shift status is checked once
	 */
	private async computeIfQROrderingAvailable() {
		// heartbeat
		//TODO uncomment when the waiter app is updated to > v.3.1
		// this.unsubscribeHeartbeat();
		// this.heartBeatSubscription = this.db.collection("gastro").doc(this.$gastroId).collection("devices").valueChanges().subscribe(docs => {
		// 	const mostRecentHeartbeat = Math.max(...docs.map((doc: any) => {
		// 		return new Date(doc.timestamp).getTime();
		// 	}), 0);
		// 	this.mostRecentHeartbeat = new Date(mostRecentHeartbeat);

		// 	if (this.computeHeartbeatInterval !== undefined) {
		// 		clearInterval(this.computeHeartbeatInterval);
		// 	}

		// 	const intervalFunction = () => {
		// 		const currentTime = new Date();
		// 		this.hasRecentHeartbeat
		// 			.next((currentTime.getTime()
		// 			- this.mostRecentHeartbeat.getTime()) / (1000 * 60) < 60);
		// 	};
		// 	intervalFunction();
		// 	this.computeHeartbeatInterval = setInterval(intervalFunction, 1000 * 10);
		// });

		//only check if the gastro is in shift if the gastro has the orderbird system enabled
		if (await this.orderbirdService.active === true) {
			// Out of shift
			const url = `${environment.functionsUrlEU}orderbirdIsInShift`;
	
			$.post(url, { gastroId: this.gastroId }).done(async(data) => {
				this.isInShift = data;
			}).catch((err) => {
				this.isInShift = false;
			});
		} else {
			this.isInShift = true;
		}
	}

	/**
	 * Unsubscribes from the heartbeat and clears the remaining interval
	 */
	private unsubscribeHeartbeat() {
		if (this.heartBeatSubscription !== undefined) {
			this.heartBeatSubscription.unsubscribe();
		}
		if (this.computeHeartbeatInterval !== undefined) {
			clearInterval(this.computeHeartbeatInterval);
		}
	}

	public async setGastroIdAndLoadGastro(gastroId: string) {
		return new Promise((resolve) => {
			this.gastroLoaded = false;

			this.gastroId = gastroId;
			this.storageService.store("gastroId", gastroId);
			this.gastroSubscription = this.db.collection("gastro").doc(this.$gastroId).snapshotChanges()
				.subscribe((doc) => {
					if (doc.payload.exists) {
						this.gastro = <any>doc.payload.data();
						// if (!this.hasOpen()) {
						//   this.router.navigate(['closed'])
						// }
						// if (!this.isActive() && this.sessionDataService.$inhouseLink != undefined && this.sessionDataService.$inhouseLink == false) {
						//   this.router.navigate(['inactive'])
						// }
						if (this.gastro.nicknameDB !== "" && this.gastro.nicknameDB !== undefined
							&& this.gastroLoaded !== true) {
							this.utilService.fetchNicknames(this.$gastro.nicknameDB);
						}

						this.computeIfQROrderingAvailable();
						this.loadDibsPublicKey();
						//----position of this line is important. Dont MOVE!!!!---
						this.gastroLoaded = true;
						//----position of this line is important. Dont MOVE!!!!---

						if (this.gastro.hasLanguageSystem === true) {
							this.localizationService.init(this.gastro);
						}

						//TODO this could lead to wrong pickup times as it submits an empty cart
						this.calculateIsTogoOptionAvailable(
							{},
							0,
						);
						console.log("gastro loaded");
						resolve(true);
					} else {
						console.log("offline no gastro fetched");
					}
				});
		});
	}

	getDeliveryHoursForToday() {
		const now = new Date();
		return this.getDeliveryHoursForDay(now);
	}

	async getGastroFromID(id) {
		const gastro = await this.db.collection("gastro").doc(id).get().toPromise();
		return gastro.data();
	}

	public getPickupHoursForToday() {
		const now = new Date();
		return this.getPickupHoursForDay(now);
	}

	getDeliveryHoursForDay(now) {
		const day = now.getDay();
		const ret = [];
		if (this.gastro.deliveryTimes && this.gastro.deliveryTimes.length > 0) {
			this.gastro.deliveryTimes.forEach((open) => {
				if (open.day == day) {
					ret.push(open);
				}
			});
		}

		return ret;
	}

	getReducedPickupTimes() {
		const ret = [];
		for (let i = 1; i < 8; i++) {
			const entriesOfDay = this.gastro.pickupTimes.filter(day => day.day == i % 7);
			ret.push({ day: this.dayOfWeekAsString(i % 7), times: [] });
			entriesOfDay.forEach((day) => {
				const fromStrArr = day.from.split(":");
				const tmpDate = new Date();
				tmpDate.setHours(parseInt(fromStrArr[0]));
				tmpDate.setMinutes(parseInt(fromStrArr[1]));
				tmpDate.setMinutes(tmpDate.getMinutes() - this.gastro.pickupPreTime);
				ret[ret.length - 1].times.push({
					from: getHoursAndMinutes(tmpDate),
					to: day.to,
				});
			});
			ret[ret.length - 1].times.sort(function(a: any, b: any) {
				if (a.from < b.from) { return -1; }
				else { return 1; }
			});

		}
		return ret;
	}

	getPickupHoursForDay(now) {
		const day = now.getDay();
		const ret = [];
		if (this.gastro.pickupTimes && this.gastro.pickupTimes.length > 0) {
			this.gastro.pickupTimes.forEach((open) => {
				if (open.day == day) {
					ret.push(open);
				}
			});
		}
		return ret;
	}

	hasDeliveryOpen() {
		const now = new Date();
		const minutes = now.getMinutes();
		const hours = now.getHours();
		const opening = this.getDeliveryHoursForToday();

		let ret = false;
		if (this.gastro.deliveryTimes == undefined) {
			ret = true;
		} else if (this.gastro.deliveryTimes && this.gastro.openingHours.length == 0) {
			ret = true;
		}
		if (opening.length > 0) {
			opening.forEach((open) => {
				const fromDate = this.hoursToNowDate(open.from);
				const toDate = this.hoursToNowDate(open.to);
				if (now > fromDate && now < toDate) {
					ret = true;
				}
			});
		}
		return ret;
	}

	public getNextDeliveryTime() {
		const now = new Date();
		const opening = this.getDeliveryHoursForToday();
		let lowestFutureTime = this.hoursToNowDate("24:00");
		let valid = false;

		if (opening == undefined) { return { valid: valid, time: lowestFutureTime }; }
		if (opening.length == 0) {
			return { valid: valid, time: lowestFutureTime };
		}
		for (let i = 0; i < opening.length; i++) {
			const fromDate = this.hoursToNowDate(opening[i].from);
			const toDate = this.hoursToNowDate(opening[i].to);

			if (fromDate < now) {
				continue;
			} else if (lowestFutureTime < fromDate) {
				continue;
			} else {
				valid = true;
				lowestFutureTime = fromDate;
			}
		}
		return { valid: valid, time: lowestFutureTime };

	}

	public getNextOpeningTime() {
		const now = new Date();
		const opening = this.getOpeningHoursForToday();
		let lowestFutureTime = this.hoursToNowDate("24:00");
		let valid = false;

		if (opening == undefined) { return { valid: valid, time: lowestFutureTime }; }
		if (opening.length == 0) {
			return { valid: valid, time: lowestFutureTime };
		}
		for (let i = 0; i < opening.length; i++) {
			const fromDate = this.hoursToNowDate(opening[i].from);
			const toDate = this.hoursToNowDate(opening[i].to);

			if (fromDate < now) {
				continue;
			} else if (lowestFutureTime < fromDate) {
				continue;
			} else {
				valid = true;
				lowestFutureTime = fromDate;
			}
		}
		return { valid: valid, time: lowestFutureTime };

	}

	public getNextPickupTime() {
		const now = new Date();
		const opening = this.getPickupHoursForToday();
		let lowestFutureTime = this.hoursToNowDate("24:00");
		let valid = false;

		if (opening == undefined) { return { valid: valid, time: lowestFutureTime }; }
		if (opening.length == 0) {
			return { valid: valid, time: lowestFutureTime };
		}
		for (let i = 0; i < opening.length; i++) {
			const fromDate = this.hoursToNowDate(opening[i].from);
			const toDate = this.hoursToNowDate(opening[i].to);

			if (fromDate < now) {
				continue;
			} else if (lowestFutureTime < fromDate) {
				continue;
			} else {
				valid = true;
				lowestFutureTime = fromDate;
			}
		}
		return { valid: valid, time: lowestFutureTime };

	}

	public checkAvailability(array: any) {
		const now = new Date();
		if (array == undefined) {
			return true;
		}
		const todaysTimes = array.filter((day) => {
			return day.day == now.getDay();
		});
		if (todaysTimes.length == 0) {
			return false;
		}

		let ret = false;

		todaysTimes.forEach((time) => {
			const fromDate = this.hoursToNowDate(time.from);
			const toDate = this.hoursToNowDate(time.to);

			if (fromDate < now && now < toDate) {
				ret = true;
			}
			if (fromDate > now) {
				ret = true;
			}
		});

		return ret;
	}
	//CLEANUP: ???
	async clearValues() {
		this.resetService.onGastroLogout();
		this.sessionDataService.$comesFromPlatform = false;
		const tempDeviceID = await this.storage.get("deviceId");
		const tempPopoverClosed = await this.storage.get("popoverClosed");
		const tempIsLoggedIn = await this.storage.get("isLoggedIn");
		await this.storage.remove("comesFromPlatform");
		await this.storage.remove("gastroId");
		await this.storage.remove("orderedItemsID");
		await this.sessionDataService.setIsGastroLoggedOutTrueAsync();
		//     if(this.storage.get('saveCoronaInfo')){
		//             tempCoronaName = this.storage.get('coronaName').toString();
		//             tempCoronaPhone = this.storage.get('coronaPhone').toString();
		//             tempCoronaAdress = this.storage.get('coronaAdress').toString();
		// }

		// local save of corona infos
		const saveCoronaInfosLocal = await this.storage.get("saveCoronaInfo");
		if (saveCoronaInfosLocal === true) {
			const tempCoronaName = await this.storage.get("coronaName");
			const tempCoronaPhone = await this.storage.get("coronaPhone");
			const tempCoronaAdress = await this.storage.get("coronaAdress");
			const tempCoronaSave = await this.storage.get("saveCoronaInfo");

			await this.storage.clear();
			await this.storage.set("saveCoronaInfo", tempCoronaSave);
			await this.storage.set("coronaName", tempCoronaName);
			await this.storage.set("coronaPhone", tempCoronaPhone);
			await this.storage.set("coronaAdress", tempCoronaAdress);
			await this.storage.set("popoverClosed", tempPopoverClosed);
			await this.storage.set("isLoggedIn", tempIsLoggedIn);
		} else {
			await this.storage.clear();
			await this.storage.set("popoverClosed", tempPopoverClosed);
			await this.storage.set("isLoggedIn", tempIsLoggedIn);
		}
		await this.storage.set("deviceId", tempDeviceID);
	}

	//CLEANUP: GastroService
	gastroQuit() {
		this.clearValues();
		this.navCntrl.navigateRoot("home");
	}


	getOpeningHoursForToday() {
		const now = new Date();
		return this.getOpeningHoursForDay(now);
	}

	openingHourToString(item) {
		const day = this.dayOfWeekAsString(item.day);
		return { day: day, from: item.from, to: item.to };
	}

	isActive() {
		if (this.gastro.active == undefined) { return true; }
		else { return this.gastro.active; }
	}

	dayOfWeekAsString(dayIndex) {
		return [
			"Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag",
		][dayIndex];
	}

	getOpeningHoursForDay(now) {
		const day = now.getDay();
		const ret = [];
		if (this.gastro.openingHours && this.gastro.openingHours.length > 0) {
			this.gastro.openingHours.forEach((open) => {
				if (open.day == day) {
					ret.push(open);
				}
			});
		}
		return ret;
	}

	seperateHoursAndMinutes(time) {
		return time.split(":");
	}

	hoursToNowDate(time) {
		const now = new Date();
		const hours = parseFloat(this.seperateHoursAndMinutes(time)[0]);
		const minutes = parseFloat(this.seperateHoursAndMinutes(time)[1]);

		now.setHours(hours, minutes);
		return now;
	}

	hasOpen() {
		const now = new Date();
		const minutes = now.getMinutes();
		const hours = now.getHours();
		const opening = this.getOpeningHoursForToday();

		let ret = false;
		if (this.checkForSpecialTimes()) {
			if (this.gastro.openingHours == undefined) {
				ret = true;
			} else if (this.gastro.openingHours && this.gastro.openingHours.length === 0
				&& this.gastro.openingHours.length === 0) {
				ret = true;
			}
			if (opening.length > 0) {
				opening.forEach((open) => {
					const fromDate = this.hoursToNowDate(open.from);
					const toDate = this.hoursToNowDate(open.to);
					if (now >= fromDate && now <= toDate) {
						ret = true;
					}
				});
			}
		} else { ret = false; }

		return ret;
	}


	/**
	 * 
	 * @returns true if open and false if closed
	 */
	checkForSpecialTimes() {
		const now = new Date();
		let ret = true;
		if (this.gastro.specialTimes != undefined && this.gastro.specialTimes.length > 0) {
			for (let i = 0; i < this.gastro.specialTimes.length; i++) {
				if (this.gastro.specialTimes[i].date.length > 5) {
					if (
						this.gastro.specialTimes[i].date == this.buildCompareDate("once")
					) {
						if (this.gastro.specialTimes[i].closed) {
							ret = false;
						} else {
							const fromDate = this.hoursToNowDate(this.gastro.specialTimes[i].from);
							const toDate = this.hoursToNowDate(this.gastro.specialTimes[i].to);
							if (now >= fromDate && now <= toDate) {
								ret = true;
							} else {
								ret = false;
							}
						}
					}
				}
				else if (
					this.gastro.specialTimes[i].date == this.buildCompareDate("yearly")
				) {
					if (this.gastro.specialTimes[i].closed) {
						ret = false;
					} else {
						const fromDate = this.hoursToNowDate(this.gastro.specialTimes[i].from);
						const toDate = this.hoursToNowDate(this.gastro.specialTimes[i].to);
						if (now > fromDate && now < toDate) {
							ret = true;
						} else {
							ret = false;
						}
					}
				}
			}
		}
		return ret;
	}

	buildCompareDate(type: string) {
		const now = new Date();
		const year = now.getFullYear();
		let month = (now.getMonth() + 1).toString();
		let day = now.getDate().toString();
		if (month.length == 1) {
			month = `0${month}`;
		}
		if (day.length == 1) {
			day = `0${day}`;
		}
		if (type === "once") {
			return `${year}-${month}-${day}`;
		}
		if (type === "yearly") {
			return `${month}-${day}`;
		}
	}

	getGastroSpecialTimes() {
		if (this.gastro.specialTimes === undefined) {
			return [];
		} else {
			return this.gastro.specialTimes;
		}
	}

	findPlatformItem(name) {
		this.gastro.paymentOptions.forEach((e) => {
			if (e.option === "platform") {
				e.name = name;

			}
		});
	}

	changePlatformToRightName() {
		const userAgent = window.navigator.userAgent.toLowerCase(),
			safari = /safari/.test(userAgent),
			ios = /iphone|ipod|ipad/.test(userAgent),
			chrome = /chrome/.test(userAgent);
		if (ios || safari && !chrome) {
			//this.hideApplePay()
			this.findPlatformItem("ApplePay");
		} else {

			this.findPlatformItem("Google Pay");
		}
	}

	hideApplePay() {
		if ((<any>window).ApplePaySession) {
			const merchantIdentifier = "web.split-app.de";
			const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
			promise.then((canMakePayments) => {

				if (!canMakePayments) {
					this.gastro.paymentOptions = this.gastro.paymentOptions.filter((obj) => {
						return obj.option !== "platform";
					});
				}
			});
		}
	}


	/**
   * checks whether or not this gastro has the loyaltySystem enabled
   * @returns 
   */
	getLoyaltyEnabled() {
		if (this.gastro.loyaltyCard == undefined || this.gastro.loyaltyCard.active == false) {
			return false;
		} else {
			return true;
		}
	}

	/**
   * returns the loyaltyCard Config of a gastro
   * @returns 
   */
	getLoyaltyCardConfig() {

		return this.gastro.loyaltyCard;
	}


	initDropDowns() {
		if (this.gastro.whereOptions && this.gastro.whereOptions.length == 0) {

			this.gastro.whereOptions.push({
				delivery: false,
				name: "Hier Essen",
				option: "inhouse",
				pickup: false,
				selected: false,
				togo: false,
			});
			this.gastro.whereOptions.push({
				delivery: false,
				name: "To-Go",
				option: "pickup",
				pickup: true,
				selected: false,
				togo: true,
			});
		}

		this.changePlatformToRightName();
	}


	/**
	  * @returns Returns true if delivery or pickup is enabled and has an available option
	  */
	//CLEANUP: GastroService?
	calculateIsTogoOptionAvailable(cart, foodAmount): boolean {
		// console.log("is togo available", this.isDeliveryAvailable(cart, foodAmount) || this.isPickupAvailable(cart, foodAmount))
		// console.log(this.isPickupAvailable(cart, foodAmount))
		this.isTogoOptionAvailable = this.isDeliveryAvailable(cart, foodAmount)
			|| this.isPickupAvailable(cart, foodAmount);
		return this.isTogoOptionAvailable;
	}

	/**
   * @returns true if deliveryInactive flag is false and there are still pickable deliveryTimes
   */
	//CLEANUP: GastroService?
	isDeliveryAvailable(cart, foodAmount): boolean {
		if (this.gastro.deliveryInactive === true) { return false; }
		if (this.gastro.whereOptions === undefined) { return false; }
		if (this.gastro.whereOptions.findIndex(elem => elem.togo === true && elem.delivery === true) !== -1) {
			const dates = this.sessionDataService.getColumnsForDatesDelivery(this.$gastro, cart, foodAmount)[0].options;
			switch (dates.length) {
			case 0: return false;
			case 1: {
				if (this.sessionDataService.$deliveryTime == "Heute ist leider keine Lieferung mehr verfügbar."
						|| this.sessionDataService.getColumnsForTimesDelivery(this.$gastro, cart, foodAmount)[0]
							.options[0].text === "Heute ist leider keine Lieferung mehr verfügbar.") { return false; }
				else { return true; }
			}
			default: return true;
			}
		}
		return false;
	}

	/**
   * @returns true if pickupInactive flag is false and there are still pickable pickupTimes
   */
	//CLEANUP: GastroService?
	isPickupAvailable(cart, foodAmount): boolean {
		// console.log("foodamount", foodAmount)
		if (this.gastro.pickupInactive === true) { return false; }
		if (this.gastro.whereOptions === undefined) { return false; }
		if (this.gastro.whereOptions.findIndex(elem => elem.togo === true && elem.pickup === true) !== -1) {
			const dates = this.sessionDataService.getColumnsForDatesPickup(this.$gastro, cart, foodAmount)[0].options;
			switch (dates.length) {
			case 0: return false;
			case 1: {
				if (this.sessionDataService.$pickUpTime == "Heute ist leider kein Pickup mehr verfügbar."
						|| this.sessionDataService.getColumnsForTimesPickup(this.$gastro, cart, foodAmount)[0]
							.options[0].text === "Heute ist leider kein Pickup mehr verfügbar.") { return false; }
				else { return true; }
			}
			default: return true;
			}
		}
		return false;
	}

	saveCoronaInfos(coronaInformation) {
		const now = new Date();
		this.db.collection("gastro").doc(this.$gastroId).collection("coronaList").doc(getDateString(now))
			.get().subscribe((doc) => {
				if (doc.exists) {
					const customerArray = doc.data().customers;
					customerArray.push(coronaInformation);
					this.db.collection("gastro").doc(this.$gastroId).collection("coronaList")
						.doc(getDateString(now)).set({ customers: customerArray }, { merge: true });
				} else {
					this.db.collection("gastro").doc(this.$gastroId).collection("coronaList")
						.doc(getDateString(now)).set({ customers: [coronaInformation] });
				}
			});
	}

	saveMarketingInformation(marketingInformation) {
		this.db
			.collection("gastro")
			.doc(this.$gastroId)
			.collection("userListMarketing", (ref) => ref.where("email", "==", marketingInformation.email))
			.get()
			.subscribe((doc) => {
				if (doc.empty) {
					this.db.collection("gastro").doc(this.$gastroId)
						.collection("userListMarketing").add(marketingInformation);
				}
			});
	}

	getGastroFee(): number {
		const gastro = this.$gastro;
		return gastro.hasFee ? gastro.fee : 0;
	}
	checkDiscountLocation() {
		if (this.sessionDataService.$isDelivery == true) {
			return "delivery";
		}
		if (this.sessionDataService.$isPickUp == true) {
			return "pickup";
		}
		if (this.sessionDataService.$inhouseLink == true) {
			return "inhouse";
		}
	}

	private async loadDibsPublicKey() {
		if (this.gastro.hasDibsPayment === true) {
			const publicKeyDoc = await this.db.collection("gastro").doc(this.$gastroId)
				.collection("readOnlyData").doc("dibs").get().toPromise();
			this.dibsPublicKey = publicKeyDoc.data().dibsPublicKey;
		}
	}

	ngOnDestroy() {
		this.unsubscribeHeartbeat();
	}
}
export interface WhereOption {
	/**
	 * Whether or not this option is delivery.
	 * When this flag is active the togo flag has to be active aswell.
	 */
	delivery: boolean;

	/**
	 * The name which will be displayed when showing this option.
	 */
	name: string;

	/**
	 * A unique identifier for this option.
	 */
	option: "delivery" | "pickup" | "inhouse";

	/**
	 * Whether or not this option is pickup.
	 * When this flag is active the togo flag has to be active aswell.
	 */
	pickup: boolean;

	/**
	 * Whether or not this option is a togo option.
	 */
	togo: boolean;

	/**
	 * Whether it is selected in frontend or not
	 */
	selected: boolean;
}
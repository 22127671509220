import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
@Injectable({ providedIn: "root" })
export class StorageService {

	private keys: string[] = [];

	constructor(private storage: Storage) {
		this.initStorage();

	}
	public async initStorage() {
		const loadedKeys = await this.load("_storage_keys");
		console.log("KEYS", loadedKeys);
		if (loadedKeys != undefined && loadedKeys != null) {
			this.keys = loadedKeys;
		} else {
			this.storage.set("_storage_keys", this.keys);
		}
	}

	/**
	 * Store a given value in the storage under the given key
	 * @param key The key under which the value should be stored
	 * @param value The value to be stored
	 */
	public async store(key: string, value: any) {
		if (key === "_storage_keys") {
			throw new Error("Invalid value for key!");
		}
		if (!this.keys.includes(key)) {
			this.keys.push(key);
			await this.storage.set("_storage_keys", this.keys);
		}
		await this.storage.set(key, value);
	}

	/**
	 * Returns the value which is stored at key
	 * @param key the key of which to get the value
	 * @returns A promise to the value which is stored at key. !May be undefined!
	 */
	public async load(key: string): Promise<any | undefined> {
		return await this.storage.get(key);
	}

	/**
	 * Clears a value from the localStorage
	 * @param key the key to be cleared
	 */
	async clear(key: string) {
		const index = this.keys.indexOf(key);
		if (index !== -1) {
			this.keys.splice(index, 1);
			this.storage.remove(key);
			await this.storage.set("_storage_keys", this.keys);
		} else {
			// commented the error throwing out for fixing purposes, 
			// there are still to many 'leaks' around, to properly use error throwing
			// throw new Error('Can\'t delete value. Key not found!');
		}
	}

	/**
	 * Clears all values from storage
	 */
	async clearAll() {
		console.log("clear all");
		const saveCoronaInfosLocal = await this.load("saveCoronaInfo");

		const dataToKeep = [
			{ name: "deviceId", value: "" },
			{ name: "popoverClosed", value: "" },
			{ name: "saveCoronaInfo", value: "" },
			{ name: "isLoggedIn", value: "" },
			{ name: "popoverTriggered", value: "" },
		];

		if (saveCoronaInfosLocal === true) {
			dataToKeep.push({ name: "coronaName", value: "" });
			dataToKeep.push({ name: "coronaPhone", value: "" });
			dataToKeep.push({ name: "coronaAdress", value: "" });
		}

		console.log("datatokeep", dataToKeep);
		console.log("keys", this.keys);

		//store data temp
		for (const data of dataToKeep) {
			const e = await this.storage.get(data.name);
			data.value = e;
		}

		await this.storage.clear();

		for (const data of dataToKeep) {
			this.storage.set(data.name, data.value);
		}


	}
}

import { CartService } from "../services/cart.service";
import { MenuService } from "../services/menu.service";
import { Component, OnInit, NgModule, Input } from "@angular/core";
import { IonicModule, ModalController, Platform, ToastController } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { NavController } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { SessionDataService } from "../services/session-data.service";
import { GastroService } from "../services/gastro.service";
import { UtilService } from "../services/util.service";
import { LocalizationService } from "../services/localization.service";
import { ExtraCategory, Product } from "src/shared/split-submodules/types/types";
import { CurrencyPipeOptions } from "../pipes/price.pipe";
import { TemplateStyleService } from "../services/template-style.service";
interface Detail {
	img: string;
	name: string;
	info: any;
	note: string;
	sorted: boolean;
	kind: number;
	price: number;
	extras: Array<any>;
	dropdown: Array<any>;
	checkboxen: Array<any>;
	to: string;
	id: string;
}

@Component({
	selector: "app-dish-detail",
	templateUrl: "./dish-detail.page.html",
	styleUrls: ["./dish-detail.page.scss"],
})
export class DishDetailPage implements OnInit {

	@Input() dish: any = {};
	@Input() extra: any = { extraCategories: [] };
	public note = "";
	public showMore = false;
	public hasOverLength = false;

	public errorInCategoryIndex = -1;

	constructor(
		public cartService: CartService,
		public gastroService: GastroService,
		private localizationService: LocalizationService,
		public menuService: MenuService,
		private modalCtrl: ModalController,
		public navCtrl: NavController,
		public platform: Platform,
		public router: Router,
		public sessionDataService: SessionDataService,
        private templateStyleService: TemplateStyleService,
		private toastr: ToastController,
		private utilService: UtilService,
	) { }

	ngOnInit() { }

	ionViewWillEnter() {
		window.addEventListener("popstate", e => {
			this.closeModal();
		});

		if (!window.history.state.modal) {
			const modalState = { modal: true };
			history.pushState(modalState, null);
		}

		this.extra.extraCategories.forEach(cat => {
			if (cat.free == undefined) {
				cat.free = 0;
			}
		});
		this.initExtra();
	}

	initExtra() {
		this.extra.extraCategories.sort((a, b) => {
			if (a.kind == 3) {
				return -1;
			} else if (b.kind == 3) {
				return 1;
			} else {
				return 0;
			}
		});
		this.extra.extraCategories.forEach(cat => {
			//Dropdown
			if (cat.kind == 0) {
				this.selectItemInDropdown(cat.extraItems);
				for (let i = 0; i < cat.extraItems.length; i++) {
					if (this.sessionDataService.$inhouseLink
						? cat.extraItems[i].inhouseVisible
						: cat.extraItems[i].outerhouseVisible
					) {
						this.selectItemInDropdown(cat.extraItems, cat.extraItems[i]);
						break;
					}
				}
			}
			//Checkbox
			else if (cat.kind == 1) {
				cat.extraItems.forEach(item => {
					item.selected = false;
				});
			}
			//PlusMinus
			else if (cat.kind == 2) {
				cat.extraItems.forEach(item => {
					item.count = 0;
				});
			}
		});
	}

	// ngOnDestroy() {
	//     if (window.history.state.modal) {
	//         history.back();
	//     }
	// }

	ionViewDidEnter() {
		if (window.innerHeight === window.screen.height && navigator.userAgent.match(/instagram/i)) {
			window.dispatchEvent(new Event("resize"));
		}
	}

	selectItemInDropdown(dropdownItems: any, item: any = {}) {
		if (this.menuService.isUnclickable()) {
			return;
		}
		dropdownItems.forEach(item => {
			//Handle indexRaised
			if (item.selected == true && item.add != undefined) {
				item.add.forEach(addIndex => {
					if (
						this.extra.extraCategories[addIndex.indexRaised] != undefined
						&& this.extra.extraCategories[addIndex.indexRaised].free != undefined
					) {
						this.extra.extraCategories[addIndex.indexRaised].free -= addIndex.amount;
					}
				});
			}
			//Deselect everything
			item.selected = false; //not sure about this (carsten) - sollte doch davon alles deselecten oder? this.extra.extraCategories[addIndex.indexRaised] 
		});
		//Handle indexRaised
		if (item.add != undefined) {
			item.add.forEach(addIndex => {
				if (
					this.extra.extraCategories[addIndex.indexRaised] != undefined
					&& this.extra.extraCategories[addIndex.indexRaised].free != undefined
				) {
					this.extra.extraCategories[addIndex.indexRaised].free += addIndex.amount;
				}
			});
		}
		//Select the correct option
		item.selected = true;
	}

	checkItemInCheckbox(checkbox: any, item: any) {
		if (this.menuService.isUnclickable()) {
			return;
		}
		//Check if maxAmount of selected checkboxen was reached. if its zero we have no max count
		if (!item.selected && checkbox.maxCount != undefined && checkbox.maxCount !== 0) {
			const count = this.getCountInCheckbox(checkbox);
			if (count >= checkbox.maxCount) {
				return;
			}
		}
		//Toggle Checkbox
		item.selected = !item.selected;
	}

	shouldDisplayPlus(extraCategory: ExtraCategory): boolean {
		if (this.menuService.isUnclickable()) {
			return false;
		}
		if (extraCategory.maxCount === undefined || extraCategory.maxCount === 0) {
			return true;
		}
		return this.getCountInPlusMinus(extraCategory) < extraCategory.maxCount;
	}

	incrCountInPlusMinus(plusMinus: any, item: any, value: number) {
		if (this.menuService.isUnclickable()) {
			return;
		}
		if (value > 0) {
			const count = this.getCountInPlusMinus(plusMinus);
			if (plusMinus.maxCount !== undefined && plusMinus.maxCount !== 0
				&& count >= plusMinus.maxCount) {
				return;
			}
		}
		if (item.count + value >= 0) {
			item.count = item.count + value;
		}
	}

	getCountInCheckbox(checkbox) {
		let count = 0;
		checkbox.extraItems.forEach(item => {
			count += item.selected ? 1 : 0;
		});
		return count;
	}

	getCountInPlusMinus(plusMinus) {
		let count = 0;
		plusMinus.extraItems.forEach(item => {
			count += item.count;
		});
		return count;
	}

	getSelected(cat: any) {
		for (let i = 0; i < cat.extraItems.length; i++) {
			if (cat.extraItems[i].selected == true) {
				return cat.extraItems[i];
			}
		}
	}

	min(a: number, b: number) {
		return Math.min(a, b);
	}

	categoryHasItems(cat): boolean {
		const filtered = cat.extraItems?.filter(elem => this.sessionDataService.$inhouseLink
			? elem.inhouseVisible
			: elem.outerhouseVisible);
		if (filtered !== undefined && filtered.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	getPrice() {
		let price = parseFloat(this.emptyStringToZero(this.sessionDataService.$inhouseLink
			? this.dish.inhousePrice
			: this.dish.outerhousePrice));
		this.extra.extraCategories.forEach(cat => {

			if (cat.extraItems === undefined) {
				return;
			}
			//Dropdown
			if (cat.kind == 0) {
				for (let i = 0; i < cat.extraItems.length; i++) {
					if (cat.extraItems[i].selected == true) {
						price += parseFloat(this.emptyStringToZero(this.sessionDataService.$inhouseLink
							? cat.extraItems[i].inhousePrice
							: cat.extraItems[i].outerhousePrice));
						break;
					}
				}
			}
			//Checkbox
			else if (cat.kind == 1) {
				let selectedFree = 0;
				for (let i = 0; i < cat.extraItems.length; i++) {
					if (cat.extraItems[i].selected == true) {
						if (cat.free <= selectedFree) {
							price += parseFloat(this.emptyStringToZero(this.sessionDataService.$inhouseLink
								? cat.extraItems[i].inhousePrice
								: cat.extraItems[i].outerhousePrice));
						}
						selectedFree++;
					}
				}
			}
			//PlusMinus
			else if (cat.kind == 2) {
				let selectedFree = 0;
				for (let i = 0; i < cat.extraItems.length; i++) {
					if (cat.free <= selectedFree + cat.extraItems[i].count) {
						price += parseFloat(this.emptyStringToZero((
							this.sessionDataService.$inhouseLink
								? cat.extraItems[i].inhousePrice
								: cat.extraItems[i].outerhousePrice
						) * Math.min(
							selectedFree
								+ cat.extraItems[i].count
								- cat.free,
							cat.extraItems[i].count,
						)));
					}
					selectedFree += cat.extraItems[i].count;
				}
			}
		});
		return price;
	}

	emptyStringToZero(val) {
		let ret = val;
		if (val == "") {
			ret = 0;
		}

		return ret;
	}

	public async closeModal() {
		const isModalOpened = await this.modalCtrl.getTop();
		if (isModalOpened) {
			this.modalCtrl.dismiss(null);
		}
	}

	public canAddToCart(): boolean {
		let categoryIndex = 0;
		
		for (const category of this.extra.extraCategories) {
			const categoryMinCount = category.minCount === undefined ? 0 : category.minCount;
			let selectedItems = 0;

			// check in order to ignore the min items if the category is invisible anyways.
			let isCategoryInvisibleInhouse = true;
			let isCategoryInvisibleOuterhouse = true;

			for (const item of category.extraItems) {
				if (category.kind == 0 || category.kind == 1) {
					selectedItems += item.selected ? 1 : 0;
				} else if (category.kind == 2) {
					selectedItems += item.count;
				}
				//  is the item inhouseVisible?
				if (item.inhouseVisible == true) {
					isCategoryInvisibleInhouse = false;
				}
				// is the item inhouseVisible?
				if (item.outerhouseVisible == true) {
					isCategoryInvisibleOuterhouse = false;
				}
			}
			if (selectedItems < categoryMinCount) {
				//are we inhouse or are we outerhouse?
				if ((isCategoryInvisibleInhouse == false
					&& this.sessionDataService.$inhouseLink == true)
					||								(isCategoryInvisibleOuterhouse == false
						&& this.sessionDataService.$inhouseLink == false)) {

					this.errorInCategoryIndex = categoryIndex;
					this.utilService.alertInfo(
						"Fehler",
						`Sie müssen mindestens ${categoryMinCount} 
						Elemente in der Kategorie ${category.name} auswählen, 
					haben aber nur ${selectedItems} ausgewählt.`,
					);
					const scrollElement = document.querySelector(`#category${categoryIndex}`);
					if (scrollElement !== null) {
						scrollElement.scrollIntoView({ behavior: "smooth" });
					}
					return false;
				}
				categoryIndex++;
			}
		}
		return true;
	}

	public async toCart() {
		if (!this.canAddToCart()) {
			return;
		}
		
		this.extra.note = this.note;
		const data = {
			dish: this.dish,
			extra: this.extra,
			total: this.getPrice(),
		};
		this.modalCtrl.dismiss(data);
		this.toast("Zum Warenkorb hinzugefügt", "orderbird");
	}

	async toast(message, status) {
		const toast = await this.toastr.create({
			color: status,
			duration: 2000,
			message: message,
			position: "top",
		});
		toast.present();
	}

	splitDescritption(description) {
		return description.split("****");
	}

	isOverflown(element) {
		return element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth;
	}

	calculateOverflow() {
		const elem = document.getElementById("desc-container");
		this.hasOverLength = this.isOverflown(elem);
		return this.hasOverLength;
	}

	public getProductTranslation(product: Product): {
		name: string;
		description: string;
	} {
		return this.localizationService.getProductTranslation(product);
	}

	public getExtraCategoryTranslation(categoryIndex: number): {
		name: string;
		description: string;
	} {
		const extraCategory = this.extra.extraCategories[categoryIndex];
		const ret = {
			name: extraCategory.name,
			description: extraCategory.description,
		};
		
		const extraTranslation = this.localizationService.getExtraTranslation(this.extra.id);
		if (extraTranslation === undefined) {
			return ret;
		}

		const extraCategoryTranslation = extraTranslation.extraCategories[categoryIndex];
		if (extraCategoryTranslation === undefined) {
			return ret;
		}

		if (extraCategoryTranslation.name !== "") {
			ret.name = extraCategoryTranslation.name;
		}

		if (extraCategoryTranslation.description !== "") {
			ret.description = extraCategoryTranslation.description;
		}

		return ret;
	}


	public getExtraItemTranslation(categoryIndex: number, itemIndex: number): {
		name: string;
	} {
		const extraItem = this.extra.extraCategories[categoryIndex].extraItems[itemIndex];
		const ret = {
			name: extraItem.name,
		};

		const extraTranslation = this.localizationService.getExtraTranslation(this.extra.id);
		if (extraTranslation === undefined) {
			return ret;
		}

		const extraCategoryTranslation = extraTranslation.extraCategories[categoryIndex];
		if (extraCategoryTranslation === undefined) {
			return ret;
		}

		const extraItemTranslation = extraCategoryTranslation.extraItems[itemIndex];
		if (extraItemTranslation === undefined) {
			return ret;
		}

		if (extraItemTranslation.name !== "") {
			ret.name = extraItemTranslation.name;
		}

		return ret;
	}

	/**
	 * Returns the options for the currencyPipe that are defined within gastro.menuOptions.priceFormatting if we are in the digital menu.
	 * If we are not in the digital menu, we return the default values leading to a formatting in the form of: "12.50 €".
	 * @returns 
	 */
	public getPriceFormat(): CurrencyPipeOptions {
		const options: CurrencyPipeOptions = {
			currencyCode: "EUR",
			digitsInfo: "1.2-2",
			display: "symbol-narrow",
			locale: "de",
		};

		// Return default if menu is clickable
		if (!this.menuService.isUnclickable()) {
			return options;
		}

		const templateConfig = this.templateStyleService.$templateConfig;

		return templateConfig.priceFormatting ?? options;
	}
}

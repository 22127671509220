export class Observable<T> {
	private _value: T;
	private _observers: ((value: T) => void)[] = [];

	public constructor(value: T) {
		this._value = value;
	}

	get value(): T {
		return this._value;
	}

	set value(value: T) {
		this._value = value;
		this._observers.forEach(observer => {
			observer(value);
		});
	}

	subscribe(observer: (value: T) => void): void {
		this._observers.push(observer);
	}

	notifyObservers(): void {
		this._observers.forEach(observer => {
			observer(this._value);
		});
	}
}
import { SwUpdate } from "@angular/service-worker";
import { Injectable, OnDestroy, ApplicationRef } from "@angular/core";
import { Subject, timer, concat } from "rxjs";
import { takeUntil, first } from "rxjs/operators";
import { ToastController } from "@ionic/angular";

@Injectable()
export class UpdatesService implements OnDestroy {

	private _unsb$ = new Subject();

	constructor(private _swUpdate: SwUpdate, appRef: ApplicationRef, public toastController: ToastController) {
		console.log("%c Update service is running...", "color: green; font-weight: bold;");

		if (this._swUpdate.isEnabled) {
			console.log("%c Service worker enabled", "color: orange; font-weight: bold;");

			// Allow the app to stabilize first, before starting polling for updates.
			const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
			const everySixHours$ = timer(0, 1000 * 60 * 60 * 6);
			const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

			everySixHoursOnceAppIsStable$
				.pipe(takeUntil(this._unsb$))
				.subscribe(() => {
					console.log("%c Checks for updates...", "color: blue; font-weight: bold;");
					this._swUpdate.checkForUpdate()
						.then(() => {
							console.log("%c Finish checking for updates...", "color: blue; font-weight: bold;");
						});
				});
		} else {
			console.log("%c No service worker allow", "color: red; font-weight: bold;");
		}
		this.SubscribeForUpdates();
	}

	SubscribeForUpdates(): void {
		console.log("%c Subscribed for updates", "color: blue; font-weight: bold;");
		this._swUpdate.available
			.pipe(takeUntil(this._unsb$))
			.subscribe(event => {
				console.log("current version is", event.current.hash);
				console.log("available version is", event.available.hash);
				this._swUpdate.activateUpdate()
					.then(async() => {
						const toast = await this.toastController.create({
							buttons: [
								{
									handler: () => {
										window.location.reload();
									},
									icon: "star",
									side: "start",
									text: "Update",
								},
							],
							header: "Update",
							message: "Neues Update gefunden",
							position: "top",
						});
						toast.present();
					});
			});
	}

	ngOnDestroy(): void {
		this._unsb$.next(true);
		this._unsb$.complete();
	}
}
/* eslint-disable @typescript-eslint/ban-types */
import { PreloadingStrategy, Route } from "@angular/router";

import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";

export interface RouteToPreload {
    routePath: string;
    route: Route;
    load: Function;
}

@Injectable({ providedIn: "root" })
export class AppRoutingPreloaderService implements PreloadingStrategy {
	preload(route: Route, load: Function): Observable<any> {
		if (route.data && route.data.preload) {
			return load();
		}
		return of(null);
	}
}
<div *ngIf="!menuService.disableMenuSkeleton">
    
    <!-- Default -->
    <div *ngIf="this.layout === 'Default'">
        <ion-list>
            <ng-container *ngFor="let dummy of [].constructor(2), let catIndex = index">
                <ion-item>
                    <ion-label>
                        <h1>
                            <ion-skeleton-text [animated]="animate" style="width: 80px"></ion-skeleton-text>
                        </h1>
                        <p>
                            <ion-skeleton-text [animated]="animate" style="width: 240px"></ion-skeleton-text>
                        </p>
                    </ion-label>
                </ion-item>
                <ion-item *ngFor="let dummy2 of [].constructor(3 - catIndex)">
                    <ion-skeleton-text [animated]="animate" style="width: 27px; height: 27px" slot="start">
                    </ion-skeleton-text>
                    <ion-label>
                        <h3>
                            <ion-skeleton-text [animated]="animate" style="width: 50%"></ion-skeleton-text>
                        </h3>
                        <p>
                            <ion-skeleton-text [animated]="animate" style="width: 80%"></ion-skeleton-text>
                        </p>
                        <p>
                            <ion-skeleton-text [animated]="animate" style="width: 60%"></ion-skeleton-text>
                        </p>
                    </ion-label>
                </ion-item>
            </ng-container>
        </ion-list>
    </div>

    <!-- Switch -->
    <div *ngIf="this.layout === 'Switch'" class="switch">
        <ion-list>
            <ng-container *ngFor="let dishSkeleton of [].constructor(5), let dishIndex = index">
                <ion-item [ngClass]="{'reverse': dishIndex % 2 === 0}" color="none" lines="none" style="padding-bottom: 10px">
                    <ion-skeleton-text style="width: 40%; height: 100%;">
                    </ion-skeleton-text>
                    <ion-label>
                        <h1>
                            <ion-skeleton-text style="width: 30%; height: 20px;"></ion-skeleton-text>
                        </h1>
                        <p>
                            <ion-skeleton-text style="width: 80%"></ion-skeleton-text>
                        </p>
                        <p>
                            <ion-skeleton-text style="width: 60%"></ion-skeleton-text>
                        </p>
                    </ion-label>
                </ion-item>
            </ng-container>
        </ion-list>
    </div>

    <!-- Kacheln -->
    <div *ngIf="this.layout === 'Kacheln'">
        <ion-row>
            <ion-col *ngFor="let col of [].constructor(6)" size-lg="3" size="6">
                <ion-card class="kachelnCard">
                    <ion-list style="height: 100%;">
                        <ion-list-header>
                            <ion-skeleton-text [animated]="animate" style="width: 50%; margin: auto;"></ion-skeleton-text>
                        </ion-list-header>
                        <ion-skeleton-text [animated]="animate" style="width: 80%; height: 70%; margin: auto;"></ion-skeleton-text>
                    </ion-list>
                </ion-card>
            </ion-col>
        </ion-row>
    </div>
</div>


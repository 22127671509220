import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { StartOfInteractionPage } from "./start-of-interaction.page";

const routes: Routes = [
	{
		path: "",
		component: StartOfInteractionPage,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class StartOfInteractionPageRoutingModule {}

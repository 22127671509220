import { NgModule } from "@angular/core";
import { SafePipe } from "./safe.pipe";
import { PricePipe } from "./price.pipe";
import { CurrencyPipe } from "@angular/common";

@NgModule({
	declarations: [
		SafePipe,
		PricePipe,
	],
	exports: [
		SafePipe,
		PricePipe,
	],
	providers: [
		PricePipe,
		CurrencyPipe,
	],
})
export class PipesModule {}

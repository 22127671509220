import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { CartService } from "../services/cart.service";
import { SessionDataService } from "../services/session-data.service";
import { StorageService } from "../services/storage.service";
import { UserService } from "../services/user.service";

@Injectable({ providedIn: "root" })
export class InGastroGuard implements CanActivate {

	constructor(
        public cartService: CartService,
        private userService: UserService,
        public router: Router,
        public storageService: StorageService,
	) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise(async(resolve, reject) => {
			const isGastroLoggedOut = await this.storageService.load("isGastroLoggedOut");
			console.log("isGastrologgedout", isGastroLoggedOut);
			if (
				isGastroLoggedOut === true
				|| isGastroLoggedOut === undefined
				|| isGastroLoggedOut === ""
				|| isGastroLoggedOut === null
			) {
				this.router.navigate(["home"]);
				resolve(false);
			} else {
				resolve(true);
			}
		});
	}
  
}

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-vytal-modal",
	templateUrl: "./vytal-modal.page.html",
	styleUrls: ["./vytal-modal.page.scss"],
})
export class VytalModalPage implements OnInit {

	constructor(public modalCtrl: ModalController) { }

	ngOnInit() {
	}

}

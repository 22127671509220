<ion-row *ngIf="!this.gastroService.hasOpen()" class="banner">
  <ion-col>
      <div>
          <ion-text>
              <ng-container>Der Bestellservice ist gerade nicht verfügbar. </ng-container>
              <ng-container *ngIf="this.nextPickup !== undefined">Wir öffnen wieder um {{this.nextPickup}}. </ng-container>
              <ng-container *ngIf="this.nextOpening !== undefined">Bestellungen sind ab {{this.nextOpening}} möglich. </ng-container>
              <a 
                  [routerLink]="['/closed']"
				  routerDirection="forward"
              >
                  Zu den Öffnungszeiten
              </a>
          </ion-text>
      </div>
  </ion-col>
</ion-row>
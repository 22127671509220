export const dishesPerProductDocument = 10000;

export const languages = [
	{
		languageCode: "de",
		label: "Deutsch",
	},
	{
		languageCode: "en",
		label: "Englisch",
	},
	{
		languageCode: "fr",
		label: "Französisch",
	},
	{
		languageCode: "es",
		label: "Spanisch",
	},
	{
		languageCode: "ru",
		label: "Russisch",
	},
	{
		languageCode: "tr",
		label: "Türkisch",
	},
	{
		languageCode: "pl",
		label: "Polnisch",
	},
	{
		languageCode: "it",
		label: "Italienisch",
	},
	{
		languageCode: "nl",
		label: "Niederländisch",
	},
	{
		languageCode: "hr",
		label: "Kroatisch",
	},
	{
		languageCode: "el",
		label: "Grieschisch",
	},
	{
		languageCode: "ar",
		label: "Arabisch",
	},
];
<ion-content>
	<ion-grid style="margin: 40px;">
		<ion-row  class="ion-justify-content-center">
<ion-img style="height: 80px;" height="5" src="../assets/icons/wine-beer-glass-icon.svg"></ion-img>
		</ion-row>
		<ion-row  class="ion-justify-content-center">
<h3>	Bist du mindestens {{age}} Jahre?</h3>
		</ion-row>
		<ion-row  class="ion-justify-content-center">
	Um deine Bestellung abzuschließen, bestätige bitte, dass du das gesetzliche Mindestalter erreicht hast für den Kauf dieses Artikels in dem Land, in dem du den Artikel kaufen möchtest.
		</ion-row>
		<ion-row  class="ion-justify-content-center">
	Du musst dein Alter {{age}}+ durch einen gültigen Ausweis nachweisen, um deine Bestellung zu erhalten.
		</ion-row>

<ion-row  class="ion-justify-content-center">
	<ion-col>
<ion-button (click)="accept()" color="split-main" size="large" expand="block">
	Ja, weiter
</ion-button>
</ion-col>
<ion-col>
<ion-button (click)="dismiss()" color="split-main" size="large" expand="block" fill="outline">
	Nein, Abrechen
</ion-button>
</ion-col>
</ion-row>
</ion-grid>

</ion-content>

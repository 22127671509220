import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
import { Globals } from "./util.service";

@Injectable({ providedIn: "root" })
export class GlobalService {
	globals: Globals;
	constructor(private afs: AngularFirestore) {
		this.initGlobals();
	}

	async initGlobals() {
		this.globals = (
			await this.afs
				.collection("globals")
				.doc(environment.globalsID)
				.get()
				.toPromise()
		).data() as Globals;
	}
}

import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LoginGuard implements CanActivate {

	constructor(
        private router: Router,
        private auth: AngularFireAuth,
	) { }
      
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise((resolve, reject) => {
			this.auth.onAuthStateChanged((user: firebase.default.User) => {
				if (user) {
					resolve(true);
				} else {
					this.router.navigate(["/home"]);
					resolve(false);
				}
			});
		});
	}
  
}

/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { environment } from "./../../environments/environment";
import { PaymentService } from "./payment.service";
import { TableService } from "./table.service";
import { GastroService } from "./gastro.service";
import {
	AngularFirestore,
} from "@angular/fire/compat/firestore";
import { UtilService } from "./util.service";
import { Observable } from "src/shared/split-submodules/Observable/Observable";
@Injectable({
	providedIn: "root",
})

export class TableContentService {

	constructor(

		public afs: AngularFirestore,
		public paymentService: PaymentService,
		public router: Router,
		public utilService: UtilService,
		public tableService: TableService,
		public gastroService: GastroService,
	) { }

	public tableContent: Observable<any[]> = new Observable<any[]>([]);
	public selectedTableContent: Observable<any[]> = new Observable<any[]>([]);

	public tab: any;


	public aggregatedTableContent = [];
	public aggregatedSelectedTableContent = [];

	public taxObjects;
	public gastroId;


	/**
 * Fetches OrderBird table data for the currently selected table in the gastro venue.
 * Resets aggregated and selected table content, sends a request to fetch tabs, and updates the tab information.
 * Displays an alert if there is an issue with the QR code or the data retrieval process.
 */
	async fetchOBTableData(): Promise<void> {
		if (this.tableService.getTable() == undefined) {
			this.utilService.alertInfo("Ups!", "Etwas ist schief gelaufen. Bitte scanne den QR-Code noch einmal!");
			this.tableContent.value = [];
			this.aggregatedTableContent = [];
			this.aggregatedSelectedTableContent = [];
			this.selectedTableContent.value = [];
			return;
		}

		const data = {
			gastroId: this.gastroService.$gastroId,
			tableId: this.tableService.getTable().addData.ob_id,
		};
		this.gastroId = data.gastroId;
		const url = `${environment.functionsUrlEU}obFetchTabs`;
		try {
			const ret = await $.post(url, data);
			this.tab = ret;
			this.addTabToTableContent(ret);
			return;
		} catch (error: unknown) {
			this.tableContent.value = [];
			this.aggregatedTableContent = [];
			this.aggregatedSelectedTableContent = [];
			this.selectedTableContent.value = [];
			
			if (error instanceof Error) {
				console.log(error.message);

			}
			this.tab = {};
			return;
		}
	}

	/**
 * Adds an aggregation identifier to each order in the provided table content array.
 * The aggregation identifier is created by concatenating the itemUuid of the order and its children, if any.
 *
 * @param tableContent - An array containing orders and their associated children in the table.
 */
	addAggregationIdentifierToOrders(tableContent: any[]) {
		for (const order of tableContent) {
			let identifier = order.itemUuid;
			for (const child of order.children) {
				identifier = `${identifier} ${child.itemUuid}`;
			}

			order.aggregationIdentifier = identifier;

		}
	}

	/**
 * Aggregates the provided table content by combining orders with the same aggregation identifier.
 * Adds a counter to represent the number of occurrences for each unique aggregated order.
 *
 * @param tableContent - An array containing orders with aggregation identifiers to be aggregated.
 * @returns An array of aggregated orders with counters indicating the number of occurrences.
 */
	aggregateTableContent(tableContent: any[]) {
		const tempList = [];
		for (const item of tableContent) {
			let found = false;
			for (const tempItem of tempList) {
				if (item.aggregationIdentifier == tempItem.aggregationIdentifier) {
					found = true;
					tempItem.counter++;
					break;
				}


			}
			if (!found) {
				item.counter = 1;
				tempList.push(item);

			}

		}
		return tempList;
	}

	/**
 * Retrieves tax information for the given positions based on the products and dishes available in the gastro venue.
 * Fetches tax details for each position by matching itemUuid with dishes in the venue's products.
 *
 * @param positions - An array of positions for which tax information is to be retrieved.
 * @param gastroId - Identifier for the gastro venue.
 * @returns An array of tax objects containing gross and net totals, tax rate, and tax total for each position.
 */
	async getTaxesForPositions(positions: any[], gastroId) {

		const listOfOBUUIDs = [];
		const taxObjects = [];
		const productsRef = await this.afs.collection("gastro").doc(gastroId).collection("products").get().toPromise();
		const listOfMenus = [];
		if (!productsRef.empty) {
			for (const products of productsRef.docs) {
				listOfMenus.push(products.data());
			}
		}

		for (const position of positions) {
			for (const menu of listOfMenus) {
				for (const dish of menu.dishes) {
					if (position.itemUuid === dish.addData.ob_id) {
						//TODO: move the setting of the inhouseTax and outerhouseTax to a sepperated function
						position.inhouseTax = dish.inhouseTax;
						position.outerhouseTax = dish.outerhouseTax;
						const taxObject = {
							gross_total: {
								amount: position.price.amount,
								currency: "EUR",
								exp: -2,
							},
							name: `${Math.round(dish.inhouseTax * 100)}`, //TODO outerhouseTax
							net_total: {
								amount: position.price.amount
									- this.calcSingleTaxForPrice(position.price.amount, dish.inhouseTax),
								currency: "EUR",
								exp: -2,
							},
							rate: dish.inhouseTax, //TODO outerhouseTax

							tax_total: {
								amount: this.calcSingleTaxForPrice(position.price.amount, dish.inhouseTax),
								currency: "EUR",
								exp: -2,
							},

						};
						const extraObject = await this.afs.collection("gastro").doc(gastroId).collection("dishExtras").doc(dish.extraId).get().toPromise();
						const extraData = extraObject.data();
						for (const child of position.children) {
							for (const extraCategories of extraData.extraCategories) {
								for (const extra of extraCategories.extraItems) {
									if (child.itemUuid === extra.addData.ob_id) {
										//TODO: move the setting of the inhouseTax and outerhouseTax to a sepperated function
										child.inhouseTax = extra.inhouseTax;
										child.outerhouseTax = extra.outerhouseTax;
										taxObject.gross_total.amount += child.price.amount;
										taxObject.net_total.amount += child.price.amount
											- this.calcSingleTaxForPrice(child.price.amount, dish.inhouseTax);
										taxObject.tax_total.amount += this.calcSingleTaxForPrice(child.price.amount, dish.inhouseTax);
									}
								}
							}
						}
						taxObjects.push(taxObject);
						break;
					}

				}
			}

		}
		return taxObjects;


	}

	/**
   * calc tax for price
   * @param price times 100 (e.g. 2.80€ = 280)
   * @param tax in decimal (e.g. 0.19)
   * @returns rounded tax in cents (e.g. 45)
   */
	calcSingleTaxForPrice(price: number, tax: number): number {
		const calcedTax = price - (price / (1 + tax));
		return Math.round((calcedTax + Number.EPSILON));
	}

	/**
	   * is given a tab from the OB API and puts its in a converted form into a local object called tableContent
	   * @param tabContent 
	   * @returns 
	   */
	addTabToTableContent(tabContent: any) {
		const allPositions = [];
		for (const party of tabContent.parties) {
			for (const position of party.positions) {
				position.showAll = false;
				allPositions.push(position);
			}
		}

		this.addAggregationIdentifierToOrders(allPositions);

		const selectedPositions = [];

		for (const position of allPositions) {
			const selectedPosition = this.selectedTableContent.value.find((selected) => selected.uuid === position.uuid);
			if (selectedPosition) {
				selectedPositions.push(position);
			}
		}

		this.tableContent.value = allPositions;
		this.selectedTableContent.value = selectedPositions;

		this.aggregatedTableContent = this.aggregateTableContent(allPositions);
		this.aggregatedSelectedTableContent = this.aggregateTableContent(selectedPositions);
	}

	/**
	   * is triggered when a user selects an item from the table content in order to add it to the list of items to be paid
	   * the idea here is that it should be possible to do partial payment of a tab
	   *
	   * @param position 
	   */
	addOrderToSelectedTableContent(position) {
		this.selectedTableContent.value
			.push(this.getItemFromTableContentWithAggreID(position));
		this.tableContent.notifyObservers();
		this.selectedTableContent.notifyObservers();
	}
	/**
	   * is triggered when a user selects an item from the selected table content in order to remove it from the list of items to be paid
	   * the idea here is that it should be possible to do partial payment of a tab
	   *
	   * @param position 
	   */
	removeOrderFromSelectedTableContent(position) {
		this.selectedTableContent.value.splice(this.selectedTableContent.value
			.indexOf(this.getItemFromTableContentWithAggreID(position)), 1);
		this.tableContent.notifyObservers();
		this.selectedTableContent.notifyObservers();
	}
	/**
 * Retrieves the item from the table content array with the specified aggregation identifier.
 *
 * @param position - The position object with an aggregation identifier to be matched.
 * @returns The item from the table content array with the specified aggregation identifier.
 */
	getItemFromTableContentWithAggreID(position) {
		const item = {};
		
		for (const positionOnTable of
			this.tableContent.value.filter((filteredTable)=> filteredTable.aggregationIdentifier == position.aggregationIdentifier)
		) {
			if (!this.selectedTableContent.value.filter((filteredSelected)=> filteredSelected.aggregationIdentifier == position.aggregationIdentifier)
				.includes(positionOnTable)) {
				return positionOnTable;

			}
		}
	}
	/**
 * Retrieves the item from the selected table content array with the specified aggregation identifier.
 *
 * @param position - The position object with an aggregation identifier to be matched.
 * @returns The item from the selected table content array with the specified aggregation identifier.
 */
	getItemFromSelectedTableContentWithAggreID(position) {
		let item = {};
		for (const positionOnTable of this.selectedTableContent.value) {
			if (position.aggregationIdentifier == positionOnTable.aggregationIdentifier) {
				item = positionOnTable;
			}
		}
		return item;
	}
	/**
 * Selects all positions from the table content, adding them to the aggregated selected table content.
 * Clears the aggregated table content and updates the table and selected table content arrays.
 */

	selectAll() {
		const newList = this.tableContent.value.slice();
		for (const position of newList) {
			this.selectedTableContent.value.push(position);
		}
		this.tableContent.notifyObservers();
		this.selectedTableContent.notifyObservers();
	}

	/**
 * Deselects all positions from the table content, adding them to the aggregated table content.
 * Clears the aggregated selected table content and updates the table and selected table content arrays.
 */

	deselectAll() {
		this.aggregatedSelectedTableContent = [];
		this.selectedTableContent.value = [];
		this.tableContent.notifyObservers();
		this.selectedTableContent.notifyObservers();
	}

	/**
 * Calculates and returns the total price of all positions in the table content array.
 * Includes the prices of both main positions and their children.
 *
 * @returns The total price of all positions in the table content array, converted to Euros.
 */

	getTotal() {
		let total = 0;

		for (const position of this.tableContent.value) {
			total += (position.price.amount);
			for (const child of position.children) {
				total += child.price.amount;
			}
		}

		return total / 100;
	}
	/**
 * Retrieves and returns the total count of positions in the table content array.
 *
 * @returns The total count of positions in the table content array.
 */
	getTotalCount() {
		let totalCount = 0;
		totalCount = this.tableContent.value.length;

		return totalCount;
	}
	/**
 * Calculates and returns the total price of all selected positions in the selected table content array.
 * Includes the prices of both main positions and their children.
 *
 * @returns The total price of all selected positions in the selected table content array, converted to Euros.
 */

	getTotalOfSelectedContent() {
		let total = 0;

		for (const position of this.selectedTableContent.value) {
			total += (position.price.amount);
			for (const child of position.children) {
				total += child.price.amount;
			}
		}

		return total / 100;
	}

	/**
 * Navigates to the "pay-at-table-cart" route, redirecting the user to the Pay-at-Table cart page.
 */
	goToPayAtTableCart(): void {
		this.router.navigate(["pay-at-table-cart"]);
	}

	/**
 * Navigates to the "table-content" route, redirecting the user to the Table Content page.
 */
	goToTableContent(): void {
		this.router.navigate(["table-content"]);
	}

	getOBPositionTotal() {
		const obTotal = { currency: "EUR", amount: 0, exp: -2 };

		for (const position of this.selectedTableContent.value) {
			obTotal.amount = obTotal.amount + position.price.amount;
		}

		return obTotal;
	}

}
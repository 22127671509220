import { Component, Input, OnInit } from "@angular/core";
import { AssetService } from "src/app/services/asset.service";
import { MenuService } from "src/app/services/menu.service";
import { ProductTag } from "src/shared/split-submodules/types/types";

@Component({
	selector: "app-tag-list",
	templateUrl: "./tag-list.component.html",
	styleUrls: ["./tag-list.component.scss"],
})
export class TagListComponent implements OnInit {

	private _tagIds: number[] = [];

	@Input()
	set tagIds(val: number[]) {
		this._tagIds = val;
		this.initializeTags();
	}

	get tagIds(): number[] {
		return this._tagIds;
	}

	@Input() blacklistedTagIds: number[] = [
		3, 4, 5,
	];

	tags: ProductTag[] = [];

	constructor(
		private assetService: AssetService,
		private menuService: MenuService,
	) { }

	ngOnInit() {
		this.initializeTags();
	}

	/**
	 * Initializes the tags array by mapping [tagIds] to their respective ProductTag
	 */
	private initializeTags() {
		this.tags = this.tagIds
			?.map(id => this.menuService.productTags.find(elem => elem.id === id))
			.filter(elem => elem !== undefined);
	}

	/**
	 * Whether or not to display a tag. 
	 * @param tag The tag in question
	 * @returns 
	 */
	shouldDisplayTag(tag: ProductTag): boolean {
		if (this.blacklistedTagIds.includes(tag.id)) {
			return false;
		}

		if (this.getPathToTag(tag.id) === "") {
			return false;
		}

		return true;
	}

	/**
	 * Returns the path to an asset which resembles the tag with [tagId]
	 * @param tagId 
	 * @returns 
	 */
	getPathToTag(tagId: number): string {
		return this.assetService.getPathToProductTagIcon(tagId);
	}
}

import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-footer-agb",
	templateUrl: "./footer-agb.component.html",
	styleUrls: ["./footer-agb.component.scss"],
})
export class FooterAgbComponent implements OnInit {

	constructor() { }

	ngOnInit() {}

}

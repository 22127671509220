import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-age-verification",
	templateUrl: "./age-verification.page.html",
	styleUrls: ["./age-verification.page.scss"],
})
export class AgeVerificationPage implements OnInit {

	@Input() age = 16;

	constructor(public modalCtrl: ModalController) { }

	ngOnInit() {
	}

	accept() {
		this.modalCtrl.dismiss(true);
	}

	dismiss() {
		this.modalCtrl.dismiss(false);
	}
}

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { GastroService } from "../services/gastro.service";

@Injectable({ providedIn: "root" })
export class NegateOpenGuard implements CanActivate {

	constructor(
        public gastroService: GastroService,
        public router: Router,
	) { }
      
	canActivate(
    
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (
			this.gastroService.$gastro.openingHours == undefined
			|| !this.gastroService.$gastro.openingHours
			|| this.gastroService.$gastro.openingHours.length == 0
			|| this.gastroService.hasOpen() === true
		) {
			this.router.navigate(["restaurant-page"]);
		}
		return true;
	}
  
}

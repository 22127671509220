import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { SessionDataService } from "../../session-data.service";
import { Category, Extra, ExtraCategory, ExtraItem, Order, PaymentMethod, Product } from "../../util.service";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { UserService } from "../../user.service";
import { DeviceService } from "../../device.service";
import { CheckoutSystem } from "../checkout-system-api.service";

/** For more information visit https://developers.lightspeedhq.com/ecom/endpoints/category/ */

//#region lightspeed interfaces
interface ls_articleGroup {
	/**
	 * ID of article group. Each type of article group has it’s own unique id.
	 */
	id?: number;

	/**
	 * Name of article group. Must be assigned by user
	 */
	name: string;

	/**
	 * Short name of article group, used by printing for bills/bons and in some parts of App interface.
	 */
	shortName: string;

	/**
	 * The file name of the default image, which will be applied to all articles of the group, if they don’t have
	 * assigned images and the applyImageToArticles is set to true. Used to assign the proper icon to the
	 * articles in the platform interface. To allow the end user to choose the icon they must be downloaded
	 * from the platform store.
	 */
	image?: "default/nonalcoholic.png";

	/**
	 * The boolean indicator, if true the articles of this group will be printed on extra ticket.
	 */
	fixedAt?: boolean;


	/**
	 * The boolean indicator, if true the articles of this group will be considered as discountable, if discount is
	 * applied.
	 */
	discountable?: boolean;

	/**
	 * The boolean indicator, if true the articles of the group will be colored in the specified group’s color, if
	 * they don’t have specified colour.
	 */
	applyColorToArticles?: boolean;

	/**
	 * The boolean indicator, if true the articles of the group will get the image, provided in the field “image”
	 * as a filename, in case if they don’t have already assigned image.
	 */
	applyImageToArticles?: boolean;

	/**
	 * Nested object, containing the information about an id and name of the VAT rate for articles of the
	 * article group, considered to be consumed in restaurant. In Germany there are separate VAT rates for
	 * in house consumption and orders to be taken away. In other countries the content for externalVAT is
	 * either being duplicated (rather rarely) or le blank.
	 */
	internalVat?: {
		id: number;
		name: string;
	};

	/**
	 * Nested object, containing the information about an id and name of the VAT rate for articles of the
	 * article group, considered to be ordered to take away. In Germany there are separate VAT rates for in
	 * house consumption and orders to be taken away. In other countries the content for externalVAT is
	 * either being duplicated (rather rarely) or le blank.
	 */
	externalVat?: {
		id: number;
		name: string;
	};

	/**
	 * Nested object, containing the information about an id and name of the parent group of the current
	 * article group. Empty field means the current group has no parent groups, thus is a supergroup or
	 * parent group itself
	 */
	parent?: {
		id: number;
		name: string;
	};

	/**
	 * The HEX color code. The defined color is applied to the icon of all articles of the group, changing their
	 * color. Two conditions for this setting to be applied to each article: the boolean attribute applyColorToArticles 
	 * must be true and article must have no image being assigned.
	 */
	colorCode?: string;

	/**
	 * Nested array of objects, each of them contains the information about an id and name of the printer,
	 * assigned to this group of articles. By bon-printing all articles of current group will be listed on the
	 * bon printed on the given printers. If nullified the list will be empty. Patch operation overwrites all of
	 * collection at once.
	 */
	bonPrinters?: [];

	/**
	 * Nested array of objects, each of them contains the information about an id and name of the layout
	 * format, assigned to this group of articles for using by printing ordering tickets.
	 */
	ticketLayout?: {
		id: number;
		name: string;
	};

	/**
	 * Nested array of objects, each of them contains the information about an id and name of the layout,
	 * assigned to this group of articles for using by printing voiding tickets.
	 */
	ticketVoidLayout: {
		id: number;
		name: string;
	};
}

interface ls_article {
	/**
	 * The name of the article. Must be assigned by user.
	 */
	name: string;

	/**
	 * The short name of an article. This name is displayed on the POS. Must be assigned by user.
	 */
	shortName: string;

	/**
	 * Nested object, containing the information about an id and name of the article group, assigned to this
	 * article.
	 */
	articleGroup: {
		id: number;
		name: string;
	};

	/**
	 * Nested object, containing the information about an id and name of the article type, assigned to this
	 * article.
	 */
	articleType: {
		id: number;
		name: string;
	};

	/**
	 * The amount of article of position, that are available on stock in current business id.
	 */
	stockAmount?: number;

	/**
	 * Description of the article. Can be added by user. This field is called “Info” in the cloud.
	 */
	description?: string;

	/**
	 * True: the article is presented on the POS. False: it is marked in the cloud as deactivated and is not
	 * displayed on the POS. Default true.
	 */
	active?: boolean;

	/**
	 * The full price of an article, including taxes. It can include two decimal signs. Default 0.
	 */
	price?: number;

	/**
	 * Nested array of objects, each of them contains the information about an id and name of the price level,
	 * related to this article. Price level is created separately and causes the change of the article’s price in
	 * the predifined time period. Patch operation overwrites all of collection at once.
	 */
	priceLevelPrices?: any;

	/**
	 * The measurement of article. Customizable in Master Data / Article (Selling-Unit). Default 0.
	 */
	measures?: number;

	/**
	 * It shows the file name of the default image to the article. It can be used to assign the proper icon to the
	 * article in the platform interface. To allow the end user to choose the icon they must be downloaded
	 * from platform store.
	 */
	image?: string;

	/**
	 * The HEX color code. The defined color is applied to the article’s icon changing it’s color.
	 */
	colorCode?: string;

	/**
	 * Internal / Control String.
	 */
	internal?: string;

	/**
	 * Mode of article: 
	 * 1) “REGULAR” (default) - single type article without any modifiers (relations to other articles). Default state. 
	 * 2) “CONSTRAINTS” - article with this mode can refer to constraint articles, like salami for pizza. 
	 * 3) “AGGREGATED” - article with this mode includes aggregated items with defined price for the whole set, e.g. “Happy Meal” or “Breakfast”.
	 */
	mode?: string;

	/**
	 * Constraints Data, used with mode ‘CONSTRAINTS’.
	 */
	constraints?: ls_constraintData;

	/**
	 * Aggregated Data, used with mode ‘AGGREGATED’.
	 */
	aggregated?: ls_aggregatedData;

	/**
	 * Additional field for storing generic information in json format. Please adhere to the following structure
	 * of the JSON: ‘yourProductsName’: {‘element’: {‘subelement1’: 1, ‘subelement2’: 2}}. Always use similar
	 * name of your product as a root element. For existing articles use only patch operations for updating
	 * the field. Please note there is a special endpoint of PATCH type for updating the certain root element.
	 */
	additionalInformation?: any;

	/**
	 * Timestamp of last article modification. For example, it can be used to update only positions, which
	 * were changed. The format is “yyyy-MM-dd’T’HH:mm:ss.SSSZ”.
	 */
	modified?: string;

	/**
	 * SKU (Stock Keeping Unit) of article. Each article has it’s own unique SKU, that can’t be changed after
	 * initial creation of article.
	 */
	sku?: number;

	/**
	 * List of PLUs and EAN Codes for the article. Patch operation overwrites all of collection at once. At least
	 * one value must be present. Values should be different - equal numbers will be stored as one number.
	 */
	pluAndBarcodes?: string[];
}

interface ls_articleType {
	/**
	 * ID of article type. Each type of article has it’s own unique id.
	 */
	id?: number;

	/**
	 * Name of article type. Must be assigned by user.
	 */
	name: string;

	/**
	 * Description of the article type. Can be added by user.
	 */
	description?: string;

	/**
	 * True: the article of this type is presented on the POS as info article. Info articles are shown additionally
	 * during the ordering process, for instance, the user can choose info article “hot” or “cold” or “sugar-free”
	 * additionally to other article. Default false.
	 */
	info?: boolean;

	/**
	 * True: the article of this type is presented on the POS as free price article. When choosing such kind of
	 * articles the user is asked to input the price. Default true.
	 */
	fixedPrice?: boolean;

	/**
	 * True: the article of this type is presented on the POS as free name article. When choosing such kind of
	 * articles the user is asked to input the name. Default true.
	 */
	fixedName?: boolean;

	/**
	 * True: the article of this type has special properties on the POS. It is not displayed on layout by default
	 * and can be hidden on the receipts. The parameter is used for optional or constraint articles, for instance,
	 * to present some component of main article, like salami for pizza, or the state of article, like spicy. Default
	 * false.
	 */
	constraintOnly?: boolean;

	/**
	 * True: the article participated in the turnover. The “true” values are actual for special kinds of articles,
	 * like Tobacco. Default false.
	 */
	nonTurnover?: boolean;

	/**
	 * True: the article type is measurable. Default false.
	 */
	measure?: boolean;

	/**
	 * True: the article of this type is presented in reports as voucher type. It is used to distinguish between
	 * regular non-turnover articles and non-turnover voucher articles in reports. You can not enable “Voucher”
	 * while “Non Revenue” is disabled. The field is used in section “VOUCHER AMOUNT PER TENDER”. Default false.
	 */
	voucher?: boolean;
}

interface ls_constraintData {
	/**
	 * Nested array of objects, that represent constraint articles and settings of steps ordering process, which
	 * will be displayed on POS in process of ordering the main article, which has assigned constraint articles.
	 * Patch operation overwrites all of collection at once.
	 */
	steps: ls_constraintStep[];

	/**
	 * True: then the id (SKU) of the main article will be deleted after making an order, only SKUs of constrain
	 * articles will be displayed. Default false.
	 */
	deleteOriginal?: boolean;

	/**
	 * True: then each of the constraint articles will be presented as a separate position on the POS. That
	 * means the user will see Cola and Ice instead of Cola, Ice position. Default false.
	 */
	newPos?: boolean;
}

interface ls_constraintStep {
	/**
	 * Name of the step in the process of ordering with constraints. For instance, first step for burger would
	 * be - choose the bun.
	 */
	name?: string;

	/**
	 * True: the articles of each step are not necessarily must be chosen, i.e. can be skipped. Default false.
	 */
	optional?: boolean;

	/**
	 * True: it’s possible to choose more than one constraint article at each step. Default false.
	 */
	multipleSelects?: boolean;

	/**
	 * Nested array of objects, that represent constraint articles, which will be displayed on POS in process of
	 * ordering of the main article.
	 */
	articles: ls_skuName[];
}

interface ls_aggregatedData {
	/**
	 * List of articles with prices in aggregated mode. Patch operation overwrites all of collection at once.
	 */
	items: ls_aggregatedStep;

	/**
	 * Show constituents on receipt or not. Default false.
	 */
	shown?: boolean;
}

interface ls_aggregatedStep {
	/**
	 * Price of one article item.
	 */
	price: number;

	/**
	 * One article which belongs to the main item with aggregated mode.
	 */
	article: ls_skuName;
}

interface ls_skuName {
	/**
	 * SKU - stock keeping unit. Can be specified by creating the new position and can’t be changed anymore.
	 */
	sku: number;

	/**
	 * Name of instance.
	 */
	name?: string;
}

interface ls_paymentMethod {
	/**
	 * ID of payment method. Each payment method has it’s own unique id.
	 */
	id?: number;

	/**
	 * Name of payment method. Must be assigned by user.
	 */
	name: string;

	/**
	 * The short name of an payment method. This name is displayed on the POS. Must be assigned by user.
	 */
	shortName?: string;

	/**
	 * It shows the file name of the default image to the payment method. 
	 * It can be used to assign the proper icon to the payment method in the platform interface. 
	 * To allow the end user to choose the icon they must be downloaded from platform store.
	 */
	image?: string;

	/**
	 * Auto-generated value among one business.
	 */
	number?: number;

	/**
	 * Default true.
	 */
	active?: boolean;

	/**
	 * True: if bill total shall count negative. Default false.
	 */
	negative?: boolean;

	/**
	 * True: if warning shall be given if bill total exceeds limit. Default false.
	 */
	warningLimit?: boolean;

	/**
	 * True: if payment shall be denied if bill total exceeds limit. Default false.
	 */
	denyLimit?: boolean;

	/**
	 * True: if bill total shall be added to system turnover. Default false.
	 */
	addSystem?: boolean;

	/**
	 * True: if bill total shall be added to user total. Default false.
	 */
	addUser?: boolean;

	/**
	 * True: if tip shall be handled. Default false.
	 */
	tip?: boolean;

	/**
	 * True: if return money shall be calculated. Default false.
	 */
	returnMoney?: boolean;

	/**
	 * Maximum amount to be paid with this pay form (refers to base currency).
	 */
	maxLimit: number;

	/**
	 * True: if bill printing default is on or off. Default false.
	 */
	printDefault?: boolean;

	/**
	 * Description of the payment method.
	 */
	description?: string;

	/**
	 * Nested object, containing the information about an id and name of the payment type.
	 */
	type?: any;

	/**
	 * Default 0.
	 */
	position?: number;

	/**
	 * Default true.
	 */
	showOnPaymentScreen?: boolean;

	/**
	 * Nested array of objects, each of them contains the information about an id and name of the bill layout, 
	 * assigned to this payment method. If nullified the list will be empty. 
	 * Patch operation overwrites all of collection at once.
	 */
	billLayouts?: any[];

	/**
	 * Minimum payment amount (refers to base currency). Default 0.
	 */
	minPaymentAmount?: number;

	/**
	 * Maximum payment amount (refers to base currency). Default 0.
	 */
	maxPaymentAmount?: number;

	/**
	 * Default true.
	 */
	voidable?: boolean;

	/**
	 * Default false.
	 */
	readerTipping?: boolean;

	/**
	 * True: if single purpose voucher is set the fields ‘AddSystem’ and ‘AddUser’ become false. Default false.
	 */
	singlePurposeVoucher?: boolean;

	/**
	 * True: if multi purpose voucher is set the field ‘AddSystem’ becomes true and ‘AddUser’ becomes false. Default false.
	 */
	multiPurposeVoucher?: boolean;

}

interface ls_paymentMethodType {
	/**
	 * ID of payment method type. Each payment method type has it’s own unique id.
	 */
	id: number;
	/**
	 * Name of payment method type. Must be assigned by user.
	 */
	name?: string;
}

interface ls_employee {
	/**
	 * The first name of the employee. Customizable in Master Data / Employee.
	 */
	firstName: string;

	/**
	 * An array of group objects, which contain information about employee groups. Each group can differ in terms of < PairIdName > permissions, 
	 * and there might be more than one assigned to array the employee.
	 */
	groups: any[];

	/**
	 * ID of employee.
	 */
	id: number;

	/**
	 * The last name of the employee. Customizable in Master Data / Employee.
	 */
	lastName: string;

	/**
	 * The pin of the current employee, used for logging in. Customizable in Master Data / Employee. Type – integer. Unique value.
	 */
	loginPin?: number;

	/**
	 * The staff number for the identification purposes. Customizable in Master Data / Employee.
	 */
	number?: string;

	/**
	 * The password of the employee. Can be used alternatively to PIN. Type - string. Customizable in Master Data / Employee.
	 */
	password?: string;

	safe?: boolean;

}

interface ls_order {
	/**
	 * the table the order will be made on. For deliveries, this should be configured on the Cloud
	 */
	tableId: number;

	/**
	 * either one of them can be provided, or both (they have to match), or none (pick any free party)
	 */
	party: {

		id?: number;

		/**
		 * if provided, this should be a unique string (see App support notes for 2.42)
		 */
		name?: string;
	};

	waiterId: number;

	/**
	 * optional uuid4 string, see Avoiding problems with retransmissions
	 */
	operationUuid?: string;

	sales: {

		itemSku: number;

		/**
		 * “true” for deliveries, “false” for in-house self-orders
		 */
		isToGoFlag: boolean;

		/**
		 * the price should be multiplied by 1000 (2 should be 2000) (see App support notes for 2.42)
		 */
		quantity: number;

		/**
		 * the price should be multiplied by 1000 (2€ should be 2000); required only for free price articles, otherwise optional (see App support notes for 2.42)
		 */
		regularUnitPrice?: number;

		/**
		 * required only for free text articles, otherwise optional; max allowed length: 60 chars
		 */
		itemName?: string;

		/**
		 * required only for free text articles, otherwise optional; max allowed length: 60 chars
		 */
		shortItemName?: string;

		/**
		 * array of dictionaries (recursive structure, one level deep)
		 */
		constraints?: any[];
	}[];

	/**
	 * see Attachment subTypeCode options
	 */
	attachments?: any[];
}

const SPLIT_PAYMENT_ID = "SPLIT";

//#endregion

@Injectable({ providedIn: "root" })
export class LightspeedService implements CheckoutSystem {

	/*
  TODO:
  - Which WaiterId to use? From  checkoutSystemApi collection? currently using first waiter available
  - How to get the tableID? From checkoutSystemApi collection?
  - What to do with ToGo-flag -> Done /Axel
  */

	private gastroId: string;
	private gastroURL = "";
	public hasMobilePayment = false;
	public partyId:number;

	private active: Promise<boolean>;

	//Attributes to save the ls_categories and the categories mapped to split categories
	private categories: Category[] = [];
	private ls_articleGroups: ls_articleGroup[] = [];

	//Attributes to save the ls_articles, the articles mapped to split products
	private products: Product[] = [];
	private ls_articles: ls_article[] = [];
	private productMap: number[] = [];

	//ls_articleTypes
	private ls_articleTypes: ls_articleType[] = [];

	//paymentMethod data
	private ls_paymentMethods: ls_paymentMethod[] = [];
	private paymentMethods: PaymentMethod[] = [];
	private ls_paymentMethodTypes: ls_paymentMethodType[] = [];

	//employees
	private employees: ls_employee[] = [];
	private employeeId = 0;
	private hasSplitPay = false;
	private splitPayId = 0;

	constructor(
		public afs: AngularFirestore,
		private sessionDataService: SessionDataService,
		private userService: UserService,
		private deviceService: DeviceService,
	) {
		this.active = new Promise((resolve, reject) => {
			const gastroIdSubscription = this.sessionDataService.$gastroId.subscribe(id => {
				if (id !== undefined && id !== null && id !== this.gastroId) {
					this.gastroId = id;
					this.afs
						.collection("gastro")
						.doc(id)
						.collection("checkoutSystemApi")
						.doc("lightSpeed")
						.snapshotChanges()
						.subscribe(async gastroDoc => {
							const data: any = gastroDoc.payload.data();
							if (data?.lsURL !== undefined && data?.active === true) {
								this.gastroURL = data.lsURL;
								this.employeeId = data.employeeId;
								this.hasSplitPay = data.hasSplitPay;
								if (this.hasSplitPay === true) {
									this.splitPayId = data.splitPayId;
								}
								await this.init();
								resolve(true);
							} else {
								resolve(false);
							}
							gastroIdSubscription.unsubscribe();
						});
				}
			});
		});


	}

	private async init() {
		const response: any = await this.fetchLightSpeedData();
		this.initCategories(response.articleGroups);
		this.ls_articleTypes = response.articleTypes;
		this.initProducts(response.articles);
		this.ls_paymentMethodTypes = response.paymentMethodTypes;
		this.initPaymentMethods(response.paymentMethods);
		this.employees = response.employees;
	}

	private async fetchLightSpeedData() {
		const body = {
			"gastroURL": this.gastroURL,
			"gastroId": this.gastroId,
		};

		return await $.post(`${environment.functionsUrl}fetchLSData`, body);
	}

	private initCategories(articleGroups: ls_articleGroup[]) {
		this.categories = [];
		this.ls_articleGroups = [];
		articleGroups.forEach((ls_category: ls_articleGroup) => {
			this.ls_articleGroups.push(ls_category);
			const newCategory: Category = {
				description: "",
				id: ls_category.id,
				inhouseVisible: true,
				isFood: true,
				name: ls_category.name,
				outerhouseVisible: true,
			};
			this.categories.push(newCategory);
		});
	}

	private initProducts(articles: ls_article[]) {
		this.products = [];
		this.ls_articles = [];
		articles.forEach((article: ls_article) => {

			if (article.active === false) {
				return;
			}

			this.ls_articles.push(article);

			let articleType = this.ls_articleTypes.find(elem => article.articleType.id === elem.id);
			if (articleType === undefined) {
				articleType = this.ls_articleTypes.find(elem => article.articleType.name === elem.name);
			}

			//This article is only used within extras
			if (articleType.constraintOnly === true) {
				return;
			}

			if (articleType.info === true) {
				return;
			}

			const newProduct: Product = {
				categoryId: article.articleGroup.id,
				description: article.description !== undefined ? article.description : "",
				extraId: "",
				id: this.products.length,
				img: "",
				inhousePrice: article.price !== undefined ? article.price : 0,
				inhouseTax: 0,
				inhouseVisible: true,
				isFood: true,
				kind: 0,
				name: article.name,
				offer: false,
				opt: false,
				outerhousePrice: article.price !== undefined ? article.price : 0,
				outerhouseTax: 0,
				outerhouseVisible: true,
			};

			//This article is involved has extras
			//Use the articleid as extra id
			if (article.mode === "CONSTRAINTS") {
				newProduct.opt = true;
				newProduct.extraId = newProduct.id.toString();
			}

			this.productMap.push(this.ls_articles.length - 1);
			this.products.push(newProduct);
		});
	}

	private initPaymentMethods(paymentMethods: ls_paymentMethod[]) {
		this.ls_paymentMethods = [];
		this.paymentMethods = [];
		paymentMethods.forEach((method: ls_paymentMethod) => {
			this.ls_paymentMethods.push(method);

			//Payment method is not active
			if (method.active === false) {
				return;
			}

			const paymentMethodType = this.ls_paymentMethodTypes.find(elem => {
				if (method.type === undefined) {
					return false;
				}
				return elem.id === method.type.id;
			});

			//Reactivate if we got the payment api
			// this.hasMobilePayment = this.hasSplitPayment(paymentMethods);

			this.hasMobilePayment = this.hasSplitPay;

			const newPaymentMethod: PaymentMethod = {
				inhouse: true,
				name: method.name,
				// option: '(paymentMethodType !== undefined && paymentMethodType.name !== undefined) ? paymentMethodType.name : method.name',
				option: "bar",
				outerhouse: true,
			};
			this.paymentMethods.push(newPaymentMethod);
		});
	}

	/**
	 * searches through all paymentMethods from lightspeed for a SPLIT member
	 * if it contains it, it enables the gastro for mobile payment through split
	 * @param paymentMethods all paymentmetods from ls
	 * @return true if it contians SPLIT - false if not
	 */
	private hasSplitPayment(paymentMethods: ls_paymentMethod[]): boolean {
		let ret = false;
		const paymentMethod = this.filterForSplitPayment(paymentMethods);
		if (paymentMethod !== false) {
			ret = true;
		}
		return ret;
	}

	/**
	 * function to automatically fetch the split payment method
	 * @param paymentMethods 
	 * @returns 
	 */
	filterForSplitPayment(paymentMethods: ls_paymentMethod[]): ls_paymentMethod | false {
		let ret: ls_paymentMethod | boolean = false;
		for (const m of paymentMethods) {
			if (m.active === true && m.name === SPLIT_PAYMENT_ID) {
				ret = m;
			}
		}
		return ret;
	}

	private getLsArticleToSplitId(id: number): ls_article {
		return this.ls_articles[this.productMap[id]];
	}

	apiName(): string {
		return "lightspeed";
	}

	async isActiveAndEnabled(): Promise<boolean> {
		return this.active;
	}

	getCategories(): Category[] {
		return this.categories;
	}

	getProducts(): Product[] {
		return this.products;
	}

	getPaymentMethods(): PaymentMethod[] {
		return this.paymentMethods;
	}

	/**
	 * Returns an Extra in the split format to a given extraId in the split-format
	 * @param extraId refers to the product id in this case
	 * @returns an extra
	 */
	getExtra(extraId: string): Extra {
		//The return value
		const extra: Extra = {
			description: "",
			extraCategories: [],
			id: "",
			img: "",
		};

		//Get the article for which an extra is required
		// const article: ls_article = this.ls_articles[this.productMap[extraId]]
		const article: ls_article = this.getLsArticleToSplitId(parseInt(extraId));

		//No article was found
		if (article === undefined) {
			return extra;
		}

		//extraData is not defined on article
		if (article.constraints === undefined) {
			return extra;
		}

		article.constraints.steps.forEach((step: ls_constraintStep, index: number) => {
			//Create a new extraCategory for each step
			const extraCategory: ExtraCategory = {
				description: "",
				extraItems: [],
				kind: (step.multipleSelects === true || step.optional === true) ? 1 : 0,
				name: step.name !== undefined ? step.name : (`Schritt: ${(index + 1).toString()}`),
			};
			//Category can be skipped but allows only one selection 
			if (step.optional === true && step.multipleSelects !== true) {
				extraCategory.maxCount = 1;
			}

			step.articles.forEach((article) => {
				const ls_article: ls_article = this.ls_articles.find(elem => elem.name === article.name);
				//No related article found
				if (ls_article === undefined) {
					return;
				}

				//Create a new extraItem
				const extraItem: ExtraItem = {
					description: ls_article.description !== undefined ? ls_article.description : "",
					inhousePrice: ls_article.price,
					inhouseTax: 0,
					inhouseVisible: ls_article.active !== false ? true : false,
					isFood: true,
					name: ls_article.name,
					outerhousePrice: ls_article.price,
					outerhouseTax: 0,
					outerhouseVisible: ls_article.active !== false ? true : false,
				};
				extraCategory.extraItems.push(extraItem);
			});
			extra.extraCategories.push(extraCategory);
		});
		return extra;
	}

	async sendOrder(order: Order): Promise<[boolean, any]> {
		const table = this.sessionDataService.$tableNr;
		const attachments = this.getAttachments();
		this.partyId = Math.floor(Math.random() * 1000);
		const ls_order: ls_order = {
			attachments: attachments,
			party: { id: this.partyId },
			sales: [],
			tableId: table, //TODO Must be changed to specific table. 
			waiterId: this.employeeId, //TODO Must be changed according to right waiter. 
		};

		order.items.forEach((item: any) => {
			const ls_article = this.getLsArticleToSplitId(item.addData.sku);

			const ls_item = {
				constraints: [],
				isToGoFlag: order.pickup,
				itemSku: item.addData !== undefined ? item.addData.sku : 0,
				quantity: item.count * 1000,
			};

			//Add any selected extras

			if (item.sorted === true) {
				if (item.extras != undefined) {
					item.extras.forEach((cat: any) => {
						cat.extra.forEach((item: any) => {

							//Skip this item if it is not selected
							switch (cat.kind) {
							case 0: {
								if (item.selected !== true) {
									return;
								}

								break;
							}
							case 1: {
								if (item.selected !== true) {
									return;
								}
								break;
							}
							case 2: {
								if (item.count === undefined || item.count === 0) {
									return;
								}
								break;
							}
							default: {
								return;
							}
							}

							//At this point item is selected either by selected(checkbox/dropdown) or count > 0(plusMinus)
							//Find the related ls article by name
							const found = this.ls_articles.find(elem => elem.name === item.name);
							if (found === undefined || found.sku === undefined) {
								return;
							}

							//add an extra to the order
							const extra = {
								itemSku: found.sku,
								isToGoFlag: false,
								quantity: (item.count === undefined ? 1 : item.count) * 1000,
							};
							ls_item.constraints.push(extra);
						});
					});
				}
			} else {
				if (item.checkboxen !== undefined) {
					item.checkboxen.forEach((checkbox: any) => {
						checkbox.extra.forEach((item: any) => {
							//The item is not selected
							if (item.selected !== true) {
								return;
							}

							//Find the related ls article by name
							const found = this.ls_articles.find(elem => elem.name === item.name);
							if (found === undefined || found.sku === undefined) {
								return;
							}

							//add an extra to the order
							const extra = {
								itemSku: found.sku,
								isToGoFlag: false,
								quantity: 1 * 1000,
							};
							ls_item.constraints.push(extra);
						});
					});
				}
				if (item.dropdown !== undefined) {

					item.dropdown.forEach((dropdown: any) => {
						dropdown.extra.forEach((item: any) => {
							//The item is not selected
							if (item.selected !== true) {
								return;
							}

							//Find the related ls article by name
							const found = this.ls_articles.find(elem => elem.name === item.name);
							if (found === undefined || found.sku === undefined) {
								return;
							}

							//add an extra to the order
							const extra = {
								itemSku: found.sku,
								isToGoFlag: false,
								quantity: 1 * 1000,
							};
							ls_item.constraints.push(extra);
						});
					});
				}
			}

			ls_order.sales.push(ls_item);
		});

		const body = {
			"gastroId": this.gastroId,
			"gastroURL": this.gastroURL,
			"order": JSON.stringify(ls_order),
		};

		return new Promise<[boolean, any]>(async(resolve, reject) => {
			$.post(`${environment.functionsUrl}/sendOrderLS`, body)
				.done((data: any) => {
					resolve([true, undefined]);
				})
				.fail((data: any) => {
					resolve([false, undefined]);
				});
		});
	}

	sendPayment(amount: number, tip = 0) {
		//Reachtivate if we got ther payent api
		//const paymentMethod = this.filterForSplitPayment(this.ls_paymentMethods) as ls_paymentMethod;
		const ls_payment = {
			party: { id: this.partyId },
			payment: {
				appliedToTransactionAmount: Number.parseInt(Number(amount * 1000).toFixed(0)),
				tenderId: this.splitPayId,
				tipAmount: tip * 1000,
			},
			tableId: this.sessionDataService.$tableNr,
			waiterId: this.employeeId,
		};
		const body = {
			"gastroId": this.gastroId,
			"gastroURL": this.gastroURL,
			"payment": JSON.stringify(ls_payment),
		};

		return new Promise<boolean>(async(resolve, reject) => {
			$.post(`${environment.functionsUrl}/sendPaymentLS`, body)
				.done((data: any) => {
					resolve(true);
				})
				.fail((data: any) => {
					resolve(false);
				});
		});
	}


	getMobilePaymentSplitObject(): PaymentMethod {
		//Reactivate if we get the payment API
		// const paymentMethod = this.filterForSplitPayment(this.ls_paymentMethods);

		if (this.hasSplitPay === true) {
			return {
				inhouse: true,
				name: "SPLIT",
				option: "SPLIT",
				outerhouse: true,
			};
		} else {
			return {
				inhouse: true,
				name: "",
				option: "",
				outerhouse: true,
			};
		}
	}

	getAttachments() {
		const ret = [];
		if (this.sessionDataService.$isPickUp) {
			ret.push({
				"subTypeCode": "01",
				"blob": {
					"key": "pickup_informations",
					"type": "lines",
					"payload": [
						`Abholzeit: ${this.sessionDataService.$pickUpTime}`,
						`Tel.: ${this.userService.user.mobileNr}`,
						`Name: ${this.sessionDataService.$nickname}`,
					],
				},
			});
		} else if (this.sessionDataService.$isDelivery) {
			ret.push({
				"subTypeCode": "01",
				"blob": {
					"key": "pickup_informations",
					"type": "lines",
					"payload": [
						`Lieferzeit: ${this.sessionDataService.$deliveryTime}`,
						`Tel.: ${this.userService.user.mobileNr}`,
						`Name: ${this.userService.user.name}`,
						`Addresse: ${this.userService.user.address.street} ${this.userService.user.address.houseNr}`,
						`          ${this.userService.user.address.PLZ} ${this.userService.user.address.city}`,
					],
				},
			});
		} else {
			ret.push({
				"subTypeCode": "01",
				"blob": {
					"key": "inhouse_informations",
					"type": "lines",
					"payload": [`Nickname: ${this.sessionDataService.$nickname}`],
				},
			});
		}
		return ret;
	}

}

import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Platform, NavController, AlertController } from "@ionic/angular";
import "firebase/compat/analytics";
import "firebase/compat/performance";
import { environment } from "../environments/environment";
import { Storage } from "@ionic/storage";
import { AppRoutingPreloaderService } from "./app-routing-preloader.service";
import { slideInAnimation } from "./route-animation";
import { CartService } from "./services/cart.service";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { ConnectionService } from "ngx-connection-service";
import { UpdatesService } from "./update.service";
import { ScriptLoadingService } from "./services/script-loading.service";
import localeDe from "@angular/common/locales/de";
import firebase from "firebase/compat/app";
import { registerLocaleData } from "@angular/common";
import { UserService } from "./services/user.service";
import { SessionDataService } from "./services/session-data.service";
import { OrderService } from "./services/order.service";
import { PaymentService } from "./services/payment.service";
import { GastroService } from "./services/gastro.service";
import { MenuService } from "./services/menu.service";
import { filter } from "rxjs/operators";
import { HistoryService } from "./services/history.service";
@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	animations: [slideInAnimation],
})
export class AppComponent {
	wizard: AppComponent;
	constructor(
		private userService: UserService,
		private platform: Platform,
		private afAuth: AngularFireAuth,
		private storage: Storage,
		private nav: NavController,
		private routingService: AppRoutingPreloaderService,
		private cartService: CartService,
		private db: AngularFirestore,
		private connectionService: ConnectionService,
		private alertController: AlertController,
		private _updates: UpdatesService,
		private scriptLoadingService: ScriptLoadingService,
		public sessionDataService: SessionDataService,
		public orderService: OrderService,
		private paymentService: PaymentService,
		public gastroService: GastroService,
		public menuService: MenuService,
		public historyService: HistoryService,
	) {
		this.initializeApp();
	}

	public profilePath = "login";
	public checkInPath = "qrcode-scanner";
	public checkOutPath = "home2";
	public actualPage = 1;
	public lastPage = 1;
	public compatible = true;
	status = "ONLINE";
	isConnected = true;
	private paypalLoaded = false;
	private dibsLoaded = false;

	async initializeApp() {
		this.connectionService.monitor().subscribe(isConnected => {
			this.isConnected = isConnected.hasNetworkConnection;
			if (this.isConnected) {
				this.status = "ONLINE";
			} else {
				this.status = "OFFLINE";
			}
			console.log(this.status);
		});
		// const perf = firebase.performance();
	}

	loadStripeWhenAvailable(name) {
		const interval = 10; // ms
		window.setTimeout(() => {
			if (window[name]) {
				this.paymentService.initStripe();
			} else {
				this.loadStripeWhenAvailable(name);
			}
		}, interval);
	}

	ngOnInit() {
		if (!this.paypalLoaded) {
			this.paymentService.registerScript((payPal: any) => {
				this.paypalLoaded = true;
			});
		}
		if (!this.dibsLoaded) {
			this.registerDibs((dibs: any) => {
				this.dibsLoaded = true;
			});
		}
		this.loadStripeWhenAvailable("Stripe");
		registerLocaleData(localeDe, "de");
		const analytics = firebase.analytics();
		analytics.logEvent("enter");
		// listen to the service worker promise in index.html to see if there has been a new update.
		// condition: the service-worker.js needs to have some kind of change - e.g. increment CACHE_VERSION.
		this._updates.SubscribeForUpdates();

		this.afAuth.authState.subscribe(function(user) {
			console.log(user);
			if (user) {
				this.profilePath = "profile";
			} else {
				this.profilePath = "login";
			}
		}.bind(this));

		this.storage.get("tableID").then((val) => {
			console.log(val);
			if (val) {
				this.checkInPath = "restaurant-page";
				this.checkOutPath = "checkout";
			} else {
				this.checkInPath = "qrcode-scanner";
				this.checkOutPath = "home2";
			}
		});
	}

	registerDibs(loaded: (dibsApi: any) => void): void {
		this.scriptLoadingService.registerScript(environment.dibsCheckoutUrl, "Dibs", loaded);
	}
}

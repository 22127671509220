<div class="modal-container">

  <img class="card-img" src="{{imgUrl}}"/>

  <div class="text-box">
    <div class="title">{{header}}</div>
    <div>
      <p *ngFor="let paragraph of paragraphs" class="text">
        {{paragraph}}
      </p>
    </div>
  </div>
  <div class="button-container">
    <ion-button (click)="buttonClickHandler()">
      {{button}}
    </ion-button>
  </div>
</div>


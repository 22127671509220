import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { TableContentPageRoutingModule } from "./table-content-routing.module";

import { TableContentPage } from "./table-content.page";
import { ArticleComponent } from "./components/article/article.component";
import { ComponentsModule } from "src/app/components/components.module";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		TableContentPageRoutingModule,
		ComponentsModule,
	],
	declarations: [
		TableContentPage,
		ArticleComponent,
	],
})
export class TableContentPageModule {}

import { Component, OnInit, ViewChild } from "@angular/core";
import { IonInput, ModalController } from "@ionic/angular";
import { PayAtTableOrderService } from "src/app/PayAtTable/order/pay-at-table-order.service";

@Component({
	selector: "app-tipping-modal",
	templateUrl: "./tipping-modal.page.html",
	styleUrls: ["./tipping-modal.page.scss"],
})
export class TippingModalPage implements OnInit {

	@ViewChild(IonInput)
	public tipInput: IonInput;

	private closeModalTimeOut: NodeJS.Timeout;

	constructor(
		public payAtTableOrderService: PayAtTableOrderService,
		public modalCtrl: ModalController,
	) { }

	public customTipInput = {
		valueAsNumber: 0.00,
		valueAsString: "0.00",
	};

	public tippingComplete = false;

	ngOnInit() {
	}

	ionViewDidEnter() {
		this.tipInput.setFocus();
	}

	/**
	 * formats the tip input in order to make more usable for the user. Its right to left starting with the smallest decimal
	 * @param e event of the input
	 * @param separator type of separator, either . or ,
	 * @param decimals how many decimals do you want
	 */

	customTipInputFormater(e: any, separator = ".", decimals = 2) {
		const a: any = this.tipInput.value.toString().split("");

		let ns = "";
		a.forEach((c: any) => { if (!isNaN(c)) { ns = ns + c; } });
		ns = parseInt(ns).toString();
		if (ns.length < (decimals + 1)) { ns = ("0".repeat(decimals + 1) + ns); ns = ns.slice((decimals + 1) * -1); }
		const ans = ns.split("");
		let r = "";
		for (let i = 0; i < ans.length; i++) {
			if (i == ans.length - decimals) {
				r = r + separator + ans[i];
			} else { r = r + ans[i]; }
		}
		if (r === "N.aN") {
			r = "0.00";
		}
		this.customTipInput = {
			valueAsNumber: parseFloat(r),
			valueAsString: r,
		};
	}

	/**
	 * this functions prevents the user of inputting non-digit values into the custom tip input
	 * @param e keypress event
	 * @returns wheter the charcode of the input is a digit or not
	 */

	onlyDigits(e: any) {
		const code = e.charCode;
		return (code >= 48 && code <= 57);
	}

	/**
 * Confirms the current input by dismissing the modal with the custom tip input.
 * 
 * @returns {Promise<Object>} - Returns a promise that resolves when the modal is dismissed, 
 * providing the custom tip input and the role of "confirm".
 * 
 * @description
 * This function calls `dismiss` on the `modalCtrl` with the current 
 * `customTipInput` and the role of "confirm". 
 * 
 * @example
 * async function testConfirm() {
 *   const result = await confirm();
 *   // result would be { value: customTipInput, role: 'confirm' }
 * }
 */

	confirm() {
		this.modalCtrl.dismiss(this.customTipInput, "confirm");
	}

	/**
	 * Dismisses the modal with no data and the role of "cancel".
	 */
	dismiss() {
		this.modalCtrl.dismiss(null, "cancel");
	}

	/**
 * Plays a "thank you" animation and confirms the input if the tip value is non-zero.
 * 
 * @description
 * This function checks if the `customTipInput`'s `valueAsNumber` is not zero. 
 * If it's not zero, it sets `tippingComplete` to `true` and waits for 3 seconds 
 * before calling `confirm`. If the tip value is zero, it immediately calls `confirm`.
 * The function is typically used to show a visual confirmation of a non-zero tip 
 * before finalizing the input.
 * 
 * @example
 * playThanksAnimation();
 * // Will trigger the animation and confirm if the tip is non-zero.
 */

	async playThanksAnimation() {
		if (this.customTipInput.valueAsNumber !== 0.00) {
			this.tippingComplete = true;
			this.closeModalTimeOut = setTimeout(async() => {
				this.confirm();
			}, 3000);
		} else {
			this.confirm();
		}

	}

	ionViewWillLeave() {
		if (this.closeModalTimeOut) {
			clearTimeout(this.closeModalTimeOut);
		}
	}
}

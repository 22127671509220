import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { StartOfInteractionPageRoutingModule } from "./start-of-interaction-routing.module";

import { StartOfInteractionPage } from "./start-of-interaction.page";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		StartOfInteractionPageRoutingModule,
	],
	declarations: [StartOfInteractionPage],
})
export class StartOfInteractionPageModule {}

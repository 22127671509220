

<div 
*ngIf="this.tippingComplete === false"
class="close-button-container">
	<button class="ion-align-self-end" (click)="dismiss()">
        <div>
			<img src="assets/icons/orderbird-cancel.svg">
		</div>
	</button>
</div>
<ion-row class="tipping-modal-container" *ngIf="this.tippingComplete == false">
    <div class="tipping-question">
      Wie viel Trinkgeld möchtest Du geben?
    </div>
    <div class="tipping-input-box">
      <ion-input 
	  class="tipping-input-text"
	  (keypress)="this.onlyDigits($event)"
	  min="0"
	  inputmode="decimal"
	  type="tel"
	  value="0.00"
	  placeholder="{{0.00| currency: 'EUR':'symbol-narrow':'1.2-2':'de'}}"
	  [ngModel]="customTipInput.valueAsString "
	  (ionInput)="this.customTipInputFormater($event)"
	  ></ion-input>
    </div>
    <button class="tipping-done" (click)="playThanksAnimation()">Fertig!</button>
  
</ion-row>

<ion-row *ngIf="this.tippingComplete == true">
  <ion-row style="text-align:center; width: 100%;">
    <div class="tipping-thanks">
      Danke!
    </div>

  </ion-row >
  
  
  <ion-row style="justify-content: center; width: 100%;">
    <div class="tipping-heart"> 
      <img src="../../../../../assets/icon/❤️heart.png" />
    </div>
  </ion-row>
</ion-row>



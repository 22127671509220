import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";

@Component({
	selector: "app-show-more",
	templateUrl: "./show-more.component.html",
	styleUrls: ["./show-more.component.scss"],
})
export class ShowMoreComponent implements OnInit {

	@Input() items: any[] = [];
	@Input() limit = 2;
	@ContentChild(TemplateRef) itemTemplate: TemplateRef<unknown>;

	visibleItems: any[] = [];
	hiddenItems: any[] = [];
	showToggle = false;

	@Input()
		showAllItems = false;
	@Output()
		showAllItemsChange = new EventEmitter<boolean>();
  
	constructor() { }

	ngOnInit() {}

	ngOnChanges() {
		this.updateVisibleItems();
	}

	updateVisibleItems() {
		this.visibleItems = this.items.slice(0, this.showAllItems ? this.items.length : this.limit);
		this.hiddenItems = this.items.slice(this.limit);

		if (this.hiddenItems.length === 1) {
			this.visibleItems = this.visibleItems.concat(this.hiddenItems);
			this.hiddenItems = [];
		}

		this.showToggle = this.hiddenItems.length > 0;
	}

	toggleItems() {
		event.stopPropagation();
		this.showAllItems = !this.showAllItems;
		this.showAllItemsChange.emit(this.showAllItems);
		this.updateVisibleItems();
	}
}

import { Injectable } from "@angular/core";
import { DeliverectService } from "./deliverect/deliverect.service";
import { HelloTessServiceService } from "./helloTess/hello-tess-service.service";
import { LightspeedService } from "./lightspeed/lightspeed.service";
import { Order, Category, PaymentMethod, Product, Extra } from "./../util.service";
import { OrderbirdService } from "./orderbird/orderbird.service";

export interface CheckoutSystem {

	/**
	 * Return APIs name 
	 * */
	apiName(): string;
	/**
	 * Async function to determine whether this api is active. 
	 * When it is actice it is assured that t has been initialized and that all other methods of the interface CheckoutSystem return valid values
	 */
	isActiveAndEnabled(): Promise<boolean>;

	/**
	 * Returns all Categories of the api in split-format
	 */
	getCategories(): Category[];

	/**
	 * Returns all Products of the api in split-format
	 */
	getProducts(): Product[];

	/**
	 * Returns an Extra in the split format to a given extraId in the split-format
	 * @param extraId the extraId which is saved in the product
	 */
	getExtra(extraId: string): Extra;

	/**
	 * Returns the paymentmethods which are supported by the api
	 */
	getPaymentMethods(): PaymentMethod[];

	/**
	 * Takes a cart and sends an order to the checkout-system.
	 * @param splitCart the cart in split-format
	 */
	sendOrder(splitCart: Order): Promise<[boolean, any]>;

	/**
	 * indicates if POS supports mobile payment
	 */
	hasMobilePayment: boolean;

	/**
	 * returns the corresponding split payment object
	 */
	getMobilePaymentSplitObject(): PaymentMethod;

	/**
	 * sends a payment to the pos
	 */
	sendPayment(amount: number, partyId?: number, tip?: number);
}

@Injectable({ providedIn: "root" })
export class CheckoutSystemApiService {

	private apis: CheckoutSystem[] = [];
	public activeCheckoutSystemName;

	constructor(
		deliverectService: DeliverectService,
		helloTessService: HelloTessServiceService,
		lightspeedService: LightspeedService,
		orderbirdService: OrderbirdService,
	) {
		this.apis.push(deliverectService);
		this.apis.push(helloTessService);
		this.apis.push(lightspeedService);
		this.apis.push(orderbirdService);

		this.getActiveApi().then((api) => api
			? (this.activeCheckoutSystemName = api.apiName())
			: (this.activeCheckoutSystemName = "noapi"));
	}

	/**
	 * Checks if there are any apis which holds products, categories or paymentmethods and returns the first active api
	 * @returns a Promise to an active api or undefined if none was found
	 */
	public getActiveApi(): Promise<CheckoutSystem> {
		return new Promise<CheckoutSystem>(async(resolve, reject) => {
			for (const api of this.apis) {
				if (await api.isActiveAndEnabled()) {
					resolve(api);
					return;
				}
			}
			resolve(undefined);
		});
	}

}

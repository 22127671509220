import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { PaymentMethodModalPageRoutingModule } from "./payment-method-modal-routing.module";

import { PaymentMethodModalPage } from "./payment-method-modal.page";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		PaymentMethodModalPageRoutingModule,
	],
	declarations: [PaymentMethodModalPage],
})
export class PaymentMethodModalPageModule {}

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { getWeekNumber } from "src/shared/split-submodules/functions/functions";
import { profile } from "console";


interface KpiReport {
	profileOrdersAmount: number,
	profileOrdersValue: number,
	profileOrdersWithCouponAmount: number,
	profileOrdersWithCouponValue: number,
	profileOrdersWithoutCouponAmount: number,
	profileOrdersWithoutCouponValue: number,
	ordersAsExistingCustomer: number,
	ordersAsNewCustomer: number,
	ordersWithoutProfileAmount: number,
	ordersWithoutProfileValue: number,
	couponOrders: number,
	couponInvest: number,
	couponProfit: number,
	ordersWithoutCoupon: number,
	tipAmount: number,
	tipValue: number,
	ordersWithExtras: number,
	extrasCount: number,
	ordersWithoutExtras: number,
	productsCount: number,
}

@Injectable({ providedIn: "root" })
export class ReportService {

	constructor(private afs: AngularFirestore) { }

	//CLEANUP: ReportService
	async updateReportUserProfile(doc, userId) {
		//check if report exists, if it does not create one
		// if it does , get the info, increase the counters, set the info
		//do this for weekly, montly and overall
		// note: we use LastcartID to evoid incrementing data of the same cart ID
		const now = new Date();
		const kw = getWeekNumber(now);
		const year = now.getFullYear();

		const reportStringDaily = `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`;

		// add data to daily report--------------------
		await this.afs
			.collection("customer-profiles")
			.doc(userId)
			.collection("reports")
			.doc(reportStringDaily)
			.get()
			.subscribe(async(report) => {

				if (report.exists) {
					const reportData = report.data();

					if (isNaN(reportData.totalCost) || reportData.totalCost == undefined) {
						reportData.totalCost = 0;
					}
					reportData.totalCost = reportData.totalCost + doc.totalCost;

					if (reportData.lastCartID != doc.cartID) {
						if (doc.delivery) {
							reportData.delivery++;
						} else if (doc.pickup) {
							reportData.pickup++;
						} else {
							reportData.inhouse++;
						}
						reportData.gastroCounter = this.updateGastroCounter(doc, reportData.gastroCounter);
						reportData.lastCartID = doc.cartID;
					}
					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc(reportStringDaily)
						.set(reportData, { merge: true });
				} else {
					const reportData = {
						delivery: 0,
						gastroCounter: [{ id: doc.gastroID, counter: 1 }],
						inhouse: 0,
						lastCartID: doc.cartID,
						pickup: 0,
						totalCost: doc.totalCost,
					};
					if (doc.delivery) {
						reportData.delivery++;
					} else if (doc.pickup) {
						reportData.pickup++;
					} else {
						reportData.inhouse++;
					}

					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc(reportStringDaily)
						.set(reportData);
				}

			});

		//--------------------------------------------

		// add data to weekly report -----------------

		// make report-doc name

		const reportStringWeek = `${year}-KW-${kw}`;


		await this.afs
			.collection("customer-profiles")
			.doc(userId)
			.collection("reports")
			.doc(reportStringWeek)
			.get()
			.subscribe(async(report) => {

				if (report.exists) {
					const reportData = report.data();

					if (isNaN(reportData.totalCost) || reportData.totalCost == undefined) {
						reportData.totalCost = 0;
					}
					reportData.totalCost = reportData.totalCost + doc.totalCost;

					if (reportData.lastCartID != doc.cartID) {
						if (doc.delivery) {
							reportData.delivery++;
						} else if (doc.pickup) {
							reportData.pickup++;
						} else {
							reportData.inhouse++;
						}
						reportData.gastroCounter = this.updateGastroCounter(doc, reportData.gastroCounter);
						reportData.lastCartID = doc.cartID;
					}
					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc(reportStringWeek)
						.set(reportData, { merge: true });
				} else {
					const reportData = {
						delivery: 0,
						gastroCounter: [{ id: doc.gastroID, counter: 1 }],
						inhouse: 0,
						lastCartID: doc.cartID,
						pickup: 0,
						totalCost: doc.totalCost,
					};
					if (doc.delivery) {
						reportData.delivery++;
					} else if (doc.pickup) {
						reportData.pickup++;
					} else {
						reportData.inhouse++;
					}

					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc(reportStringWeek)
						.set(reportData);
				}

			});
		//----------------------------------------------
		// add data to monthly report -------------------
		const reportStringMonth = `${now.getFullYear()}-M-${now.getMonth() + 1}`;
		await this.afs
			.collection("customer-profiles")
			.doc(userId)
			.collection("reports")
			.doc(reportStringMonth)
			.get()
			.subscribe(async(report) => {

				if (report.exists) {
					const reportData = report.data();

					if (isNaN(reportData.totalCost) || reportData.totalCost == undefined) {
						reportData.totalCost = 0;
					}
					reportData.totalCost += doc.totalCost;

					if (reportData.lastCartID != doc.cartID) {
						if (doc.delivery) {
							reportData.delivery++;
						} else if (doc.pickup) {
							reportData.pickup++;
						} else {
							reportData.inhouse++;
						}
						reportData.gastroCounter = this.updateGastroCounter(doc, reportData.gastroCounter);
						reportData.lastCartID = doc.cartID;
					}

					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc(reportStringMonth)
						.set(reportData, { merge: true });
				} else {
					const reportData = {
						delivery: 0,
						gastroCounter: [{ id: doc.gastroID, counter: 1 }],
						inhouse: 0,
						lastCartID: doc.cartID,
						pickup: 0,
						totalCost: doc.totalCost,
					};
					if (doc.delivery) {
						reportData.delivery++;
					} else if (doc.pickup) {
						reportData.pickup++;
					} else {
						reportData.inhouse++;
					}
					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc(reportStringMonth)
						.set(reportData);
				}

			});

		//-----------------------------------------------
		//add data to overall report ------------------------

		await this.afs
			.collection("customer-profiles")
			.doc(userId)
			.collection("reports")
			.doc("overall")
			.get()
			.subscribe(async(report) => {
				if (report.exists) {
					const reportData = report.data();

					if (isNaN(reportData.totalCost) || reportData.totalCost == undefined) {
						reportData.totalCost = 0;
					}
					reportData.totalCost += doc.totalCost;

					if (reportData.lastCartID != doc.cartID) {
						if (doc.delivery) {
							reportData.delivery++;
						} else if (doc.pickup) {
							reportData.pickup++;
						} else {
							reportData.inhouse++;
						}
						reportData.gastroCounter = this.updateGastroCounter(doc, reportData.gastroCounter);
						reportData.lastCartID = doc.cartID;
					}

					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc("overall")
						.set(reportData, { merge: true });
				} else {
					const reportData = {
						delivery: 0,
						gastroCounter: [{ id: doc.gastroID, counter: 1 }],
						inhouse: 0,
						lastCartID: doc.cartID,
						pickup: 0,
						totalCost: doc.totalCost,
					};
					if (doc.delivery) {
						reportData.delivery++;
					} else if (doc.pickup) {
						reportData.pickup++;
					} else {
						reportData.inhouse++;
					}
					await this.afs
						.collection("customer-profiles")
						.doc(userId)
						.collection("reports")
						.doc("overall")
						.set(reportData);
				}

			});
	}

	//CLEANUP: GastroService
	private updateGastroCounter(doc, gastroCounter = []) {
		let found = false;
		if (gastroCounter.length > 0) {
			for (const gastroEntry of gastroCounter) {
				if (gastroEntry.id == doc.gastroID) {
					gastroEntry.counter++;
					found = true;
					break;
				}
			}
		}
		if (found == false) {
			gastroCounter.push({ id: doc.gastroID, counter: 1 });
		}

		return gastroCounter;
	}

	/**
	 * returns an array of string in the corresponding report format
	 * @returns 
	 */
	public createReportIds() {
		const reportIds: string[] = [];
		const now = new Date();
		reportIds.push("overall");
		reportIds.push(`${now.getFullYear().toString()}-M-${now.getMonth().toString()}`);
		reportIds.push(`${now.getFullYear().toString()}-KW-${getWeekNumber(now)}`);
		reportIds.push(`${now.getFullYear().toString()}-${
			 (now.getMonth() + 1).toString()
			 }-${now.getDate().toString()}`);

		return reportIds;
	}

	/**
	 * increases the counter of new registries on the global kpi 
	 */
	public increaseRegisterCounter() {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("global-kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then((doc) => {

					const data = doc.data();
					const newValue = data?.newProfileRegistrations !== undefined
						? (data.newProfileRegistrations + 1)
						: 1;
					transaction.set(ref, { newProfileRegistrations: newValue }, { merge: true });
					
				});
			});
		});
	}

	public incrementUsersFromPlatformOrDirectLinkinReports(gastroId: string, comesFromPlatform: boolean) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then((doc) => {

					const data = doc.data();
					if (comesFromPlatform) {
						const newValue = data?.clicksMarketplace !== undefined ? (data.clicksMarketplace + 1) : 1;
						transaction.set(ref, { clicksMarketplace: newValue }, { merge: true });
					} else {
						const newValue = data?.clicksDirect !== undefined ? (data.clicksDirect + 1) : 1;
						transaction.set(ref, { clicksDirect: newValue }, { merge: true });
					}
				});
			});
			//taken out beucause we dont write into the global kpi reports anymore

			// const refGlobal = this.afs.firestore.collection("global-kpi-reports").doc(id);
			// this.afs.firestore.runTransaction((transaction) => {
			// 	return transaction.get(refGlobal).then((doc) => {

			// 		const data = doc.data();
			// 		if (comesFromPlatform) {
			// 			const newValue = data?.clicksMarketplace !== undefined ? (data.clicksMarketplace + 1) : 1;
			// 			transaction.set(refGlobal, { clicksMarketplace: newValue }, { merge: true });
			// 		} else {
			// 			const newValue = data?.clicksDirect !== undefined ? (data.clicksDirect + 1) : 1;
			// 			transaction.set(refGlobal, { clicksDirect: newValue }, { merge: true });
			// 		}
			// 	});
			// });
		});

	}
	/**
	 * when a qrcode is scanned the corresponding digital menu scan kpi is increased
	 * @param gastroId 
	 */

	
	async loadPopDishes(gastroId, year, month) {
		const report = await this.afs.collection("gastro").doc(gastroId).collection("reports")
			.doc(`${year}-M-${month}`).get().toPromise();
		return report.data();
	}

	IncrementDigitalMenuScan(gastroId) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then((doc) => {

					const data = doc.data();

					const newValueScansDigitalMenu = data?.scansDigitalMenu !== undefined
					 ? (data.scansDigitalMenu + 1)
						: 1;
					transaction.set(ref, { scansDigitalMenu: newValueScansDigitalMenu }, { merge: true });

				});
				
			});

			//taken out beucause we dont write into the global kpi reports anymore

			// const refGlobal = this.afs.firestore.collection("global-kpi-reports").doc(id);
			// this.afs.firestore.runTransaction((transaction) => {
			// 	return transaction.get(refGlobal).then((doc) => {

			// 		const data = doc.data();

			// 		const newValueScansDigitalMenu = data?.scansDigitalMenu !== undefined
			// 		 ? (data.scansDigitalMenu + 1)
			// 			: 1;
			// 		transaction.set(refGlobal, { scansDigitalMenu: newValueScansDigitalMenu }, { merge: true });

			// 	});
				
			// });
		});
	}
	/**
	 * when a qrcode is scanned the corresponding inhouse scan kpi is increased
	 * @param gastroId 
	 */
	IncrementInhouseScan(gastroId) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then((doc) => {

					const data = doc.data();

					const newValueScansInhouse = data?.scansInhouse !== undefined ? (data.scansInhouse + 1) : 1;
					transaction.set(ref, { scansInhouse: newValueScansInhouse }, { merge: true });

				});
			});

			//taken out beucause we dont write into the global kpi reports anymore
			// const refGlobal = this.afs.firestore.collection("global-kpi-reports").doc(id);
			// this.afs.firestore.runTransaction((transaction) => {
			// 	return transaction.get(refGlobal).then((doc) => {

			// 		const data = doc.data();

			// 		const newValueScansInhouse = data?.scansInhouse !== undefined ? (data.scansInhouse + 1) : 1;
			// 		transaction.set(refGlobal, { scansInhouse: newValueScansInhouse }, { merge: true });

			// 	});
			// });
		});
	}
	/**
	 * when a qrcode is scanned the corresponding outer house scan kpi is increased
	 * @param gastroId 
	 */
	IncrementOuterhouseScan(gastroId) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then((doc) => {

					const data = doc.data();

					const newValueScansOuterhouse = data?.scansOuterhouse !== undefined
					 ? (data.scansOuterhouse + 1)
						: 1;
					transaction.set(ref, { scansOuterhouse: newValueScansOuterhouse }, { merge: true });

				});
			});

			//taken out beucause we dont write into the global kpi reports anymore
			// const refGlobal = this.afs.firestore.collection("global-kpi-reports").doc(id);
			// this.afs.firestore.runTransaction((transaction) => {
			// 	return transaction.get(refGlobal).then((doc) => {

			// 		const data = doc.data();

			// 		const newValueScansOuterhouse = data?.scansOuterhouse !== undefined
			// 		 ? (data.scansOuterhouse + 1)
			// 			: 1;
			// 		transaction.set(refGlobal, { scansOuterhouse: newValueScansOuterhouse }, { merge: true });

			// 	});
			// });
		});
	}

	/**
	 * is given an order , gastroID and profile data in order to update the kpi reports
	 * @param orderData 
	 * @param gastroId 
	 * @param userId 
	 * @param isLoggedIn 
	 */

	public async createKPIData(orderData: any, gastroId, userId, isLoggedIn) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			// const globalRef = this.afs.firestore.collection("global-kpi-reports").doc(id);
			this.runKpiTransaction(ref, isLoggedIn, orderData, userId);

			//taken out beucause we dont write into the global kpi reports anymore
			// this.runKpiTransaction(globalRef, isLoggedIn, orderData, userId);
		});
	}

	/**
	 * 
	 */
	// eslint-disable-next-line complexity
	static initKPIUpdateData(doc) {
		const zero = 0;
		const updateData:KpiReport = {
			couponInvest: doc.couponInvest === undefined ? zero : doc.couponInvest,
			couponOrders: doc.couponOrders === undefined ? zero : doc.couponOrders,
			couponProfit: doc.couponProfit === undefined ? zero : doc.couponProfit,
			extrasCount: doc.extrasCount === undefined ? zero : doc.extrasCount,
			ordersAsExistingCustomer: doc.ordersAsExistingCustomer === undefined ? zero : doc.ordersAsExistingCustomer,
			ordersAsNewCustomer: doc.ordersAsNewCustomer === undefined ? zero : doc.ordersAsNewCustomer,
			ordersWithExtras: doc.ordersWithExtras === undefined ? zero : doc.ordersWithExtras,
			ordersWithoutCoupon: doc.ordersWithoutCoupon === undefined ? zero : doc.ordersWithoutCoupon,
			ordersWithoutExtras: doc.ordersWithoutExtras === undefined ? zero : doc.ordersWithoutExtras,
			ordersWithoutProfileAmount:
			doc.ordersWithoutProfileAmount === undefined ? zero : doc.ordersWithoutProfileAmount,
			ordersWithoutProfileValue:
			doc.ordersWithoutProfileValue === undefined ? zero : doc.ordersWithoutProfileValue,
			productsCount: doc.productsCount === undefined ? zero : doc.productsCount,
			profileOrdersAmount: doc.profileOrdersAmount === undefined ? zero : doc.profileOrdersAmount,
			profileOrdersValue: doc.profileOrdersValue === undefined ? zero : doc.profileOrdersValue,
			profileOrdersWithCouponAmount:
			doc.profileOrdersWithCouponAmount === undefined ? zero : doc.profileOrdersWithCouponAmount,
			profileOrdersWithCouponValue:
			doc.profileOrdersWithCouponValue === undefined ? zero : doc.profileOrdersWithCouponValue,
			profileOrdersWithoutCouponAmount:
			doc.profileOrdersWithoutCouponAmount === undefined ? zero : doc.profileOrdersWithoutCouponAmount,
			profileOrdersWithoutCouponValue:
			doc.profileOrdersWithoutCouponValue === undefined ? zero : doc.profileOrdersWithoutCouponValue,
			tipAmount: doc.tipAmount === undefined ? zero : doc.tipAmount,
			tipValue: doc.tipValue === undefined ? zero : doc.tipValue,
		};
		return updateData;
	}

	/**
	 * when a review is completed the relevant kpi data is written under the given gastroId document
	 * @param gastroId 
	 * @param review 
	 */

	public addReviewKPI(gastroId: string, review: any) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then(async(doc) => {

					const data = doc.data();
					const newReviewAmount = data?.reviewAmount !== undefined
						? (data.reviewAmount + 1)
						: 1;
					transaction.set(ref, { reviewAmount: newReviewAmount }, { merge: true });
					const newReviewRating = data?.reviewRating !== undefined
						? (data.reviewRating + parseInt(review.rating))
						: parseInt(review.rating);
					transaction.set(ref, { reviewRating: newReviewRating }, { merge: true });


				});

			});
		});
	}
	/**
	 * when the button for a google maps review is pressed, it updates the kpi-report for the given gastroId
	 * @param gastroId 
	 */

	public addGoogleClickKPI(gastroId: string) {
		this.createReportIds().forEach((id: string) => {
			const ref = this.afs.firestore.collection("gastro").doc(gastroId).collection("kpi-reports").doc(id);
			this.afs.firestore.runTransaction((transaction) => {
				return transaction.get(ref).then(async(doc) => {

					const data = doc.data();
					const newGoogleClicks = data?.googleClicks !== undefined
						? (data.googleClicks + 1)
						: 1;
					transaction.set(ref, { googleClicks: newGoogleClicks }, { merge: true });


				});

			});
		});
	}

	/**
	 * returns whether or not the given order has a coupin applied or not
	 */

	public doesOrderHaveCoupon(orderData) {
		let hasCoupon = false;
		if (orderData.couponList.length > 0) {
			hasCoupon = true;
		}
		return hasCoupon;
	}

	/**
	 * returns the coupon discount of the given order
	 * @param orderData
	 * @returns 
	 */
	public getCouponInvest(orderData) {
		let couponInvest = 0;

		if (orderData.couponList.length > 0) {
			for (const coupon of orderData.couponList) {
				if (coupon.rewardType === "flatAmount") {
					couponInvest = couponInvest + coupon.rewardMagnitude;
				} else if (coupon.rewardType === "flatPercentage") {
					couponInvest = couponInvest + ((orderData.orderTotal * coupon.rewardMagnitude) / 100);
				}
			}
		}
		return couponInvest;
	}
	/**
	 * returns the order value of the given order without the discount
	 * @param orderData 
	 * @returns 
	 */
	public getCouponProfit(orderData) {
		let couponProfit = 0;
		couponProfit = orderData.orderTotalWithDiscount;
		return couponProfit;

	}
	/**
	 * returns the amount of tips given
	 * @param orderData 
	 * @returns 
	 */
	public getTips(orderData) {
		const tips = orderData.orderArray[0].tip;
		return tips;
	}
	/**
	 * returns the amount of products in the order
	 * @param orderData 
	 * @returns 
	 */
	public getProductCount(orderData) {
		let count = 0;
		for (const order of orderData.orderArray) {
			count = count + order.dish.count;
		}
		return count;
	}

	/**
	 * returns the amount of extras in the given order
	 * @param orderData 
	 * @returns 
	 */
	public getExtraCount(orderData) {
		let count = 0;
		
		for (const order of orderData.orderArray) {
			for (const extraCategory of order.dish.extras) {
				for (const extraSubCategory of extraCategory.extra) {
					if (extraSubCategory.selected === true) {
						count++;
					}
				}
			}
		}
		return count;
	}

	/**
	 * checks whether the profile is already in the customer list of the gastro
	 * @param orderData 
	 * @param userId 
	 * @returns 
	 */

	public async isUserCustomer(orderData, userId) {
		const oldCustomerInfo = await this.afs
			.collection("gastro")
			.doc(orderData.gastroId)
			.collection("customers")
			.doc(userId)
			.get()
			.toPromise();
		if (oldCustomerInfo.exists) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * this function updates the kpi reports with a transaction
	 * therefore it is safe to use in respect to overwrite data
	 * @param ref firebase transaction ref wich should be updated
	 * @param isLoggedIn boolean if user is logged in
	 * @param orderData data of the order send to backend
	 * @param userId id of the logged in user
	 */
	public runKpiTransaction(ref, isLoggedIn, orderData, userId) {
		this.afs.firestore.runTransaction((transaction) => {
			return transaction.get(ref).then(async(doc) => {
				const data = doc.data();
				const updateData:KpiReport = ReportService.initKPIUpdateData(data);
				//profile orders
				if (isLoggedIn) {
					updateData.profileOrdersAmount++;
					updateData.profileOrdersValue++;
					//profile order with coupon
					if (this.doesOrderHaveCoupon(orderData) === true) {
						updateData.profileOrdersWithCouponAmount++;
						updateData.profileOrdersWithCouponValue += orderData.orderTotalWithDiscount;
					} else {
						//profile orders without coupon
						updateData.profileOrdersWithoutCouponAmount++;
						updateData.profileOrdersWithoutCouponValue += orderData.orderTotalWithoutDiscounts;
					}
					if (await this.isUserCustomer(orderData, userId) == true) {
						updateData.ordersAsExistingCustomer++;
					} else {
						updateData.ordersAsNewCustomer++;
					}
				} else {
					updateData.ordersWithoutProfileAmount++;
					updateData.ordersWithoutProfileValue += orderData.orderTotalWithDiscount;
				}
				if (this.doesOrderHaveCoupon(orderData) == true) {
					updateData.couponOrders++;
					updateData.couponInvest += this.getCouponInvest(orderData);
					updateData.couponProfit += this.getCouponProfit(orderData);
				} else {
					updateData.ordersWithoutCoupon++;
				}
				if (this.getTips(orderData) > 0) {
					updateData.tipAmount++;
					updateData.tipValue += this.getTips(orderData);
				}
				if (this.getExtraCount(orderData) > 0) {
					updateData.ordersWithExtras++;
					updateData.extrasCount += this.getExtraCount(orderData);
				} else {
					updateData.ordersWithoutExtras++;
				}
				updateData.productsCount += this.getProductCount(orderData);
				transaction.set(ref, updateData, { merge: true });
			});
			
		});
	}
}


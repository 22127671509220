import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { SentryService } from "../../services/sentry.service";

@Injectable({
	providedIn: "root",
})


/**
 * Helper class to handle OB-errors
 */
export class ObErrorHelper {

	constructor(
		private alertController: AlertController,
		private sentryService: SentryService,
	) {

	}

	/**
	 * Displays an error alert with the provided message.
	 * @param message 
	 */
	async showErrorAlert(message: string) {
		const alert = await this.alertController.create({
			header: "Error",
			message: message,
			buttons: [
				{
					text: "OK!",
					cssClass: "primary",
				},
			],
		});
		await alert.present();
	}

	/**
	 * sends Error Report to Sentry
	 * @param message Error Message
	 * @param gastroId 
	 * @param functionName name of function the error occured in
	 */
	reportToSentry(message: string, gastroId?: string, functionName?: string) {
		if (gastroId !== undefined) {
			this.sentryService.setSentryTag("gastroID", gastroId);
		}
		if (functionName !== undefined) {
			this.sentryService.setSentryTag("OB_API_FUNCTION", functionName);
		}
		this.sentryService.captureError("OB-API ERROR", message);
	}

	/**
	 * Function to handle errors completely
	 * @param clientMessage client facing message
	 * @param detailedMessage message for sentry
	 * @param gastroId 
	 * @param functionName name of function the error occured in
	 */
	async handleError(clientMessage: string, detailedMessage: string, gastroId?: string, functionName?: string) {
		this.reportToSentry(detailedMessage, gastroId, functionName);
		await this.showErrorAlert(clientMessage);
	}
}
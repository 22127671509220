import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
import * as $ from "jquery";
import { CheckoutSystem } from "../checkout-system-api.service";
import { SessionDataService } from "../../session-data.service";
import { Category, Product, Extra, PaymentMethod, Order, UtilService } from "../../util.service";
import { CartService } from "../../cart.service";
import { PaymentService } from "../../payment.service";
import { GeoService } from "../../geo.service";
import { CouponService } from "../../coupon.service";
import { GastroService } from "../../gastro.service";
import { TableService } from "../../table.service";

//TODO: Cleanup, Comments, Tests
interface deliverect_order{
	channelOrderId: string;
	channelOrderDisplayId: string;
	by?: string;
	orderType: number;
	pickupTime?: string;
	esimatedPickupTime?: string;
	deliveryTime?: string;
	deliveryIsAsap?: boolean;
	courier?: string;
	customer?: deliverect_customer;
	deliveryAddress?: deliverect_deliveryAddress;
	orderIsAlreadyPaid: boolean;
	payment:deliverect_payment;
	note?: string;
	items?: deliverect_item[];
	decimalDigits?: number; //decimalDigits value always needs to be an integer with value 2 ("decimalDigits":2)
	numberOfCustomers?:number;
	deliveryCost?:number;
	serviceCharge?: number;
	discountTotal?: number;
	taxes?: deliverect_taxes[];
	table?: string; //Either the table ID obtained from GET POS Tables or name of the table is accepted
	validationId?: string; //ValidationId returned from the ValidateDelivery endpoint. The ID cannot be reused, and is valid for 10 minutes from generation.
}

interface deliverect_taxes{
	taxes?: number;
	name?: string;
	total?: number;
}

interface deliverect_item{
	plu: string;
	name: string;
	price: number;
	quantity: number;
	remark?: string;
	subitems?: deliverect_subitem[];
}

interface deliverect_subitem{
	plu?:string;
	name?:string;
	price?: number;
	quantity?: number;
	subitems?: deliverect_item[];
}

interface deliverect_customer{
	name?: string;
	companyName?: string;
	phoneNumber?: string;
	phoneNumberAccesscode?: string;
	email?: string;
	note?: string;
	tin?: string;
}

interface deliverect_payment{
	amount: number; //Ensure this is sent as an integer and not a float
	type: number;
}

interface deliverect_deliveryAddress{
	street?: string;
	streetNumber?: string;
	postalCode?: string;
	city?: string;
	extraAddressInfo?: string;
	coordinates?: deliverect_addressCoordiantes;
}

interface deliverect_addressCoordiantes{
	longitude?: number;
	latitude?: number;
}

@Injectable({
	providedIn: "root",
})
export class DeliverectService implements CheckoutSystem {
  
	private gastroId: string;
	private gastroURL = "";
	private active: Promise<boolean>;
	private gastro:any;
	private order:any;
	private products:any;
	private deliverectItems = [];
	private deliverectOrder:deliverect_order;
	private deliverectToken;
	public deliverectChannelOrderId;
	public deliverectChannelOrderDisplayCode;

	constructor(
private sessionDataService: SessionDataService,
				private afs: AngularFirestore,
				private utilService: UtilService,
				private paymentService: PaymentService,
				private cartService: CartService,
				private geoService: GeoService,
				private couponService: CouponService,
				private gastroService: GastroService,
				private tableService: TableService,
	) {
		this.active = new Promise((resolve, reject) => {
			const gastroIdSubscription = this.sessionDataService.$gastroId.subscribe(id => {
				if (id !== undefined && id !== null && id !== this.gastroId) {
					this.gastroId = id;
					this.afs
						.collection("gastro")
						.doc(id)
						.snapshotChanges()
						.subscribe(async gastroDoc => {
							this.gastro = gastroDoc.payload.data();
							if (this.gastro.deliverect != undefined && this.gastro.deliverect.status == "active") {
								this.gastroURL = this.gastro.lsURL;
								resolve(true);
							} else {
								resolve(false);
							}
							gastroIdSubscription.unsubscribe();
						});
				}
			});
		});

	}

	/*
	* @input: the cart of the order
	* gets token, initiates the items of the cart and the order
	*/
	private async init(split_cart:any) {
		const deliverectOrder = this.initDeliverectItems(split_cart);
		return this.initDeliverectOrder(deliverectOrder);
	}

	/*
	* Takes all Information of available and arranges it in the deliverect order format
	*/
	private initDeliverectOrder(deliverectItems) {
		let orderType = 3;
		let pickupTime = "";
		let deliveryTime = "";
		let deliveryIsAsap = false;
		let alreadyPaid = true;
		let payType = 0;
		let deliveryCost;
		let customerName = "";
		const tax = [];
		if (this.sessionDataService.$deliveryInformations != undefined
			&& this.sessionDataService.$deliveryInformations.name != undefined) {
			customerName = this.sessionDataService.$deliveryInformations.name;
		} else if (this.sessionDataService.$nickname !== undefined) {
			customerName = this.sessionDataService.$nickname;
		}
		for (let i = 0, len = this.cartService.$cart.length; i < len; i++) {
			 deliveryCost = i === 0
			 ? this.geoService.getDeliveryFee(this.getTotalBeforeDeliveryFee())
				: 0;
			 if ((this.gastro.hasDelivery && this.sessionDataService.$isDelivery)
			  || (this.gastro.hasPickup && this.sessionDataService.$isPickUp)) {
				tax.push({
					name: this.cartService.$cart[i].name,
					taxes: Math.round(this.cartService.$cart[i].outerhouseTax * 100),
				});
			 } else {
				tax.push({
					name: this.cartService.$cart[i].name,
					taxes: Math.round(this.cartService.$cart[i].inhouseTax * 100),
				});
			 }
		}
		if (this.paymentService.$payedLastOrder === false) {
			alreadyPaid = false;
			payType = 1;
		}
		if (this.gastro.hasDelivery && this.sessionDataService.$isDelivery) {
			orderType = 2;
			if (this.sessionDataService.$deliveryInformations.deliveryTime == "Sobald wie möglich") {
				deliveryIsAsap = true;
			}
			deliveryTime = this.convertTime(
				this.sessionDataService.$deliveryInformations.deliveryDate,
				 this.sessionDataService.$deliveryInformations.expectedDeliveryTime,
			);
			const pickupT = new Date();
			const edt = this.sessionDataService.$deliveryInformations.expectedDeliveryTime;

			//if it is daylight saving time subtract 1 hour from pickuptime due too strange deliverect behavior 
			pickupT.setHours(parseInt(`${edt[0]}${edt[1]}`));
			const offset = this.isDST(pickupT) ? 1 : 0;
			pickupT.setHours(parseInt(`${edt[0]}${edt[1]}`) - offset);
			pickupT.setMinutes(parseInt(`${edt[3]}${edt[4]}`));
			pickupT.setMinutes(pickupT.getMinutes() - 20);
			pickupTime = this.convertTime(
				this.sessionDataService.$deliveryInformations.deliveryDate,
				`${this.addZeroToTime(pickupT.getHours())}:${this.addZeroToTime(pickupT.getMinutes())}`,
			);
		} else if (this.gastro.hasPickup && this.sessionDataService.$isPickUp) {
			orderType = 1;
		}
		if (orderType == 1) {
			const pickupT = new Date();
			const edt = this.sessionDataService.$pickUpTime;
			//if it is daylight saving time subtract 1 hour from pickuptime due too strange deliverect behavior 
			pickupT.setHours(parseInt(`${edt[0]}${edt[1]}`));
			const offset = this.isDST(pickupT) ? 1 : 0;
			pickupT.setHours(parseInt(`${edt[0]}${edt[1]}`) - offset);
			pickupT.setMinutes(parseInt(`${edt[3]}${edt[4]}`));
			pickupTime = this.convertTime(
				this.sessionDataService.$pickupDate,
				`${this.addZeroToTime(pickupT.getHours())}:${this.addZeroToTime(pickupT.getMinutes())}`,
			);
			// pickupTime = this.convertTime(this.sessionDataService.$pickupDate, this.sessionDataService.$pickUpTime);
		}
		const deliverectOrder:deliverect_order = {
			by: "SPLIT", //TODO find it out
			channelOrderDisplayId: this.utilService.makeid(10),
			channelOrderId: this.utilService.makeid(20),
			courier: "dunno", //TODO needed?
			customer: {
				name: customerName,
			},
			decimalDigits: 2,
			deliveryCost: Math.round(deliveryCost * 100),
			discountTotal: this.calcDiscount(this.getCartTotal()),
			items: deliverectItems,
			numberOfCustomers: 1, //Find out??
			orderIsAlreadyPaid: alreadyPaid,
			orderType: orderType,
			payment: {
				amount: this.getCartTotal(),
				type: payType,
			},
			serviceCharge: Math.round(this.gastroService.getGastroFee() * 100),
			table: this.sessionDataService.inhouseLink ? this.tableService.getTable().tischNR.toString() : undefined,
			taxes: tax,
			validationId: "dkjsflsjdf", //Find out
		};
		if (this.gastro.hasDelivery && this.sessionDataService.$isDelivery) {
			deliverectOrder.deliveryIsAsap = deliveryIsAsap;
			deliverectOrder.deliveryTime = deliveryTime;
			deliverectOrder.pickupTime = pickupTime;
			deliverectOrder.deliveryAddress = {
				street: this.sessionDataService.$deliveryInformations.street,
				streetNumber: this.sessionDataService.$deliveryInformations.streetNr,
				postalCode: this.sessionDataService.$deliveryInformations.plz,
			};
			deliverectOrder.customer.phoneNumber = this.sessionDataService.$deliveryInformations.mobileNr;
			deliverectOrder.note = this.sessionDataService.$deliveryInformations.note;
		}
		if (this.gastro.hasPickup && this.sessionDataService.$isPickUp) {
			deliverectOrder.pickupTime = pickupTime;
			deliverectOrder.deliveryAddress = {
				street: "",
				streetNumber: "",
				postalCode: "",
			};
			deliverectOrder.deliveryIsAsap = false;
			deliverectOrder.customer.phoneNumber = this.sessionDataService.$deliveryInformations?.mobileNr;
			deliverectOrder.note = this.sessionDataService.$deliveryInformations?.note;
		}

		this.deliverectChannelOrderId = deliverectOrder.channelOrderId;
		this.deliverectChannelOrderDisplayCode = deliverectOrder.channelOrderDisplayId;

		return deliverectOrder;
	}

	/*
	* takes the exisiting cart, arranges items in deliverect format
	*/
	private initDeliverectItems(split_cart:any) {
		const ret = [];
		for (const item of split_cart.items) {
			const subitems = [];
			if (item.extras != undefined) {
				item.extras.forEach((extra)=>{
					if (extra.kind == 0 || extra.kind == 1) {
						extra.extra.forEach((extraItem)=>{
							if (extraItem.selected) {
								if ((this.gastro.hasDelivery && this.sessionDataService.$isDelivery)
								 || (this.gastro.hasPickup && this.sessionDataService.$isPickUp)) {
									subitems.push({
										name: extraItem.name,
										plu: extraItem.addData.plu,
										price: Math.round(extraItem.inhousePrice * 100),
										quantity: 1,
									});
								} else {
									subitems.push({
										name: extraItem.name,
										plu: extraItem.addData.plu,
										price: Math.round(extraItem.outerhousePrice * 100),
										quantity: 1,
									});
								}
							}
						});
					} else if (extra.kind == 2) {
						extra.extra.forEach((extraItem)=>{
							if (extraItem.count > 0) {
								if ((this.gastro.hasDelivery && this.sessionDataService.$isDelivery)
								 || (this.gastro.hasPickup && this.sessionDataService.$isPickUp)) {
									subitems.push({
										name: extraItem.name,
										plu: extraItem.addData.plu,
										price: Math.round(extraItem.inhousePrice * 100),
										quantity: extraItem.count,
									});
								} else {
									subitems.push({
										name: extraItem.name,
										plu: extraItem.addData.plu,
										price: Math.round(extraItem.outerhousePrice * 100),
										quantity: extraItem.count,
									});
								}
							}
						});
					}
				});
			}
			const cleanPrice = this.getCleanPriceForDeliverect(item, subitems);
			ret.push({
				name: item.name,
				plu: item.addData.plu.toString(),
				price: Math.round(cleanPrice),
				quantity: item.count,
				subItems: subitems,
			});
		}
		return ret;
	}

	getCleanPriceForDeliverect(item, subItems) {
		let price = item.price * 100;
		for (const sub of subItems) {
			price -= sub.price * sub.quantity;
		}
		return price;
	}

	public getCartTotal(): number {
		const total = (Math.round(this.cartService.$cart
			.reduce((total, item) => { const reduced = total += item.count * item.price; return reduced; }, 0) * 100));
		return total;
	}
	
	/*
	* @input: date object, time object
	* return: Datetime Object in deliverect format 
	* -> 2022-12-31T12:00:00Z
	*/
	convertTime(date, time) {
		const dateArr = date.split(".");
		const timeArr = time.split(":");
		timeArr[0] = this.addZeroToTime((Number.parseInt(timeArr[0]) - 1));
		return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}T${timeArr[0]}:${timeArr[1]}:00Z`;
	}

	/**
	 * adds a 0 prefix to the given date if its below 10
	 * @param date 
	 * @returns 
	 */
	 addZeroToTime(time: number) {
		if (time < 9) {
			return `0${time}`;
		}
		return time;
	}

	getTotalBeforeDeliveryFee() {
		let total = this.getCartTotal();
		this.couponService.deleteIfCouponCodeIsNotValidRestriction(total);
		let discount = 0;
		for (const item of this.cartService.$cart) {
			discount += this.calcDiscount(item.price * item.count);
		}
		total += discount;

		this.couponService.deleteCouponIfPriceMinus(total);

		if (this.gastro.hasFee && this.cartService.$cart.length > 0) {
			total += this.gastro.fee;
		}

		return total;
	}

	private calcDiscount(itemPrice: number) {
		return this.calcGastroDiscounts(itemPrice) + this.calcCoupons(itemPrice);
	}

	private calcGastroDiscounts(itemPrice: number): number {
		const gastro = this.gastro;
		if (gastro.discount === undefined) {
			return 0;
		}

		let discountValue = 0;
		for (const discount of gastro.discount) {
			if (this.checkDiscounts()) {
				discountValue += this.calcGastroDiscount(itemPrice, discount);
			}
		}
		return discountValue;
	}

	private calcGastroDiscount(itemPrice: number, discount: any) {
		return -((discount.fix * itemPrice / this.getCartTotal()) + (itemPrice * discount.percentage / 100));
	}

	calcCoupons(itemPrice: number) {
		let couponDiscount = 0;
		for (const coupon of this.couponService.currentCouponList) {
			if (coupon.rewardType === "flatAmount") {
				couponDiscount += -(coupon.rewardMagnitude / this.cartService.$cart.length);
			} else if (coupon.rewardType === "flatPercentage") {
				couponDiscount += -(itemPrice * coupon.rewardMagnitude / 100);
			}
		}
		return couponDiscount;
	}

	private checkDiscounts(): boolean {
		const gastro = this.gastro;
		if (gastro.discount === undefined) {
			return false;
		}

		for (const discount of gastro.discount) {
			if (this.isDiscountActive(discount)) {
				return true;
			}
		}

		return false;
	}

	private isDiscountActive(discount: any): boolean {

		if (this.sessionDataService.$isDelivery === true && discount.delivery === true) {
			return this.isDiscountActiveForWhereType(discount);
		}

		if (this.sessionDataService.$isPickUp === true && discount.pickup === true) {
			return this.isDiscountActiveForWhereType(discount);
		}

		if (this.sessionDataService.$inhouseLink === true && discount.inhouse === true) {
			return this.isDiscountActiveForWhereType(discount);
		}

		return false;

	}

	isDiscountActiveForWhereType(discount) {
		if (!this.isDiscountWithinTime(discount)) {
			return false;
		}

		if (discount.days === undefined) {
			return true;
		}

		const now = new Date();
		if (discount.days[now.getDay()] === true) {
			return true;
		}

		return false;
	}

	public isDiscountWithinTime(discount: any): boolean {
		if (discount.from === undefined && discount.to === undefined
			|| discount.from === "" && discount.to === "") {
			return true;
		}

		if (
			this.sessionDataService.$isDelivery === true
			&& (this.timeToDate(discount.from) <= this.timeToDate(this.sessionDataService.$deliveryTime)
				&& this.timeToDate(this.sessionDataService.$deliveryTime) <= this.timeToDate(discount.to))
			|| (this.timeToDate(discount.from) <= this.timeToDate(this.cartService.getEarlyTime())
				&& this.timeToDate(this.cartService.getEarlyTime()) <= this.timeToDate(discount.to))
		) {
			return true;
		}

		if (
			this.sessionDataService.$isPickUp === true
			&& this.timeToDate(discount.from) <= this.timeToDate(this.sessionDataService.$pickUpTime)
			&& this.timeToDate(this.sessionDataService.$pickUpTime) <= this.timeToDate(discount.to)
		) {
			return true;
		}

		return false;
	}

	private isDST(d) {
		const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
		const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
		return Math.max(jan, jul) !== d.getTimezoneOffset();
	}

	public timeToDate(time: string): Date {
		const hours = parseInt(time.slice(0, 2));
		const minutes = parseInt(time.slice(-2));
		return new Date(2000, 0, 1, hours, minutes);
	}

	apiName(): string {
		return "deliverect";
	}

	isActiveAndEnabled(): Promise<boolean> {
		return this.active;
	}
	getCategories(): Category[] {
		throw new Error("Method not implemented.");
	}
	getProducts(): Product[] {
		return this.products;
	}
	getExtra(extraId: string): Extra {
		throw new Error("Method not implemented.");
	}
	getPaymentMethods(): PaymentMethod[] {
		throw new Error("Method not implemented.");
	}

	/*
	* sends order to deliverect API
	* @input: the cart from the order
	* resolves true if sending order was successfull
	* Attention: Resolves False, even though order is successfully send, if there are problems on the deliverect side (Webhooks etc.).
	*/
	async sendOrder(splitCart: Order): Promise<[boolean, any]> {
		const split_cart = await this.init(splitCart);
		const data = {
			order: split_cart,
			gastroId: this.gastroId,
		};
	  
		console.log(data);
		const url = `${environment.functionsUrl}deliverectSendOrder`;
		const resp = await $.post(url, data);
	
		if (resp.status === undefined || resp.status === 200) {
			return [true, undefined];
		} else {
			return [false, undefined];
		}
	}

	hasMobilePayment: boolean;
	getMobilePaymentSplitObject(): PaymentMethod {
		throw new Error("Method not implemented.");
	}
	sendPayment(amount: number, partyId?: number, tip?: number) {
		return true;
	}
}

import { languages } from "../constants/constants";

/**
 * get the calendar week number to a given date
 * @param date the date of which to calculate the calendar week
 * @returns the calendar week
 */
export function getWeekNumber(date: Date): number {
	const newDate = new Date(date);
	// Thursday in current week decides the year.
	newDate.setDate(newDate.getDate() + 3 - (newDate.getDay() + 6) % 7);
	// January 4 is always in week 1.
	const week1 = new Date(newDate.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from newDate to week1.
	return 1 + Math.round(((newDate.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

/**
 * returns an array containing the document ids which are relevant for the given date
 * @param date the date for which the reports should be returned
 * @returns an array of firebase document ids.
 */
export function getReportStrings(date: Date): string[] {
	const ret = [];
	ret.push(getReportStringOverall());
	ret.push(getReportStringMonth(date));
	ret.push(getReportStringKW(date));
	ret.push(getReportStringDate(date));
    
	return ret;
}

/**
 * @returns the document id of the overall report document 
 */
export function getReportStringOverall(): string {
	return "overall";
}

/**
 * returns the document id for the monthly report
 * @param date the date of which to get the report
 * @returns the document id of the monthly report
 */
export function getReportStringMonth(date: Date): string {
	return `${date.getFullYear().toString()}-M-${date.getMonth().toString()}`;
}

/**
 * returns the document id for the weekly report.
 * ! Weeks start on tuesday !
 * @param date the date of which to get the report
 * @returns the document id of the weekly report
 */
export function getReportStringKW(date: Date): string {
	const newDate = new Date(date);

	//Update the date so that weeks start on tuesdays
	newDate.setDate(newDate.getDate() - 1);
    
	const weekNr = getWeekNumber(newDate);
	let year = newDate.getFullYear();

	//If it is still the KW of the previous year in the current year adjust the year variable
	if (newDate.getMonth() === 0 && weekNr > 50) {
		year--;
	}

	//The new kw has already started but we are still in the old year
	if (weekNr === 1 && newDate.getMonth() === 11) {
		year++;
	}
	return `${year.toString()}-KW-${weekNr.toString()}`;
}

/**
 * returns the document id for the daily report
 * @param date the date which to get the report
 * @returns the document id of the daily report
 */
export function getReportStringDate(date: Date): string {
	return `${date.getFullYear().toString()}-${(date.getMonth() + 1).toString()}-${date.getDate().toString()}`;
}

/**
 * Rounds a full number x to the next y-step
 * @param {number} x the number to be rounded
 * @param {number} y the number to round to
 * @returns {number} x rounded
 */
export function roundX(x: number, y: number): number {
	if (y === 0) {
		return x;
	}
	return Math.ceil(x / y) * y;
}

/**
 * Returns the day of the week as a string to a given date
 * @param {Date} date the date of which to get the weekday from
 * @returns {string} the weekday as a string
 */
export function getWeekDayAsString(date: Date): string {
	switch (date.getDay()) {
	case 0: {
		return "Sonntag";
	}
	case 1: {
		return "Montag";
	}
	case 2: {
		return "Dienstag";
	}
	case 3: {
		return "Mittwoch";
	}
	case 4: {
		return "Donnerstag";
	}
	case 5: {
		return "Freitag";
	}
	case 6: {
		return "Samstag";
	}
	}
	return "";
}

function makeTwoDigitString(str: string): string {
	let ret = str;
	if (str.length === 1) {
		ret = `0${str}`;
	}
	return ret;
}

/**
 * Returns date as a string in the format: "dd.mm.yyyy"
 * @param {Date} date the date of which to get the dateString
 * @param {string} timeZone the timezone as in the IANA time zone database. 
 * @returns {string} the datestring in the format: "dd.mm.yyyy"
 */
export function getDateString(date: Date, timeZone = "Europe/Berlin"): string {
	const options: Intl.DateTimeFormatOptions = {
		day: "2-digit",
		month: "2-digit",
		timeZone: timeZone,
		year: "numeric",
	};

	return date.toLocaleDateString("de-DE", options);
}

/**
 * Returns a new date to a given dateString
 * @param {string} dateString the datestring in the format "dd.mm.yyyy"
 * @returns {Date} the date which corresponds to the given dateString
 */
export function getDate(dateString: string): Date {
	const dateParts: string[] = dateString.split(".");
	return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
}

/**
 * Returns the hours and minutes of the given date in the format "hh:mm"
 * @param {Date} date
 * @returns {string} a string in the format "hh:mm"
 */
export function getHoursAndMinutes(date: Date, timeZone = "Europe/Berlin"): string {
	const options: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		minute: "numeric",
		timeZone: timeZone,
	};
	return date.toLocaleTimeString("de-DE", options);
}

export function getWeekdayInTimezone(date: Date, timezone: string): number {
	const weekdayString = date.toLocaleDateString("de-DE", { timeZone: timezone, weekday: "long" });
	const weekday = [
		"Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag",
	].indexOf(weekdayString);
	return weekday;
}

/**
 * Returns the full name which belongs to a languageCode
 * @param languageCode the languageCode of which to get the full name
 */
export function getLabelOfLanguageCode(languageCode: string): string {
	const foundLanguage = languages.find(elem => elem.languageCode === languageCode);
	return foundLanguage !== undefined ? foundLanguage.label : languageCode;
}
  
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TableContentService } from "src/app/services/table-content.service";

@Component({
	selector: "app-article",
	templateUrl: "./article.component.html",
	styleUrls: ["./article.component.scss"],
})
export class ArticleComponent {
	@Output() totalChanged = new EventEmitter<boolean>();
	@Input() order: any = {};
	@Input() partialPay: boolean;


	showStepCounter = false;
	total = "0,00";

	constructor(public tableContentService: TableContentService) {
	}
	ngOnInit() {
		this.order.partialCounter = 0;
		this.calcTotalWithChildren(false);
	}


	// still ToDo until we split the article component into 2 separate ones
	// toggleExpandExtras(order) {
	// 	if (order.minimized == undefined) {
	// 		order.minimized = false;
	// 	}

	// 	order.minimized = !order.minimized;
	// }

	/**
 * Adds an item to the order and updates the counter if certain conditions are met.
 * 
 * @param {Object} item - The item to be added to the order.
 * 
 * @description
 * This function increments the `partialCounter` of an item by 1 if the current
 * `partialCounter` is less than the `counter`. It then updates the order by calling 
 * `addOrderToSelectedTableContent` on the `tableContentService` and emits a `totalChanged` 
 * event to indicate that the total has changed.
 * 
 */
	addItemToPay(item) {
		if (this.partialPay) {
			if (item.partialCounter < item.counter) {
				item.partialCounter++;
				this.calcTotalWithChildren(true);
				this.tableContentService.addOrderToSelectedTableContent(item);
				this.totalChanged.emit(true);
			} }
		
	}

	/**
 * Removes an item from the order and updates the counter if certain conditions are met.
 * 
 * @param {Object} item - The item to be removed from the order.
 * 
 * @description
 * This function decrements the `partialCounter` of an item by 1 if the current
 * `partialCounter` is greater than 0. It then updates the order by calling 
 * `removeOrderFromSelectedTableContent` on the `tableContentService` and emits a 
 * `totalChanged` event to indicate that the total has changed.
 * 
 * @example
 * const item = { partialCounter: 2 };
 * removeItemToPay(item);
 * // Decreases item.partialCounter to 1, removes the order from the table content, and emits totalChanged.
 */
	removeItemToPay(item) {
		if (item.partialCounter > 0) {
			item.partialCounter--;
			this.calcTotalWithChildren(true);
			this.tableContentService.removeOrderFromSelectedTableContent(item);
			this.totalChanged.emit(true);
		}
	}
	/**
	 * Toggles the display state of the step counter.
	 * 
	 * @description
	 * This function switches the boolean state of `showStepCounter` between true and false.
	 * It is used to show or hide the step counter in the UI.
	 * 
	 * @example
	 * toggleStepCounter();
	 * // If showStepCounter was true, it becomes false. If it was false, it becomes true.
	 */
	toggleStepCounter() {
		this.showStepCounter = !this.showStepCounter;

	}


	calcTotalWithChildren(partial:boolean) {
		let total = 0;
		
		total = total + this.order.price.amount / 100;

		for (const child of this.order.children) {
			total = total + (child.price.amount / 100);
		}
		if (partial) {
			total = total * this.order.partialCounter;
		} else {
			total = total * this.order.counter;
		}
		this.total = total.toFixed(2);
	}


}

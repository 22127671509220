/**
 * These States indicate the lifecycle of an order. 
 * Only orders with state ACCEPTED are orders that make it to the gastronomen.
 */
export enum PaymentState {
    PENDING = 0,
    DECLINED = 1,
    ACCEPTED = 2,
}

export enum OrderState {
	STORNO = -1,
	NEW_ORDER = 0,
	ACCEPTED_ORDER = 1, //final state
	DECLINED = 2, //Does not exist yet (probably)
	ACCEPTED_WITH_DRIVER = 3,
	ACCEPTED_WITHOUT_DRIVER = 4,
	PROCESSING = 10, //This order is still being processed and should not yet be displayed to any waiter
}
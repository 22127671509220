import { Component, Input, OnInit } from "@angular/core";
import { MenuService } from "src/app/services/menu.service";

@Component({
	selector: "app-template-skeleton",
	templateUrl: "./template-skeleton.component.html",
	styleUrls: ["./template-skeleton.component.scss"],
})
export class TemplateSkeletonComponent implements OnInit {

	/**
	 * The layout this component should use.
	 * Possible values are analogous to the possible template values: 'Default', 'Switch', 'Kacheln'.
	 */
	@Input() layout: "Default" | "Switch" | "Kacheln" = "Default";

	/**
	 * Whether or not the skeleton items should have an animation effect.
	 * Default is true.
	 */
	@Input() animate = true;

	constructor(public menuService: MenuService) { }

	ngOnInit() { }

}

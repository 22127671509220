import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SessionDataService } from "src/app/services/session-data.service";
import { Product } from "src/shared/split-submodules/types/types";

@Component({
	selector: "app-products-in-cart-deleted-alerted",
	templateUrl: "./products-in-cart-deleted-alerted.component.html",
	styleUrls: ["./products-in-cart-deleted-alerted.component.scss"],
})
export class ProductsInCartDeletedAlertedComponent {

	@Input()
		deletedProducts: Product[] = [];
	changedProducts: {
		oldProduct: Product,
		newProduct: Product,
	}[] = [];

	constructor(
		private modalController: ModalController,
		private sessionDataService: SessionDataService,
	) { }

	/**
	 * Dismisses the alert
	 */
	dismiss() {
		this.modalController.dismiss();
	}

	/**
	 * Get the price of the Product, it checks for inHouse or outerHouse
	 */
	public getPrice(product: Product): number {
		return this.sessionDataService.$inhouseLink ? product.inhousePrice : product.outerhousePrice;
	}
}

export interface ProductsInCartDeletedAlertComponentProps {
	deletedProducts: Product[];
	changedProducts: {
		oldProduct: Product,
		newProduct: Product,
	}[],
}
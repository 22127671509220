import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";

@Injectable({
	providedIn: "root",
})
export class AlertService {

	constructor(
		private alertController: AlertController,
		private loadingController: LoadingController,
	) { }

	public async confirmAlert(header: string, message: string): Promise<HTMLIonAlertElement> {
		const alert = await this.alertController.create({
			header: header,
			message: message,
			buttons: [
				{
					text: "Okay",
					handler: () => {
						alert.dismiss();
					},
				},
			],
		});

		alert.present();
		return alert;
	}

	public async errorAlert(
		errorMessage: string,
		errorHeader = "Fehler",
		subHeader = "",
	): Promise<HTMLIonAlertElement> {
		const alert = await this.alertController.create({
			buttons: [
				{
					text: "Okay",
					handler: () => {
						alert.dismiss();
					},
				},
			],
			header: errorHeader,
			message: errorMessage,
			subHeader: subHeader,
		});

		alert.present();
		return alert;
	}
	/**
 * Creates and returns a Promise that resolves to an Ionic Loading Controller element with the specified message.
 *
 * @param message - The message to be displayed on the loading spinner.
 * @returns A Promise that resolves to an HTMLIonLoadingElement representing the created loading spinner.
 */

	public createLoading(message: string): Promise<HTMLIonLoadingElement> {
		return this.loadingController.create({
			message: message,
		});
	}
}

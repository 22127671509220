import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-simple-img-modal",
	templateUrl: "./simple-img-modal.component.html",
	styleUrls: ["./simple-img-modal.component.scss"],
})
export class SimpleImgModalComponent implements OnInit {
	@Input() imgUrl: any;

	constructor(public modalCtrl: ModalController) {

	}
	ngOnInit() {
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
}
